import React, { ChangeEvent } from 'react';
import { BaseTextFieldProps, FormControl, InputLabel, TextField as TextFieldMat } from '@material-ui/core';
import Icon, { IconsType } from 'components/Icon';
import Loader from 'components/Loader';
import useStyles from './textfield.styles';

export interface TextFieldProps extends BaseTextFieldProps {
    onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onFocus?: (event: any) => void;
    onBlur?: (event: any) => void;
    startIcon?: IconsType | { label: string; style?: any };
    endIcon?: IconsType | { label: string; style?: any };
    loading?: boolean;
    disableMarginTop?: boolean;
    insideAutoComplete?: boolean;
}

const TextField: React.FunctionComponent<TextFieldProps> = React.forwardRef((props, ref) => {
    const {
        startIcon,
        endIcon,
        loading,
        type,
        disabled,
        label,
        id,
        variant = 'outlined',
        disableMarginTop,
        onFocus,
        onBlur,
        fullWidth,
        insideAutoComplete,
        ...rest
    } = props;
    const classes = useStyles({ disableMarginTop: !!disableMarginTop });

    const isPasswordField = props.type === 'password';
    const [passwordVisible, setPasswordVisible] = React.useState<boolean>(false);

    const handlePasswordVisibleClick = () => setPasswordVisible(!passwordVisible);

    const getStartAdormentIcon = () => {
        if (!startIcon) return null;
        return typeof startIcon === 'string' ? (
            <Icon name={startIcon} />
        ) : (
            <Icon name={IconsType.custom}>{startIcon}</Icon>
        );
    };

    const getEndAdormentIcon = () => {
        if (loading) return <Loader size={15} />;
        if (isPasswordField) {
            return (
                <Icon
                    onClick={handlePasswordVisibleClick}
                    style={{ cursor: 'pointer' }}
                    name={passwordVisible ? IconsType.visible : IconsType.un_visible}
                />
            );
        }
        return typeof endIcon === 'string' ? <Icon name={endIcon} /> : <Icon name={IconsType.custom}>{endIcon}</Icon>;
    };

    const getInputType = () => {
        if (isPasswordField) {
            if (passwordVisible) return 'text';
            return 'password';
        }
        return type;
    };

    return (
        <FormControl fullWidth={fullWidth}>
            {label && (
                <InputLabel shrink error={false} htmlFor={id || 'textfield-input'}>
                    {label}
                </InputLabel>
            )}
            <TextFieldMat
                type={getInputType()}
                classes={{ root: classes.root }}
                {...rest}
                variant={variant}
                id={id || 'textfield-input'}
                disabled={disabled || loading}
                fullWidth={fullWidth}
                inputRef={ref ?? rest.inputRef}
                InputProps={
                    !insideAutoComplete
                        ? {
                              ...(rest as any).InputProps,
                              onFocus,
                              onBlur,
                              startAdornment: getStartAdormentIcon(),
                              endAdornment: getEndAdormentIcon(),
                          }
                        : {
                              ...(rest as any).InputProps,
                          }
                }
            />
        </FormControl>
    );
});

export default TextField;
