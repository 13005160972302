import { createStyles } from '@material-ui/core';
import { isRtl } from 'helpers/device_helper';

export default createStyles({
    root: {
        marginLeft: '-11px',
        marginRight: '16px',
        ...isRtl({
            marginLeft: '16px',
            marginRight: '-11px',
        }),
    },
});
