/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface DevOpsApiDBI
 */
export class DevOpsApiDBI {
    /**
     *
     * @type {Array<string>}
     * @memberof DevOpsApiDBI
     */
    CZ_HOSTS?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof DevOpsApiDBI
     */
    WT_HOSTS?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof DevOpsApiDBI
     */
    isCloudFlare?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof DevOpsApiDBI
     */
    IS_PROXY_BY_CLOUD_FLARE?: boolean;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    hostname?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    platformHostname?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    PLATFORM_PROXY?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    PLATFORM_SERVER?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    PLATFORM_SERVER_REDIRECT?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    BRAND_APPS?: DevOpsApiDBIBRANDAPPSEnum;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    REDIRECT_APP_CODE?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    REDIRECT_APP_CODE_URL?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    DEPLOY_CLUSTER?: DevOpsApiDBIDEPLOYCLUSTEREnum;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CLUSTER_LB?: string;
    /**
     *
     * @type {number}
     * @memberof DevOpsApiDBI
     */
    CZ_CLIENT_HPA_MIN?: number;
    /**
     *
     * @type {number}
     * @memberof DevOpsApiDBI
     */
    CZ_CLIENT_HPA_MAX?: number;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CZ_CLIENT_CPU_REQUEST?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CZ_CLIENT_CPU_LIMIT?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CZ_CLIENT_HPA_CPU_MAX?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CZ_CLIENT_MEMORY_REQUEST?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CZ_CLIENT_MEMORY_LIMIT?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CZ_CLIENT_HPA_MEMORY_MAX?: string;
    /**
     *
     * @type {number}
     * @memberof DevOpsApiDBI
     */
    CZ_SERVER_HPA_MIN?: number;
    /**
     *
     * @type {number}
     * @memberof DevOpsApiDBI
     */
    CZ_SERVER_HPA_MAX?: number;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CZ_SERVER_CPU_REQUEST?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CZ_SERVER_CPU_LIMIT?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CZ_SERVER_HPA_CPU_MAX?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CZ_SERVER_MEMORY_REQUEST?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CZ_SERVER_MEMORY_LIMIT?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CZ_SERVER_HPA_MEMORY_MAX?: string;
    /**
     *
     * @type {number}
     * @memberof DevOpsApiDBI
     */
    WT_CLIENT_HPA_MIN?: number;
    /**
     *
     * @type {number}
     * @memberof DevOpsApiDBI
     */
    WT_CLIENT_HPA_MAX?: number;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    WT_CLIENT_CPU_REQUEST?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    WT_CLIENT_CPU_LIMIT?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    WT_CLIENT_HPA_CPU_MAX?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    WT_CLIENT_MEMORY_REQUEST?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    WT_CLIENT_MEMORY_LIMIT?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    WT_CLIENT_HPA_MEMORY_MAX?: string;
    /**
     *
     * @type {number}
     * @memberof DevOpsApiDBI
     */
    WT_SERVER_HPA_MIN?: number;
    /**
     *
     * @type {number}
     * @memberof DevOpsApiDBI
     */
    WT_SERVER_HPA_MAX?: number;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    WT_SERVER_CPU_REQUEST?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    WT_SERVER_CPU_LIMIT?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    WT_SERVER_HPA_CPU_MAX?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    WT_SERVER_MEMORY_REQUEST?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    WT_SERVER_MEMORY_LIMIT?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    WT_SERVER_HPA_MEMORY_MAX?: string;
    /**
     *
     * @type {number}
     * @memberof DevOpsApiDBI
     */
    CCS_SERVER_HPA_MIN?: number;
    /**
     *
     * @type {number}
     * @memberof DevOpsApiDBI
     */
    CCS_SERVER_HPA_MAX?: number;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CCS_SERVER_CPU_REQUEST?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CCS_SERVER_CPU_LIMIT?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CCS_SERVER_HPA_CPU_MAX?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CCS_SERVER_MEMORY_REQUEST?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CCS_SERVER_MEMORY_LIMIT?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CCS_SERVER_HPA_MEMORY_MAX?: string;
    /**
     *
     * @type {number}
     * @memberof DevOpsApiDBI
     */
    CRM_WRAPPER_HPA_MIN?: number;
    /**
     *
     * @type {number}
     * @memberof DevOpsApiDBI
     */
    CRM_WRAPPER_HPA_MAX?: number;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CRM_WRAPPER_CPU_REQUEST?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CRM_WRAPPER_CPU_LIMIT?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CRM_WRAPPER_HPA_CPU_MAX?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CRM_WRAPPER_MEMORY_REQUEST?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CRM_WRAPPER_MEMORY_LIMIT?: string;
    /**
     *
     * @type {string}
     * @memberof DevOpsApiDBI
     */
    CRM_WRAPPER_HPA_MEMORY_MAX?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum DevOpsApiDBIBRANDAPPSEnum {
    Null = 'NULL',
    Cz = 'CZ',
    Wt = 'WT',
    CzWt = 'CZ_WT',
    PropWt = 'PROP_WT',
    Crypto = 'CRYPTO',
}
/**
 * @export
 * @enum {string}
 */
export enum DevOpsApiDBIDEPLOYCLUSTEREnum {
    Ireland = 'Ireland',
    Frankfurt = 'Frankfurt',
    HongKong = 'HongKong',
}
