import { makeStyles } from '@material-ui/core';
import { customGap, isRtl, isTablet } from 'helpers/device_helper';

export default makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'flex-end',
        ...customGap(10, 'horizontal'),
        ...isTablet({
            flexGrow: 'unset',
            justifyContent: 'initial',
        }),
    },
    detailsWrapper: {
        width: 'auto',
        height: '3.7rem',
        border: `1px solid ${theme.palette.action.disabledBackground}`,
        borderRadius: '8px',
        display: 'flex',
    },
    detailsItem: {
        flex: 1,
        width: '33.33333%',
        padding: '0.8rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: theme.palette.text.default,
        '&:not(:first-child)': {
            borderLeft: `1px solid ${theme.palette.action.disabledBackground}`,
            borderRight: 'unset',
            ...isRtl({
                borderRight: `1px solid ${theme.palette.action.disabledBackground}`,
                borderLeft: 'unset',
            }),
        },
    },
    detailsItemTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    coloredDot: {
        display: 'inline-block',
        minHeight: '0.8rem',
        minWidth: '0.8rem',
        borderRadius: '50%',
        background: theme.palette.primary.main,
        marginRight: `${theme.spacing(1)}px`,
        marginLeft: 'unset',
        ...isRtl({
            marginLeft: `${theme.spacing(1)}px`,
            marginRight: 'unset',
        }),
    },
    coloredDot__secondary: {
        background: theme.palette.secondary.main,
    },
    iconBtn: {
        height: '2.5rem',
        width: '2.5rem',
        borderRadius: '8px',
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '& > svg': {
            transition: 'all 200ms linear',
        },
    },
    iconBtn__active: {
        '& > svg': {
            transform: 'rotate(-180deg)',
        },
    },
    toolTipPopper: {
        pointerEvents: 'initial',
    },
    toolTipPaper: {
        borderRadius: '8px',
        padding: 'unset',
        backgroundColor: theme.palette.background.paper,
        boxShadow:
            '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        display: 'flex',
        flexDirection: 'column',
        margin: '2.4rem 0px',
        minWidth: '30rem',
        // maxHeight: '320px',
        // overflow: 'auto'
    },
    toolTipArrow: {
        color: theme.palette.background.paper,
        height: '1rem',
        width: '1.5rem',
    },
    toolTipTop: {
        '&& .MuiTooltip-arrow': {
            marginTop: '-1rem',
        },
    },
    btn: {
        color: theme.palette.side_menu.text,
        padding: '8px',
        width: '100%',
        alignItems: 'center',
        lineHeight: 1,
        justifyContent: 'flex-start',
        '&:hover,&:focus': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },

    seperator: {
        height: '1px',
        width: '100%',
        background: theme.palette.action.disabledBackground,
    },
    titleWrapper: {
        color: theme.palette.text.default,
        padding: `${theme.spacing(6)}px ${theme.spacing(5)}px ${theme.spacing(4)}px`,
    },
    rowsWrapper: {
        padding: `${theme.spacing(2)}px`,
        ...customGap(theme.spacing(1), 'vertical'),
        maxHeight: '280px',
        overflow: 'auto',
    },
    row: {
        cursor: 'pointer',
        padding: `${theme.spacing(3)}px ${theme.spacing(12)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        ...isRtl({
            padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(12)}px`,
        }),
        display: 'flex',
        borderRadius: '8px',
        ...customGap(theme.spacing(3), 'horizontal'),
        '& > div > p:first-child': {
            color: theme.palette.text.default,
        },
        '& > div > p:nth-child(2)': {
            color: theme.palette.text.secondary,
        },
        '&:hover': {
            background: theme.palette.primary.main,
            '& > div > p:first-child': {
                color: theme.palette.primary.contrastText,
            },
            '& > div > p:nth-child(2)': {
                color: theme.palette.primary.contrastText,
            },
        },
    },
    row__active: {
        background: theme.palette.primary.main,
        '& > div > p:first-child': {
            color: theme.palette.primary.contrastText,
        },
        '& > div > p:nth-child(2)': {
            color: theme.palette.primary.contrastText,
        },
    },
    rowContect: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    boldText: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    lightText: {
        fontWeight: 600,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    filter: {
        padding: `${theme.spacing(6)}px ${theme.spacing(5)}px ${theme.spacing(4)}px`,
    },
}));
