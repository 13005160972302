import Typography from 'components/Typography';
import { getGoBackPath } from 'interfaces/main';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import useStyles from './breadcrumb.styles';

export interface BreadcrumbProps {}

const Breadcrumb: React.FunctionComponent<BreadcrumbProps> = () => {
    const [backTo, setBackTo] = React.useState<Array<{ labelT: string; link: string }>>();
    const classes = useStyles();
    const location = useLocation();
    const { t } = useTranslation();

    React.useEffect(() => {
        setBackTo(getGoBackPath(location.pathname, true) as any);
    }, [location.pathname]);

    if (!backTo || backTo.length === 0) return null;

    return (
        <div className={classes.breadcrump_wrapper}>
            {backTo.map((link, index) => (
                <React.Fragment key={index}>
                    {index !== 0 && (
                        <Typography variant="body1" className={classes.item}>
                            /
                        </Typography>
                    )}
                    {index === backTo.length - 1 ? (
                        <Typography variant="h4" className={classes.item}>
                            {t(link.labelT)}
                        </Typography>
                    ) : (
                        <Link to={link.link} className={classes.link_item}>
                            <Typography variant="body1">{t(link.labelT)}</Typography>
                        </Link>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default Breadcrumb;
