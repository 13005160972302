import React from 'react';
import { FloatingMenuProps, mapDispatchToProps, mapStateToProps } from './floating-menu.scheme';
import { ThemeOptions } from 'interfaces/main';
import { ClickAwayListener, Tooltip } from '@material-ui/core';
import Icon, { IconsType } from 'components/Icon';
import { connect } from 'react-redux';
import classNames from 'classnames';
import useStyles from './floating.menu.styles';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';

const FloatingMenu: React.FunctionComponent<FloatingMenuProps> = (props) => {
    const { theme, logOut, toggleTheme } = props;
    const [menuState, setMenuState] = React.useState<boolean>(false);
    const { t } = useTranslation();
    const classes = useStyles();

    const toggleMenuState = () => setMenuState(!menuState);

    const onClickAway = () => {
        if (!menuState) return;
        setMenuState(false);
    };

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <Tooltip
                arrow
                open={menuState}
                classes={{
                    tooltip: classes.toolTipPaper,
                    arrow: classes.toolTipArrow,
                    popper: classes.toolTipPopper,
                    tooltipPlacementBottom: classes.toolTipTop,
                }}
                title={
                    <React.Fragment>
                        <Button
                            variant="text"
                            size="small"
                            onClick={toggleTheme}
                            startIcon={theme === ThemeOptions.DARK ? IconsType.darkModeIcon : IconsType.lightModeIcon}
                            className={classes.btn}
                        >
                            {t(`side_menu.item_names.${theme}_mode`)}
                        </Button>
                        <Button
                            variant="text"
                            size="small"
                            onClick={logOut}
                            className={classes.btn}
                            startIcon={IconsType.logout}
                        >
                            {t(`side_menu.item_names.logout`)}
                        </Button>
                    </React.Fragment>
                }
            >
                <div
                    className={classNames({ [classes.iconBtn]: true, [classes.iconBtn__active]: menuState })}
                    onClick={toggleMenuState}
                >
                    <Icon name={IconsType.my_profile} />
                </div>
            </Tooltip>
        </ClickAwayListener>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(FloatingMenu));
