export enum EAuthTypes {
    LOGIN_START = '[auth] login start',
    LOGIN_SUCCESS = '[auth] login success',
    LOGIN_FAILED = '[auth] login failed',

    LOGOUT = '[auth] logout',

    START_LOADER = '[auth] start loader',
    STOP_LOADER = '[auth] stop loader',
}
