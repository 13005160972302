import { createStyles } from '@material-ui/core';

export default createStyles({
    root: {
        width: '1em',
        height: '1em',
        fontSize: '2rem',
    },
    fontSizeLarge: {
        fontSize: '2.5rem',
    },
});
