/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AtomixChatType } from './atomix-chat-type';
import { Chips } from './chips';
import { DepositBankTransferFeeIno } from './deposit-bank-transfer-fee-ino';
import { DepositCryptoLinks } from './deposit-crypto-links';
import { DepositLinks } from './deposit-links';
import { DepositOutsourceLink } from './deposit-outsource-link';
import { DepositWireTransfer } from './deposit-wire-transfer';
import { EnableRedirectToUrl } from './enable-redirect-to-url';
import { ImageBanner } from './image-banner';
import { Pallete } from './pallete';
import { PaymentTypesFlagsNewEntity } from './payment-types-flags-new-entity';
import { RefundDisclaimerType } from './refund-disclaimer-type';
import { TradersEducationLink } from './traders-education-link';

/**
 *
 * @export
 * @interface ClientEnvDBI
 */
export class ClientEnvDBI {
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof ClientEnvDBI
     */
    REACT_APP_FEATURES_FLAGS: { [key: string]: boolean };
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof ClientEnvDBI
     */
    REACT_APP_REGISTER_FLAGS: { [key: string]: boolean };
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof ClientEnvDBI
     */
    REACT_APP_BANK_ACCOUNT_FLAGS: { [key: string]: boolean };
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof ClientEnvDBI
     */
    REACT_APP_PRE_DEPOSIT_FLAGS: { [key: string]: boolean };
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof ClientEnvDBI
     */
    REACT_APP_PRE_PAYMENT_TYPES_FLAGS: { [key: string]: boolean };
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_SUPPORT_EMAIL: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_SITE_NAME: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_COMPANY_NAME: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_REGULATION_NAME: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_CERTIFICATE_NUMBER: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_REGISTERATION_NUMBER: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_REGULATION_COUNTRY: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_DOWNLOAD_SIRIX: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_DOWNLOAD_MT4: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_DOWNLOAD_MT5: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_DOWNLOAD_IOS: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_DOWNLOAD_ANDROID: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_NAME: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_BACKEND_URL: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_GOOGLE_API_KEY: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_GOOGLE_CLIENT_ID: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_GOOGLE_RECAPTCHA_KEY: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_FACEBOOK_APP_ID: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_TOKEN_KEY_NAME: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_I18N_DEFAULT_LNG: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ClientEnvDBI
     */
    REACT_APP_I18N_LNG_LIST: Array<string>;
    /**
     *
     * @type {Array<PaymentTypesFlagsNewEntity>}
     * @memberof ClientEnvDBI
     */
    REACT_APP_PRE_PAYMENT_TYPES_FLAGS_NEW: Array<PaymentTypesFlagsNewEntity>;
    /**
     *
     * @type {boolean}
     * @memberof ClientEnvDBI
     */
    REACT_APP_FIXED_DISCLAIMER_ROW: boolean;
    /**
     *
     * @type {Pallete}
     * @memberof ClientEnvDBI
     */
    REACT_APP_PALLETE: Pallete;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_TRADING_ANALYSIS_TUTORIAL_AV: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_TRADING_ANALYSIS_TUTORIAL_FI: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_TRADING_ANALYSIS_TUTORIAL_EC: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_LEGAL_INFORMATION_LINK: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_MIX_PANEL_KEY: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_CHAT_BASE_KEY: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_SMARTICO_GUID: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_SMARTICO_BRAND_KEY: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_DEFAULT_THEME: ClientEnvDBIREACTAPPDEFAULTTHEMEEnum;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_KYC_TYPE: ClientEnvDBIREACTAPPKYCTYPEEnum;
    /**
     *
     * @type {number}
     * @memberof ClientEnvDBI
     */
    REACT_APP_MIN_DEPOSIT?: number;
    /**
     *
     * @type {number}
     * @memberof ClientEnvDBI
     */
    REACT_APP_MAX_DEPOSIT?: number;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_DOWNLOAD_IOS_APP_CODE?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_DOWNLOAD_ANDROID_APP_CODE?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_TERMS_AND_CONDITION?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_PRIVACY_POLICY?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_RISK_WARNING?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_SOLITICS_BRAND?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_SOLITICS_POPUPTOKEN?: string;
    /**
     *
     * @type {Array<DepositWireTransfer>}
     * @memberof ClientEnvDBI
     */
    REACT_APP_DEPOSIT_WIRE_TRANSFER: Array<DepositWireTransfer>;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_PLATFORM_TYPE?: ClientEnvDBIREACTAPPPLATFORMTYPEEnum;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_MT4_PLATFORM_LINK?: string;
    /**
     *
     * @type {Array<DepositLinks>}
     * @memberof ClientEnvDBI
     */
    REACT_APP_DEPOSIT_LINKS?: Array<DepositLinks>;
    /**
     *
     * @type {Array<DepositCryptoLinks>}
     * @memberof ClientEnvDBI
     */
    REACT_APP_DEPOSIT_CRYPTO?: Array<DepositCryptoLinks>;
    /**
     *
     * @type {DepositOutsourceLink}
     * @memberof ClientEnvDBI
     */
    REACT_APP_DEPOSIT_OUTSOURCE?: DepositOutsourceLink;
    /**
     *
     * @type {TradersEducationLink}
     * @memberof ClientEnvDBI
     */
    REACT_APP_TRADERS_EDUCATION?: TradersEducationLink;
    /**
     *
     * @type {Array<string>}
     * @memberof ClientEnvDBI
     */
    REACT_APP_CLIENT_SUPPORT_SUBJECTS?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof ClientEnvDBI
     */
    REACT_APP_UPLOAD_FILES_TYPES?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof ClientEnvDBI
     */
    REACT_APP_REGISTRATION_BLOCKED_COUNTRIES?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_HEAD_SCRIPT?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_BODY_OPEN_SCRIPT?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_BODY_CLOSE_SCRIPT?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_CFD_PERCENTAGE?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_WHATSAPP_NUMBER?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_REDIRECT_AFTER_REGISTER?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_REDIRECT_AFTER_LOGIN?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_INITIAL_TP_TYPE?: ClientEnvDBIREACTAPPINITIALTPTYPEEnum;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_LUCKY_ORANGE_SRC?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_LUCKY_ORANGE_SITEID?: string;
    /**
     *
     * @type {EnableRedirectToUrl}
     * @memberof ClientEnvDBI
     */
    REACT_APP_ENABLE_REDIRECT_TO_EXTERNAL_URL?: EnableRedirectToUrl;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_TRADING_4_PRO_LEVEL: ClientEnvDBIREACTAPPTRADING4PROLEVELEnum;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_OUTSOURCE_WITHDRAWAL?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ClientEnvDBI
     */
    REACT_APP_WITHDRAWAL_TYPES?: Array<ClientEnvDBIREACTAPPWITHDRAWALTYPESEnum>;
    /**
     *
     * @type {Array<string>}
     * @memberof ClientEnvDBI
     */
    REACT_APP_WITHDRAWAL_CRYPTO_CURRENCIES?: Array<string>;
    /**
     *
     * @type {DepositBankTransferFeeIno}
     * @memberof ClientEnvDBI
     */
    REACT_APP_DEPOSIT_WIRE_TRANSFER_INFO?: DepositBankTransferFeeIno;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_LOGROCKET_ID?: string;
    /**
     *
     * @type {boolean}
     * @memberof ClientEnvDBI
     */
    REACT_APP_SIGNALS?: boolean;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_CRYPTO_SUBTITLE?: string;
    /**
     *
     * @type {ImageBanner}
     * @memberof ClientEnvDBI
     */
    REACT_APP_IMAGE_BANNER?: ImageBanner;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_COMMISSIONS_DECLARATION_LINK?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_CUSTODIAN_SERVICES_LINK?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_FINANCIAL_SERVICES_LINK?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_BENEFICIARY_DECLARATION_LINK?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_TRADER_EDUCATION_BEARER?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_TRADER_EDUCATION_XDOMAIN?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_PRIMARY_COLOR?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_SECONDARY_COLOR?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_SPLASH_SCREEN_BG_COLOR?: string;
    /**
     *
     * @type {RefundDisclaimerType}
     * @memberof ClientEnvDBI
     */
    REACT_APP_REFUND_DISCLAIMER?: RefundDisclaimerType;
    /**
     *
     * @type {AtomixChatType}
     * @memberof ClientEnvDBI
     */
    REACT_APP_ATOMIX_CHAT?: AtomixChatType;
    /**
     *
     * @type {Chips}
     * @memberof ClientEnvDBI
     */
    REACT_APP_CRYPTO_CHIPS?: Chips;
    /**
     *
     * @type {string}
     * @memberof ClientEnvDBI
     */
    REACT_APP_REFUND_CANCELLATION_POLICY?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ClientEnvDBIREACTAPPDEFAULTTHEMEEnum {
    Light = 'light',
    Dark = 'dark',
}
/**
 * @export
 * @enum {string}
 */
export enum ClientEnvDBIREACTAPPKYCTYPEEnum {
    Lfs = 'lfs',
    Fsca = 'fsca',
    Wgm = 'wgm',
    Kimbo = 'kimbo',
    Aml = 'aml',
    FscaWgm = 'fsca-wgm',
    ByRegulation = 'by-regulation',
    AltRegulation = 'alt-regulation',
}
/**
 * @export
 * @enum {string}
 */
export enum ClientEnvDBIREACTAPPPLATFORMTYPEEnum {
    Mt4 = 'mt4',
    Sirix = 'sirix',
}
/**
 * @export
 * @enum {string}
 */
export enum ClientEnvDBIREACTAPPINITIALTPTYPEEnum {
    Real = 'Real',
    Demo = 'Demo',
}
/**
 * @export
 * @enum {string}
 */
export enum ClientEnvDBIREACTAPPTRADING4PROLEVELEnum {
    Off = 'off',
    Basic = 'basic',
    Vip = 'vip',
}
/**
 * @export
 * @enum {string}
 */
export enum ClientEnvDBIREACTAPPWITHDRAWALTYPESEnum {
    CreditCard = 'CreditCard',
    WireTransfer = 'WireTransfer',
    Crypto = 'Crypto',
    Outsource = 'Outsource',
    TransferBetweenAccounts = 'TransferBetweenAccounts',
}
