import { EBrandManagementTypes } from './actionsTypes';
import { BrandManagementReducerState } from './interfaces';

const initialState: BrandManagementReducerState = {
    loader: true,
    featureFlagsLoading: false,
    featureFlags: undefined,
};

const brandManagementReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case EBrandManagementTypes.START_LOADER:
            return {
                ...state,
                loader: true,
            };
        case EBrandManagementTypes.STOP_LOADER:
            return {
                ...state,
                loader: false,
            };
        case EBrandManagementTypes.UPDATE_FEATURE_FLAGS_START:
            return {
                ...state,
                featureFlagsLoading: true,
            };
        case EBrandManagementTypes.UPDATE_FEATURE_FLAGS_SUCCESS:
            return {
                ...state,
                featureFlags: payload,
                featureFlagsLoading: true,
            };
        case EBrandManagementTypes.UPDATE_FEATURE_FLAGS_FAILED:
            return {
                ...state,
                featureFlagsLoading: false,
            };
        default:
            return state;
    }
};

export default brandManagementReducer;
