import { getThemeFromLocalStorage } from 'helpers/local_storage';
import { ESystemTypes } from './actionsTypes';
import { SystemReducerState } from './interfaces';
import { isDesktop } from 'helpers/device_helper';

const initialState: SystemReducerState = {
    loader: true,
    theme: getThemeFromLocalStorage(),
    sideMenuState: isDesktop(),
    snackbarIsOpen: false,
    snackbarObject: null,
    filters: {},
    filterState: true,
    scrollToTable: { state: false, items: null },
};

const systemReducer = (state = initialState, { type, payload }: { type: ESystemTypes; payload: any }) => {
    switch (type) {
        case ESystemTypes.START_LOADER:
            return {
                ...state,
                loader: true,
            };
        case ESystemTypes.STOP_LOADER:
            return {
                ...state,
                loader: false,
            };
        case ESystemTypes.TOGGLE_THEME:
            return {
                ...state,
                theme: payload,
            };
        case ESystemTypes.TOGGLE_SIDE_MENU:
            return {
                ...state,
                sideMenuState: !state.sideMenuState,
            };
        case ESystemTypes.OPEN_SNACKBAR:
            return {
                ...state,
                snackbarIsOpen: true,
                snackbarObject: payload,
            };
        case ESystemTypes.CLOSE_SNACKBAR:
            return {
                ...state,
                snackbarIsOpen: false,
                snackbarObject: null,
            };

        case ESystemTypes.DOWNLOAD_CSV:
            return { ...state };
        case ESystemTypes.TOGGLE_FILTER_STATE:
            return { ...state, filterState: !state.filterState };
        case ESystemTypes.UPDATE_FILTERS:
            return { ...state, filters: { ...payload } };
        case ESystemTypes.SCROLL_TO_TABLE: {
            return { ...state, scrollToTable: payload };
        }
        case ESystemTypes.RESET_APP: {
            return {
                ...state,
                loader: false,
            };
        }
        default:
            return state;
    }
};

export default systemReducer;
