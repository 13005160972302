import { Pages } from 'interfaces/main';
import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import LoaderFullScreen from 'components/LoaderFullScreen';
import { auth_loader, auth_hasJwtToken } from 'store/auth/selectors';
import { connect } from 'react-redux';
import { user_loader, user_loggedIn, user_rolePermitted } from 'store/user/selectors';
import { saveLinkToSessionStorage } from 'helpers/linkRemember';
import { deleteTokenFromLocalStorage, getTokenFromLocalStorage } from 'helpers/token';
import { UserInfoRoleEnum } from 'services/generatedClientFromSwagger';

export interface UserRouteProps {
    component: any;
    hasJwtToken: boolean;
    loginLoading: boolean;
    loggedIn: boolean;
    userLoader: boolean;
    user_rolePermitted: (roles: Array<UserInfoRoleEnum>) => boolean;
}

const UserRoute: React.FunctionComponent<UserRouteProps> = (props) => {
    const {
        component: Component,
        hasJwtToken,
        loginLoading,
        userLoader,
        loggedIn,
        user_rolePermitted,
        ...restProps
    } = props;

    const { pathname } = useLocation();

    if (!hasJwtToken && !loginLoading) {
        const token = getTokenFromLocalStorage();
        saveLinkToSessionStorage(pathname, token);
        deleteTokenFromLocalStorage();
        document.location.replace(`/${Pages.LOGIN}`);
        return null;
    }

    if (loginLoading || (userLoader && !loggedIn)) {
        return <LoaderFullScreen />;
    }

    // if (
    //     user_rolePermitted([UserInfoRoleEnum.SuccessManagerAgent, UserInfoRoleEnum.SuccessManagerAdmin]) &&
    //     pathname.replace('/', '') !== Pages.BRAND_MANAGEMENT
    // ) {
    //     return <Redirect to={`/${Pages.BRAND_MANAGEMENT}`} />;
    // }

    return <Route {...restProps} render={(props) => <Component {...props} />} />;
};

export const mapStateToProps = (state) => ({
    hasJwtToken: auth_hasJwtToken(state),
    loginLoading: auth_loader(state),
    loggedIn: user_loggedIn(state),
    userLoader: user_loader(state),
    user_rolePermitted: (roles: Array<UserInfoRoleEnum>) => user_rolePermitted(state, roles),
});

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(UserRoute));
