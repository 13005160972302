/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CcsApiDBI } from './ccs-api-dbi';
import { ClientEnvDBI } from './client-env-dbi';
import { CrmApiDBI } from './crm-api-dbi';
import { DevOpsApiDBI } from './dev-ops-api-dbi';
import { ManagePaymentProvidersI } from './manage-payment-providers-i';
import { OrionBrand } from './orion-brand';
import { OtpApiDBI } from './otp-api-dbi';
import { PlatformApiDBI } from './platform-api-dbi';
import { PlatformServerApiDBI } from './platform-server-api-dbi';
import { ServerEnvDBI } from './server-env-dbi';

/**
 *
 * @export
 * @interface UpdateSiteRequest
 */
export class UpdateSiteRequest {
    /**
     *
     * @type {string}
     * @memberof UpdateSiteRequest
     */
    site_name: string;
    /**
     *
     * @type {ServerEnvDBI}
     * @memberof UpdateSiteRequest
     */
    server_env?: ServerEnvDBI;
    /**
     *
     * @type {ClientEnvDBI}
     * @memberof UpdateSiteRequest
     */
    client_env?: ClientEnvDBI;
    /**
     *
     * @type {CrmApiDBI}
     * @memberof UpdateSiteRequest
     */
    crm_api?: CrmApiDBI;
    /**
     *
     * @type {DevOpsApiDBI}
     * @memberof UpdateSiteRequest
     */
    devops_api?: DevOpsApiDBI;
    /**
     *
     * @type {PlatformApiDBI}
     * @memberof UpdateSiteRequest
     */
    platform_api?: PlatformApiDBI;
    /**
     *
     * @type {PlatformServerApiDBI}
     * @memberof UpdateSiteRequest
     */
    platform_server_api?: PlatformServerApiDBI;
    /**
     *
     * @type {OrionBrand}
     * @memberof UpdateSiteRequest
     */
    platform_orion_api?: OrionBrand;
    /**
     *
     * @type {OtpApiDBI}
     * @memberof UpdateSiteRequest
     */
    otp_api?: OtpApiDBI;
    /**
     *
     * @type {ManagePaymentProvidersI}
     * @memberof UpdateSiteRequest
     */
    payment_api?: ManagePaymentProvidersI;
    /**
     *
     * @type {CcsApiDBI}
     * @memberof UpdateSiteRequest
     */
    ccs_api?: CcsApiDBI;
    /**
     *
     * @type {CcsApiDBI}
     * @memberof UpdateSiteRequest
     */
    prop?: CcsApiDBI;
}
