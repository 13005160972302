import { createSelector } from 'reselect';
import { UserInfoRoleEnum } from 'services/generatedClientFromSwagger';
import { RootState } from '../index';

const userReducerSelector = (state: RootState) => state.user;
const userClusterLbsReducerSelector = (state: RootState) => state.user.clustersLBs;
const userAuditHistoryReducerSelector = (state: RootState) => state.user.auditHistory;
const userAddUsersReducerSelector = (state: RootState) => state.user.users;
const userIsProxyByCloudflareEnable = (state: RootState) => state.user.selectedSiteCloudflareProxy;

export const user_cloudflareCname = createSelector(
    userIsProxyByCloudflareEnable,
    (selectedSiteCloudflareProxy) => selectedSiteCloudflareProxy?.cname
);

export const user_loader = createSelector(userReducerSelector, (user) => user.loader);

export const user_userInfo = createSelector(userReducerSelector, (user) => user.userInfo || {});

export const user_loggedIn = createSelector(userReducerSelector, (user) => !!user.userInfo?.email);

export const user_selectedSite = createSelector(userReducerSelector, (user) => user.selectedSite);

export const user_sites = createSelector(userReducerSelector, (user) => {
    return user.sites.filter((site, index) => !site?.disabled).map((site) => site.name);
});

export const user_allSites = createSelector(userReducerSelector, (user) => {
    return user.sites.map((site) => site);
});

export const user_disabledSites = createSelector(userReducerSelector, (user) => {
    return user.sites
        .filter((site, index) => site?.disabled)
        .reduce((obj, item) => Object.assign(obj, { [item.name]: item.disabled }), {});
});

export const user_sitesLoading = createSelector(userReducerSelector, (user) => user.sitesLoading);

export const user_disabledSitesLoading = createSelector(userReducerSelector, (user) => user.disabledSitesLoading);

export const user_selectedSiteDataLoading = createSelector(userReducerSelector, (user) => user.selectedSiteDataLoading);

export const user_selectedSiteData = createSelector(userReducerSelector, (user) => user.selectedSiteData);

export const user_sitesData = createSelector(userReducerSelector, (user) => user.sitesData);

export const user_sitesDataLoading = createSelector(userReducerSelector, (user) => user.sitesDataLoading);

export const user_allFlags = createSelector(userReducerSelector, (user) => user.allFlags);

export const user_selectedSiteAssetsLoading = createSelector(
    userReducerSelector,
    (user) => user.selectedSiteAssetsLoading
);

export const user_selectedSiteAssets = createSelector(userReducerSelector, (user) => user.selectedSiteAssets);

export const user_rolePermitted = (state, roles: Array<UserInfoRoleEnum>) =>
    createSelector(userReducerSelector, (user) => {
        const userRole = user.userInfo?.role;
        return roles.some((role) => userRole === role);
    })(state);

export const user_downloadEmailLoading = createSelector(userReducerSelector, (user) => user.downloadEmailLoading);

export const user_clusterLbs = createSelector(userClusterLbsReducerSelector, (clusterLbs) => clusterLbs);

export const user_auditHistory = createSelector(userAuditHistoryReducerSelector, (auditHistory) => auditHistory);
export const user_auditHistoryLoading = createSelector(userReducerSelector, (user) => user.auditHistoryLoading);
export const user_brandApps = createSelector(
    userReducerSelector,
    (user) => user.selectedSiteData?.devops_api?.BRAND_APPS
);

export const user_chatBaseChatHistoryLoading = createSelector(
    userReducerSelector,
    (user) => user.chatBaseChatHistoryLoading
);
export const user_chatBaseChatHistory = createSelector(userReducerSelector, (user) => user.chatBaseChatHistory);
export const user_users = createSelector(userAddUsersReducerSelector, (users) => users || {});
export const user_usersLoading = createSelector(userReducerSelector, (user) => user.usersLoading);

export const user_deleteDomainLoader = createSelector(userReducerSelector, (user) => user.deleteDomainLoading);
export const user_deleteDomainErrorMessage = createSelector(
    userReducerSelector,
    (user) => user.deleteDomainErrorMessage
);

export const user_updateDomainLoader = createSelector(userReducerSelector, (user) => user.updateDomainLoading);
export const user_updateDomainErrorMessage = createSelector(
    userReducerSelector,
    (user) => user.updateDomainErrorMessage
);
