import { ThemeOptions } from 'interfaces/main';

interface ConfigInterface {
    version: string;
    tokenKey: string;
    api: { URL: string | undefined };
    googleClientId: string;
    googleApiKey: string;
    recaptchaKey: string;
    theme: {
        default_theme: ThemeOptions;
        local_storage_key: string;
    };
    linkRememberKey: string;
    lastSelectedSiteKey: string;
}

const config: ConfigInterface = {
    version: process.env.REACT_APP_VERSION || '3',
    api: {
        URL: process.env.REACT_APP_BACKEND_URL || `${window.location.protocol}//${window.location.host}`,
    },
    googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
    recaptchaKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || '',
    tokenKey: process.env.REACT_APP_TOKEN_KEY_NAME || 'tokenKey',
    theme: {
        default_theme: ThemeOptions.DARK,
        local_storage_key: 'theme',
    },
    linkRememberKey: 'redirectToAfterLogin',
    lastSelectedSiteKey: 'selectedSite',
};

export default config;
