interface Locales {
    [language: string]: {
        code: string;
        display_name: string;
        dir: string;
    };
}

const fallbacklLanguage = {
    en: {
        code: 'en',
        display_name: 'EN',
        dir: 'ltr',
    },
};

export const rtlLanguages = ['ar', 'arc', 'dv', 'fa', 'ha', 'he', 'khw', 'ks', 'ku', 'ps', 'ur', 'yi'];

export const isRtlLanguage = (lng: string) => rtlLanguages.some((l) => l === lng);

export const getLanguageDirection = (lng: string) => (isRtlLanguage(lng) ? 'rtl' : 'ltr');

export const locales: Locales =
    (process.env.REACT_APP_I18N_LNG_LIST && JSON.parse(process.env.REACT_APP_I18N_LNG_LIST)) || fallbacklLanguage;

export const localesCodeMap = Object.keys(locales).map((key) => locales[key].code);

export const defaultI18Lng = process.env.REACT_APP_I18N_DEFAULT_LNG || 'en-US';
