import React from 'react';
import { LanguageSelectProps } from './language_select.scheme';
import { locales } from 'config/i18n';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import Select from 'components/Inputs/Select';
import useStyles from './language_select.styles';

const LanguageSelect: React.FunctionComponent<LanguageSelectProps> = () => {
    const classes = useStyles();
    const {
        i18n: { language },
    } = useTranslation();
    const changeLanaguage = (lng: string) => {
        if (lng !== language) {
            i18n.changeLanguage(lng);
        }
    };

    return (
        <div className={classes.select_wrapper}>
            <Select
                value={language}
                options={Object.keys(locales).map((lngKey) => ({
                    label: locales[lngKey].display_name,
                    value: locales[lngKey].code,
                }))}
                onChange={changeLanaguage}
            />
        </div>
        // <Fab size="small">
        //     <select name="languages" id="languages" onChange={(e) => changeLanaguage(e.target.value)} value={language}>
        //         {Object.keys(locales).map((lngKey) => (
        //             <option key={lngKey} value={locales[lngKey].code}>
        //                 {locales[lngKey].display_name}
        //             </option>
        //         ))}
        //     </select>
        // </Fab>
    );
};

export default LanguageSelect;
