/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddFeatureFlagsByGroupRequest } from '../models';
// @ts-ignore
import { AuditLogsDBI } from '../models';
// @ts-ignore
import { ChatData } from '../models';
// @ts-ignore
import { CheckCrmDependenciesRequest } from '../models';
// @ts-ignore
import { CheckPropertiesResponse } from '../models';
// @ts-ignore
import { CopySiteRequest } from '../models';
// @ts-ignore
import { CreateGroupRequest } from '../models';
// @ts-ignore
import { CreateGroupResponse } from '../models';
// @ts-ignore
import { CreateSiteRequest } from '../models';
// @ts-ignore
import { CreateSiteResponse } from '../models';
// @ts-ignore
import { DeleteAssetPublicRequest } from '../models';
// @ts-ignore
import { DeleteAssetPublicResponse } from '../models';
// @ts-ignore
import { DeleteAssetRequest } from '../models';
// @ts-ignore
import { DeleteAssetResponse } from '../models';
// @ts-ignore
import { DeleteGroupRequest } from '../models';
// @ts-ignore
import { DeleteGroupResponse } from '../models';
// @ts-ignore
import { DeleteSiteRequest } from '../models';
// @ts-ignore
import { DeleteSiteResponse } from '../models';
// @ts-ignore
import { GetAllSitesResponse } from '../models';
// @ts-ignore
import { GetAppCodeResponse } from '../models';
// @ts-ignore
import { GetAssetsStructureResponse } from '../models';
// @ts-ignore
import { GetFlagsResponse } from '../models';
// @ts-ignore
import { GetSiteResponse } from '../models';
// @ts-ignore
import { S3File } from '../models';
// @ts-ignore
import { UpdateSiteRequest } from '../models';
// @ts-ignore
import { UpdateSiteResponse } from '../models';
// @ts-ignore
import { UpdateSitesByGroupRequest } from '../models';
// @ts-ignore
import { UpdateSitesByGroupResponse } from '../models';
// @ts-ignore
import { UploadAssetPublicRequest } from '../models';
// @ts-ignore
import { UploadAssetPublicResponse } from '../models';
// @ts-ignore
import { UploadAssetRequest } from '../models';
// @ts-ignore
import { UploadAssetResponse } from '../models';
/**
 * SiteApi - axios parameter creator
 * @export
 */
export const SiteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {AddFeatureFlagsByGroupRequest} addFeatureFlagsByGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerAddFeatureFlagsByGroup: async (
            addFeatureFlagsByGroupRequest: AddFeatureFlagsByGroupRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'addFeatureFlagsByGroupRequest' is not null or undefined
            assertParamExists(
                'siteControllerAddFeatureFlagsByGroup',
                'addFeatureFlagsByGroupRequest',
                addFeatureFlagsByGroupRequest
            );
            const localVarPath = `/api/site/AddFeatureFlagsByGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                addFeatureFlagsByGroupRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CheckCrmDependenciesRequest} checkCrmDependenciesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerCheckCrmDependencies: async (
            checkCrmDependenciesRequest: CheckCrmDependenciesRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'checkCrmDependenciesRequest' is not null or undefined
            assertParamExists(
                'siteControllerCheckCrmDependencies',
                'checkCrmDependenciesRequest',
                checkCrmDependenciesRequest
            );
            const localVarPath = `/api/site/checkCrmDependencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                checkCrmDependenciesRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CopySiteRequest} copySiteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerCopySite: async (copySiteRequest: CopySiteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'copySiteRequest' is not null or undefined
            assertParamExists('siteControllerCopySite', 'copySiteRequest', copySiteRequest);
            const localVarPath = `/api/site/CopySite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(copySiteRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreateGroupRequest} createGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerCreateGroup: async (
            createGroupRequest: CreateGroupRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'createGroupRequest' is not null or undefined
            assertParamExists('siteControllerCreateGroup', 'createGroupRequest', createGroupRequest);
            const localVarPath = `/api/site/CreateGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createGroupRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreateSiteRequest} createSiteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerCreateSite: async (
            createSiteRequest: CreateSiteRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'createSiteRequest' is not null or undefined
            assertParamExists('siteControllerCreateSite', 'createSiteRequest', createSiteRequest);
            const localVarPath = `/api/site/CreateSite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createSiteRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DeleteAssetRequest} deleteAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerDeleteAsset: async (
            deleteAssetRequest: DeleteAssetRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'deleteAssetRequest' is not null or undefined
            assertParamExists('siteControllerDeleteAsset', 'deleteAssetRequest', deleteAssetRequest);
            const localVarPath = `/api/site/DeleteAsset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                deleteAssetRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DeleteAssetPublicRequest} deleteAssetPublicRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerDeleteAssetPublic: async (
            deleteAssetPublicRequest: DeleteAssetPublicRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'deleteAssetPublicRequest' is not null or undefined
            assertParamExists('siteControllerDeleteAssetPublic', 'deleteAssetPublicRequest', deleteAssetPublicRequest);
            const localVarPath = `/api/site/DeleteAssetPublic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                deleteAssetPublicRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DeleteGroupRequest} deleteGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerDeleteGroup: async (
            deleteGroupRequest: DeleteGroupRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'deleteGroupRequest' is not null or undefined
            assertParamExists('siteControllerDeleteGroup', 'deleteGroupRequest', deleteGroupRequest);
            const localVarPath = `/api/site/DeleteGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                deleteGroupRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DeleteSiteRequest} deleteSiteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerDeleteSite: async (
            deleteSiteRequest: DeleteSiteRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'deleteSiteRequest' is not null or undefined
            assertParamExists('siteControllerDeleteSite', 'deleteSiteRequest', deleteSiteRequest);
            const localVarPath = `/api/site/DeleteSite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                deleteSiteRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} namespace
         * @param {string} projectName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerDownloadEmailTemplates: async (
            namespace: string,
            projectName: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('siteControllerDownloadEmailTemplates', 'namespace', namespace);
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('siteControllerDownloadEmailTemplates', 'projectName', projectName);
            const localVarPath = `/api/site/DownloadEmailTemplates/{namespace}/{project_name}`
                .replace(`{${'namespace'}}`, encodeURIComponent(String(namespace)))
                .replace(`{${'project_name'}}`, encodeURIComponent(String(projectName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetAllSites: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/site/GetAllSites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} groupName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetAllSitesByGroup: async (groupName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupName' is not null or undefined
            assertParamExists('siteControllerGetAllSitesByGroup', 'groupName', groupName);
            const localVarPath = `/api/site/GetAllSitesByGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (groupName !== undefined) {
                localVarQueryParameter['group_name'] = groupName;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} appCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetAppCode: async (appCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appCode' is not null or undefined
            assertParamExists('siteControllerGetAppCode', 'appCode', appCode);
            const localVarPath = `/api/site/GetAppCode/{appCode}`.replace(
                `{${'appCode'}}`,
                encodeURIComponent(String(appCode))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} nameSpace
         * @param {string} key
         * @param {string} [folderPrefix]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetAsset: async (
            nameSpace: string,
            key: string,
            folderPrefix?: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'nameSpace' is not null or undefined
            assertParamExists('siteControllerGetAsset', 'nameSpace', nameSpace);
            // verify required parameter 'key' is not null or undefined
            assertParamExists('siteControllerGetAsset', 'key', key);
            const localVarPath = `/api/site/GetAsset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (nameSpace !== undefined) {
                localVarQueryParameter['name_space'] = nameSpace;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (folderPrefix !== undefined) {
                localVarQueryParameter['folderPrefix'] = folderPrefix;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} nameSpace
         * @param {string} key
         * @param {string} [folderPrefix]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetAssetPublic: async (
            nameSpace: string,
            key: string,
            folderPrefix?: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'nameSpace' is not null or undefined
            assertParamExists('siteControllerGetAssetPublic', 'nameSpace', nameSpace);
            // verify required parameter 'key' is not null or undefined
            assertParamExists('siteControllerGetAssetPublic', 'key', key);
            const localVarPath = `/api/site/GetAssetPublic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (nameSpace !== undefined) {
                localVarQueryParameter['name_space'] = nameSpace;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (folderPrefix !== undefined) {
                localVarQueryParameter['folderPrefix'] = folderPrefix;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} nameSpace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetAssetsStructure: async (nameSpace: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nameSpace' is not null or undefined
            assertParamExists('siteControllerGetAssetsStructure', 'nameSpace', nameSpace);
            const localVarPath = `/api/site/GetAssetsStructure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (nameSpace !== undefined) {
                localVarQueryParameter['name_space'] = nameSpace;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} namespace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetAuditHistory: async (namespace: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('siteControllerGetAuditHistory', 'namespace', namespace);
            const localVarPath = `/api/site/GetAuditHistory/{namespace}`.replace(
                `{${'namespace'}}`,
                encodeURIComponent(String(namespace))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} namespace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetChatBaseChatHistory: async (namespace: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('siteControllerGetChatBaseChatHistory', 'namespace', namespace);
            const localVarPath = `/api/site/GetChatBaseChatHistory/{namespace}`.replace(
                `{${'namespace'}}`,
                encodeURIComponent(String(namespace))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetFlags: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/site/getFlags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {'NULL' | 'CZ' | 'WT' | 'CZ_WT' | 'PROP_WT' | 'CRYPTO'} brandApps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetNamespaces: async (
            brandApps: 'NULL' | 'CZ' | 'WT' | 'CZ_WT' | 'PROP_WT' | 'CRYPTO',
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'brandApps' is not null or undefined
            assertParamExists('siteControllerGetNamespaces', 'brandApps', brandApps);
            const localVarPath = `/api/site/namespaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (brandApps !== undefined) {
                localVarQueryParameter['brandApps'] = brandApps;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetSite: async (siteName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteName' is not null or undefined
            assertParamExists('siteControllerGetSite', 'siteName', siteName);
            const localVarPath = `/api/site/GetSite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (siteName !== undefined) {
                localVarQueryParameter['site_name'] = siteName;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} siteName
         * @param {'server' | 'client' | 'crm' | 'otp' | 'platform' | 'platform_server' | 'ccs' | 'idv' | 'prop' | 'crypto'} env
         * @param {'envJson' | 'json'} [responseType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetSiteEnv: async (
            siteName: string,
            env:
                | 'server'
                | 'client'
                | 'crm'
                | 'otp'
                | 'platform'
                | 'platform_server'
                | 'ccs'
                | 'idv'
                | 'prop'
                | 'crypto',
            responseType?: 'envJson' | 'json',
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'siteName' is not null or undefined
            assertParamExists('siteControllerGetSiteEnv', 'siteName', siteName);
            // verify required parameter 'env' is not null or undefined
            assertParamExists('siteControllerGetSiteEnv', 'env', env);
            const localVarPath = `/api/site/GetSiteEnv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (siteName !== undefined) {
                localVarQueryParameter['site_name'] = siteName;
            }

            if (env !== undefined) {
                localVarQueryParameter['env'] = env;
            }

            if (responseType !== undefined) {
                localVarQueryParameter['responseType'] = responseType;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerIsCloudFlare: async (siteName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteName' is not null or undefined
            assertParamExists('siteControllerIsCloudFlare', 'siteName', siteName);
            const localVarPath = `/api/site/IsCloudFlare`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (siteName !== undefined) {
                localVarQueryParameter['site_name'] = siteName;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerSyncAppCodes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/site/SyncAppCodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {object} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerUpdateDisabledSites: async (body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('siteControllerUpdateDisabledSites', 'body', body);
            const localVarPath = `/api/site/UpdateDisabledSites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdateSiteRequest} updateSiteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerUpdateSite: async (
            updateSiteRequest: UpdateSiteRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'updateSiteRequest' is not null or undefined
            assertParamExists('siteControllerUpdateSite', 'updateSiteRequest', updateSiteRequest);
            const localVarPath = `/api/site/UpdateSite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updateSiteRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdateSitesByGroupRequest} updateSitesByGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerUpdateSitesByGroup: async (
            updateSitesByGroupRequest: UpdateSitesByGroupRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'updateSitesByGroupRequest' is not null or undefined
            assertParamExists(
                'siteControllerUpdateSitesByGroup',
                'updateSitesByGroupRequest',
                updateSitesByGroupRequest
            );
            const localVarPath = `/api/site/UpdateSitesByGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updateSitesByGroupRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UploadAssetRequest} uploadAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerUploadAsset: async (
            uploadAssetRequest: UploadAssetRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'uploadAssetRequest' is not null or undefined
            assertParamExists('siteControllerUploadAsset', 'uploadAssetRequest', uploadAssetRequest);
            const localVarPath = `/api/site/UploadAsset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                uploadAssetRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UploadAssetPublicRequest} uploadAssetPublicRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerUploadAssetPublic: async (
            uploadAssetPublicRequest: UploadAssetPublicRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'uploadAssetPublicRequest' is not null or undefined
            assertParamExists('siteControllerUploadAssetPublic', 'uploadAssetPublicRequest', uploadAssetPublicRequest);
            const localVarPath = `/api/site/UploadAssetPublic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                uploadAssetPublicRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * SiteApi - functional programming interface
 * @export
 */
export const SiteApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {AddFeatureFlagsByGroupRequest} addFeatureFlagsByGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerAddFeatureFlagsByGroup(
            addFeatureFlagsByGroupRequest: AddFeatureFlagsByGroupRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerAddFeatureFlagsByGroup(
                addFeatureFlagsByGroupRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {CheckCrmDependenciesRequest} checkCrmDependenciesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerCheckCrmDependencies(
            checkCrmDependenciesRequest: CheckCrmDependenciesRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckPropertiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerCheckCrmDependencies(
                checkCrmDependenciesRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {CopySiteRequest} copySiteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerCopySite(
            copySiteRequest: CopySiteRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerCopySite(copySiteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {CreateGroupRequest} createGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerCreateGroup(
            createGroupRequest: CreateGroupRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerCreateGroup(
                createGroupRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {CreateSiteRequest} createSiteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerCreateSite(
            createSiteRequest: CreateSiteRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerCreateSite(
                createSiteRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {DeleteAssetRequest} deleteAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerDeleteAsset(
            deleteAssetRequest: DeleteAssetRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAssetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerDeleteAsset(
                deleteAssetRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {DeleteAssetPublicRequest} deleteAssetPublicRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerDeleteAssetPublic(
            deleteAssetPublicRequest: DeleteAssetPublicRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAssetPublicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerDeleteAssetPublic(
                deleteAssetPublicRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {DeleteGroupRequest} deleteGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerDeleteGroup(
            deleteGroupRequest: DeleteGroupRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerDeleteGroup(
                deleteGroupRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {DeleteSiteRequest} deleteSiteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerDeleteSite(
            deleteSiteRequest: DeleteSiteRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteSiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerDeleteSite(
                deleteSiteRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} namespace
         * @param {string} projectName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerDownloadEmailTemplates(
            namespace: string,
            projectName: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerDownloadEmailTemplates(
                namespace,
                projectName,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerGetAllSites(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllSitesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerGetAllSites(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} groupName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerGetAllSitesByGroup(
            groupName: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllSitesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerGetAllSitesByGroup(
                groupName,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} appCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerGetAppCode(
            appCode: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAppCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerGetAppCode(appCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} nameSpace
         * @param {string} key
         * @param {string} [folderPrefix]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerGetAsset(
            nameSpace: string,
            key: string,
            folderPrefix?: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerGetAsset(
                nameSpace,
                key,
                folderPrefix,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} nameSpace
         * @param {string} key
         * @param {string} [folderPrefix]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerGetAssetPublic(
            nameSpace: string,
            key: string,
            folderPrefix?: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerGetAssetPublic(
                nameSpace,
                key,
                folderPrefix,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} nameSpace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerGetAssetsStructure(
            nameSpace: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAssetsStructureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerGetAssetsStructure(
                nameSpace,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} namespace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerGetAuditHistory(
            namespace: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditLogsDBI>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerGetAuditHistory(namespace, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} namespace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerGetChatBaseChatHistory(
            namespace: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChatData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerGetChatBaseChatHistory(
                namespace,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerGetFlags(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFlagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerGetFlags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {'NULL' | 'CZ' | 'WT' | 'CZ_WT' | 'PROP_WT' | 'CRYPTO'} brandApps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerGetNamespaces(
            brandApps: 'NULL' | 'CZ' | 'WT' | 'CZ_WT' | 'PROP_WT' | 'CRYPTO',
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerGetNamespaces(brandApps, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerGetSite(
            siteName: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerGetSite(siteName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} siteName
         * @param {'server' | 'client' | 'crm' | 'otp' | 'platform' | 'platform_server' | 'ccs' | 'idv' | 'prop' | 'crypto'} env
         * @param {'envJson' | 'json'} [responseType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerGetSiteEnv(
            siteName: string,
            env:
                | 'server'
                | 'client'
                | 'crm'
                | 'otp'
                | 'platform'
                | 'platform_server'
                | 'ccs'
                | 'idv'
                | 'prop'
                | 'crypto',
            responseType?: 'envJson' | 'json',
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerGetSiteEnv(
                siteName,
                env,
                responseType,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerIsCloudFlare(
            siteName: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerIsCloudFlare(siteName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerSyncAppCodes(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerSyncAppCodes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {object} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerUpdateDisabledSites(
            body: object,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllSitesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerUpdateDisabledSites(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UpdateSiteRequest} updateSiteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerUpdateSite(
            updateSiteRequest: UpdateSiteRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerUpdateSite(
                updateSiteRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UpdateSitesByGroupRequest} updateSitesByGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerUpdateSitesByGroup(
            updateSitesByGroupRequest: UpdateSitesByGroupRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSitesByGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerUpdateSitesByGroup(
                updateSitesByGroupRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UploadAssetRequest} uploadAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerUploadAsset(
            uploadAssetRequest: UploadAssetRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadAssetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerUploadAsset(
                uploadAssetRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UploadAssetPublicRequest} uploadAssetPublicRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteControllerUploadAssetPublic(
            uploadAssetPublicRequest: UploadAssetPublicRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadAssetPublicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteControllerUploadAssetPublic(
                uploadAssetPublicRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * SiteApi - factory interface
 * @export
 */
export const SiteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteApiFp(configuration);
    return {
        /**
         *
         * @param {AddFeatureFlagsByGroupRequest} addFeatureFlagsByGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerAddFeatureFlagsByGroup(
            addFeatureFlagsByGroupRequest: AddFeatureFlagsByGroupRequest,
            options?: any
        ): AxiosPromise<boolean> {
            return localVarFp
                .siteControllerAddFeatureFlagsByGroup(addFeatureFlagsByGroupRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CheckCrmDependenciesRequest} checkCrmDependenciesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerCheckCrmDependencies(
            checkCrmDependenciesRequest: CheckCrmDependenciesRequest,
            options?: any
        ): AxiosPromise<CheckPropertiesResponse> {
            return localVarFp
                .siteControllerCheckCrmDependencies(checkCrmDependenciesRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CopySiteRequest} copySiteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerCopySite(copySiteRequest: CopySiteRequest, options?: any): AxiosPromise<UpdateSiteResponse> {
            return localVarFp
                .siteControllerCopySite(copySiteRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CreateGroupRequest} createGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerCreateGroup(
            createGroupRequest: CreateGroupRequest,
            options?: any
        ): AxiosPromise<CreateGroupResponse> {
            return localVarFp
                .siteControllerCreateGroup(createGroupRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CreateSiteRequest} createSiteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerCreateSite(
            createSiteRequest: CreateSiteRequest,
            options?: any
        ): AxiosPromise<CreateSiteResponse> {
            return localVarFp
                .siteControllerCreateSite(createSiteRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DeleteAssetRequest} deleteAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerDeleteAsset(
            deleteAssetRequest: DeleteAssetRequest,
            options?: any
        ): AxiosPromise<DeleteAssetResponse> {
            return localVarFp
                .siteControllerDeleteAsset(deleteAssetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DeleteAssetPublicRequest} deleteAssetPublicRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerDeleteAssetPublic(
            deleteAssetPublicRequest: DeleteAssetPublicRequest,
            options?: any
        ): AxiosPromise<DeleteAssetPublicResponse> {
            return localVarFp
                .siteControllerDeleteAssetPublic(deleteAssetPublicRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DeleteGroupRequest} deleteGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerDeleteGroup(
            deleteGroupRequest: DeleteGroupRequest,
            options?: any
        ): AxiosPromise<DeleteGroupResponse> {
            return localVarFp
                .siteControllerDeleteGroup(deleteGroupRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DeleteSiteRequest} deleteSiteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerDeleteSite(
            deleteSiteRequest: DeleteSiteRequest,
            options?: any
        ): AxiosPromise<DeleteSiteResponse> {
            return localVarFp
                .siteControllerDeleteSite(deleteSiteRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} namespace
         * @param {string} projectName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerDownloadEmailTemplates(
            namespace: string,
            projectName: string,
            options?: any
        ): AxiosPromise<void> {
            return localVarFp
                .siteControllerDownloadEmailTemplates(namespace, projectName, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetAllSites(options?: any): AxiosPromise<GetAllSitesResponse> {
            return localVarFp.siteControllerGetAllSites(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} groupName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetAllSitesByGroup(groupName: string, options?: any): AxiosPromise<GetAllSitesResponse> {
            return localVarFp
                .siteControllerGetAllSitesByGroup(groupName, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} appCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetAppCode(appCode: string, options?: any): AxiosPromise<GetAppCodeResponse> {
            return localVarFp.siteControllerGetAppCode(appCode, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} nameSpace
         * @param {string} key
         * @param {string} [folderPrefix]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetAsset(
            nameSpace: string,
            key: string,
            folderPrefix?: string,
            options?: any
        ): AxiosPromise<S3File> {
            return localVarFp
                .siteControllerGetAsset(nameSpace, key, folderPrefix, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} nameSpace
         * @param {string} key
         * @param {string} [folderPrefix]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetAssetPublic(
            nameSpace: string,
            key: string,
            folderPrefix?: string,
            options?: any
        ): AxiosPromise<S3File> {
            return localVarFp
                .siteControllerGetAssetPublic(nameSpace, key, folderPrefix, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} nameSpace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetAssetsStructure(nameSpace: string, options?: any): AxiosPromise<GetAssetsStructureResponse> {
            return localVarFp
                .siteControllerGetAssetsStructure(nameSpace, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} namespace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetAuditHistory(namespace: string, options?: any): AxiosPromise<Array<AuditLogsDBI>> {
            return localVarFp
                .siteControllerGetAuditHistory(namespace, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} namespace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetChatBaseChatHistory(namespace: string, options?: any): AxiosPromise<Array<ChatData>> {
            return localVarFp
                .siteControllerGetChatBaseChatHistory(namespace, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetFlags(options?: any): AxiosPromise<GetFlagsResponse> {
            return localVarFp.siteControllerGetFlags(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {'NULL' | 'CZ' | 'WT' | 'CZ_WT' | 'PROP_WT' | 'CRYPTO'} brandApps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetNamespaces(
            brandApps: 'NULL' | 'CZ' | 'WT' | 'CZ_WT' | 'PROP_WT' | 'CRYPTO',
            options?: any
        ): AxiosPromise<string> {
            return localVarFp
                .siteControllerGetNamespaces(brandApps, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetSite(siteName: string, options?: any): AxiosPromise<GetSiteResponse> {
            return localVarFp.siteControllerGetSite(siteName, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} siteName
         * @param {'server' | 'client' | 'crm' | 'otp' | 'platform' | 'platform_server' | 'ccs' | 'idv' | 'prop' | 'crypto'} env
         * @param {'envJson' | 'json'} [responseType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerGetSiteEnv(
            siteName: string,
            env:
                | 'server'
                | 'client'
                | 'crm'
                | 'otp'
                | 'platform'
                | 'platform_server'
                | 'ccs'
                | 'idv'
                | 'prop'
                | 'crypto',
            responseType?: 'envJson' | 'json',
            options?: any
        ): AxiosPromise<void> {
            return localVarFp
                .siteControllerGetSiteEnv(siteName, env, responseType, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerIsCloudFlare(siteName: string, options?: any): AxiosPromise<void> {
            return localVarFp.siteControllerIsCloudFlare(siteName, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerSyncAppCodes(options?: any): AxiosPromise<void> {
            return localVarFp.siteControllerSyncAppCodes(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {object} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerUpdateDisabledSites(body: object, options?: any): AxiosPromise<GetAllSitesResponse> {
            return localVarFp
                .siteControllerUpdateDisabledSites(body, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdateSiteRequest} updateSiteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerUpdateSite(
            updateSiteRequest: UpdateSiteRequest,
            options?: any
        ): AxiosPromise<UpdateSiteResponse> {
            return localVarFp
                .siteControllerUpdateSite(updateSiteRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdateSitesByGroupRequest} updateSitesByGroupRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerUpdateSitesByGroup(
            updateSitesByGroupRequest: UpdateSitesByGroupRequest,
            options?: any
        ): AxiosPromise<UpdateSitesByGroupResponse> {
            return localVarFp
                .siteControllerUpdateSitesByGroup(updateSitesByGroupRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UploadAssetRequest} uploadAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerUploadAsset(
            uploadAssetRequest: UploadAssetRequest,
            options?: any
        ): AxiosPromise<UploadAssetResponse> {
            return localVarFp
                .siteControllerUploadAsset(uploadAssetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UploadAssetPublicRequest} uploadAssetPublicRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteControllerUploadAssetPublic(
            uploadAssetPublicRequest: UploadAssetPublicRequest,
            options?: any
        ): AxiosPromise<UploadAssetPublicResponse> {
            return localVarFp
                .siteControllerUploadAssetPublic(uploadAssetPublicRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * SiteApi - object-oriented interface
 * @export
 * @class SiteApi
 * @extends {BaseAPI}
 */
export class SiteApi extends BaseAPI {
    /**
     *
     * @param {AddFeatureFlagsByGroupRequest} addFeatureFlagsByGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerAddFeatureFlagsByGroup(
        addFeatureFlagsByGroupRequest: AddFeatureFlagsByGroupRequest,
        options?: any
    ) {
        return SiteApiFp(this.configuration)
            .siteControllerAddFeatureFlagsByGroup(addFeatureFlagsByGroupRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {CheckCrmDependenciesRequest} checkCrmDependenciesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerCheckCrmDependencies(checkCrmDependenciesRequest: CheckCrmDependenciesRequest, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerCheckCrmDependencies(checkCrmDependenciesRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {CopySiteRequest} copySiteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerCopySite(copySiteRequest: CopySiteRequest, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerCopySite(copySiteRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {CreateGroupRequest} createGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerCreateGroup(createGroupRequest: CreateGroupRequest, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerCreateGroup(createGroupRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {CreateSiteRequest} createSiteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerCreateSite(createSiteRequest: CreateSiteRequest, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerCreateSite(createSiteRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DeleteAssetRequest} deleteAssetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerDeleteAsset(deleteAssetRequest: DeleteAssetRequest, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerDeleteAsset(deleteAssetRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DeleteAssetPublicRequest} deleteAssetPublicRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerDeleteAssetPublic(deleteAssetPublicRequest: DeleteAssetPublicRequest, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerDeleteAssetPublic(deleteAssetPublicRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DeleteGroupRequest} deleteGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerDeleteGroup(deleteGroupRequest: DeleteGroupRequest, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerDeleteGroup(deleteGroupRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DeleteSiteRequest} deleteSiteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerDeleteSite(deleteSiteRequest: DeleteSiteRequest, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerDeleteSite(deleteSiteRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} namespace
     * @param {string} projectName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerDownloadEmailTemplates(namespace: string, projectName: string, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerDownloadEmailTemplates(namespace, projectName, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerGetAllSites(options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerGetAllSites(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} groupName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerGetAllSitesByGroup(groupName: string, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerGetAllSitesByGroup(groupName, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} appCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerGetAppCode(appCode: string, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerGetAppCode(appCode, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} nameSpace
     * @param {string} key
     * @param {string} [folderPrefix]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerGetAsset(nameSpace: string, key: string, folderPrefix?: string, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerGetAsset(nameSpace, key, folderPrefix, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} nameSpace
     * @param {string} key
     * @param {string} [folderPrefix]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerGetAssetPublic(nameSpace: string, key: string, folderPrefix?: string, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerGetAssetPublic(nameSpace, key, folderPrefix, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} nameSpace
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerGetAssetsStructure(nameSpace: string, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerGetAssetsStructure(nameSpace, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} namespace
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerGetAuditHistory(namespace: string, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerGetAuditHistory(namespace, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} namespace
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerGetChatBaseChatHistory(namespace: string, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerGetChatBaseChatHistory(namespace, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerGetFlags(options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerGetFlags(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {'NULL' | 'CZ' | 'WT' | 'CZ_WT' | 'PROP_WT' | 'CRYPTO'} brandApps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerGetNamespaces(
        brandApps: 'NULL' | 'CZ' | 'WT' | 'CZ_WT' | 'PROP_WT' | 'CRYPTO',
        options?: any
    ) {
        return SiteApiFp(this.configuration)
            .siteControllerGetNamespaces(brandApps, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} siteName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerGetSite(siteName: string, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerGetSite(siteName, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} siteName
     * @param {'server' | 'client' | 'crm' | 'otp' | 'platform' | 'platform_server' | 'ccs' | 'idv' | 'prop' | 'crypto'} env
     * @param {'envJson' | 'json'} [responseType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerGetSiteEnv(
        siteName: string,
        env: 'server' | 'client' | 'crm' | 'otp' | 'platform' | 'platform_server' | 'ccs' | 'idv' | 'prop' | 'crypto',
        responseType?: 'envJson' | 'json',
        options?: any
    ) {
        return SiteApiFp(this.configuration)
            .siteControllerGetSiteEnv(siteName, env, responseType, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} siteName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerIsCloudFlare(siteName: string, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerIsCloudFlare(siteName, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerSyncAppCodes(options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerSyncAppCodes(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerUpdateDisabledSites(body: object, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerUpdateDisabledSites(body, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UpdateSiteRequest} updateSiteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerUpdateSite(updateSiteRequest: UpdateSiteRequest, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerUpdateSite(updateSiteRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UpdateSitesByGroupRequest} updateSitesByGroupRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerUpdateSitesByGroup(updateSitesByGroupRequest: UpdateSitesByGroupRequest, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerUpdateSitesByGroup(updateSitesByGroupRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UploadAssetRequest} uploadAssetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerUploadAsset(uploadAssetRequest: UploadAssetRequest, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerUploadAsset(uploadAssetRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UploadAssetPublicRequest} uploadAssetPublicRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public siteControllerUploadAssetPublic(uploadAssetPublicRequest: UploadAssetPublicRequest, options?: any) {
        return SiteApiFp(this.configuration)
            .siteControllerUploadAssetPublic(uploadAssetPublicRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
