import { createStyles } from '@material-ui/core';

export default createStyles({
    root: {
        borderRadius: '8px',
        zIndex: 1,
    },
    input: {
        padding: '12.5px 12px',
    },
    notchedOutline: {
        borderWidth: '1px !important',
    },
    adornedEnd: {
        paddingInlineEnd: '12px',
    },
    adornedStart: {
        paddingInlineStart: '12px',
    },
    inputAdornedStart: {
        paddingInlineStart: '12px',
    },
    inputAdornedEnd: {
        paddingInlineEnd: '12px',
    },
});
