import config from 'config/common';

export const saveTokenToLocalStorage: (token: string) => void = (token: string) => {
    localStorage.setItem(config.tokenKey, token);
};

export const getTokenFromLocalStorage: () => string | undefined = () => {
    return localStorage.getItem(config.tokenKey) || undefined;
};

export const deleteTokenFromLocalStorage: () => void = () => {
    localStorage.removeItem(config.tokenKey);
};
