import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.tooltip.background,
        boxShadow: 'unset',
        color: theme.palette.tooltip.text,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.body2.fontWeight,
        padding: '0.8rem 1.2rem',
    },
    arrow: {
        color: theme.palette.tooltip.background,
    },
}));
