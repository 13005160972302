import * as React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { history } from 'utils/history';
import store from 'store';

import App from 'App';

import 'assets/styles/styles.scss';

const Root: React.FunctionComponent = () => {
    return (
        <Provider store={store}>
            <Router history={history}>
                <App />
            </Router>
        </Provider>
    );
};

export default Root;
