import { ThemeOptions } from 'interfaces/main';
import { user_getUserData, user_updateSite } from 'store/user/actions';
import { auth_hasJwtToken } from 'store/auth/selectors';
import { system_loader, system_theme } from 'store/system/selectors';
import {
    user_loggedIn,
    user_rolePermitted,
    user_selectedSiteData,
    user_selectedSiteDataLoading,
} from 'store/user/selectors';
import { GetSiteResponse, UpdateSiteRequest, UserInfoRoleEnum } from 'services/generatedClientFromSwagger';
import { system_closeSnackbar } from 'store/system/actions';

export interface AppProps {
    systemTheme: ThemeOptions;
    hasJwtToken: boolean;
    systemLoader: boolean;
    loggedIn: boolean;
    tryGetUserData: () => void;
    user_rolePermitted: (roles: Array<UserInfoRoleEnum>) => boolean;
    user_siteData: GetSiteResponse | undefined;
    user_siteDataLoading: boolean;
    system_closeSnackbar: () => void;
    user_updateSite: (payload: UpdateSiteRequest) => Promise<boolean>;
}
export const mapStateToProps = (state) => ({
    systemTheme: system_theme(state),
    systemLoader: system_loader(state),
    hasJwtToken: auth_hasJwtToken(state),
    loggedIn: user_loggedIn(state),
    user_rolePermitted: (roles: Array<UserInfoRoleEnum>) => user_rolePermitted(state, roles),
    user_siteData: user_selectedSiteData(state),
    user_siteDataLoading: user_selectedSiteDataLoading(state),
});

export const mapDispatchToProps = {
    tryGetUserData: user_getUserData,
    system_closeSnackbar,
    user_updateSite,
};
