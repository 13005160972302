/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FeatureFlagsRequest } from '../models';
// @ts-ignore
import { UpdateSiteResponse } from '../models';
/**
 * BrandManagementApi - axios parameter creator
 * @export
 */
export const BrandManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {FeatureFlagsRequest} featureFlagsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandManagementControllerUpdateFeatureFlags: async (
            featureFlagsRequest: FeatureFlagsRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'featureFlagsRequest' is not null or undefined
            assertParamExists(
                'brandManagementControllerUpdateFeatureFlags',
                'featureFlagsRequest',
                featureFlagsRequest
            );
            const localVarPath = `/api/brandManagement/UpdateFeatureFlags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                featureFlagsRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * BrandManagementApi - functional programming interface
 * @export
 */
export const BrandManagementApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BrandManagementApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {FeatureFlagsRequest} featureFlagsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandManagementControllerUpdateFeatureFlags(
            featureFlagsRequest: FeatureFlagsRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandManagementControllerUpdateFeatureFlags(
                featureFlagsRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * BrandManagementApi - factory interface
 * @export
 */
export const BrandManagementApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = BrandManagementApiFp(configuration);
    return {
        /**
         *
         * @param {FeatureFlagsRequest} featureFlagsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandManagementControllerUpdateFeatureFlags(
            featureFlagsRequest: FeatureFlagsRequest,
            options?: any
        ): AxiosPromise<UpdateSiteResponse> {
            return localVarFp
                .brandManagementControllerUpdateFeatureFlags(featureFlagsRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrandManagementApi - object-oriented interface
 * @export
 * @class BrandManagementApi
 * @extends {BaseAPI}
 */
export class BrandManagementApi extends BaseAPI {
    /**
     *
     * @param {FeatureFlagsRequest} featureFlagsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandManagementApi
     */
    public brandManagementControllerUpdateFeatureFlags(featureFlagsRequest: FeatureFlagsRequest, options?: any) {
        return BrandManagementApiFp(this.configuration)
            .brandManagementControllerUpdateFeatureFlags(featureFlagsRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
