import { createMuiTheme, Direction } from '@material-ui/core';
import { TypeText } from '@material-ui/core/styles/createPalette';

import palette from 'muiTheme/config/palette';
import typography from 'muiTheme/config/typography';
import breakpoints from 'muiTheme/config/breakpoints';
import overrides from 'muiTheme/ui/overrides';
import { ThemeOptions } from 'interfaces/main';

export default (theme: ThemeOptions, direction: Direction) =>
    createMuiTheme({
        overrides,
        palette: {
            type: theme,
            ...palette[theme],
        },
        typography: typography,
        breakpoints: {
            values: breakpoints,
        },
        spacing: 4,
        direction,
    });

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        default: Palette['primary'];
        side_menu: {
            background: string;
            text: string;
        };
        tooltip: {
            background: string;
            text: string;
        };
    }
    interface PaletteOptions {
        default: PaletteOptions['primary'];
        side_menu: {
            background: string;
            text: string;
        };
        tooltip: {
            background: string;
            text: string;
        };
    }
    interface TypeText {
        default: string;
        lightDefault: string;
    }
}
