import { user_changeSelectedSite, user_getSites } from 'store/user/actions';
import { user_selectedSite, user_sites, user_sitesLoading } from 'store/user/selectors';

export interface SiteSelectProps {
    selectedSite: string | undefined;
    sites: Array<string>;
    sitesLoading: boolean;
    user_changeSelectedSite: (site_name: string) => void;
    user_getSites: () => void;
}

export const mapStateToProps = (state) => ({
    selectedSite: user_selectedSite(state),
    sites: user_sites(state),
    sitesLoading: user_sitesLoading(state),
});

export const mapDispatchToProps = {
    user_changeSelectedSite,
    user_getSites,
};
