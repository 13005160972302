// import { colors } from '../../../config';
// import { fadeColor } from 'helpers/fadeColor';

export default {
    root: {
        fontSize: 12,
        // color: colors.black,
        // borderBottom: `1px solid ${fadeColor(colors.black, 8)}`,
    },
};
