import { Hidden } from '@material-ui/core';
import { connect } from 'react-redux';
import React from 'react';
import Logo from '../components/Logo';
import { PrivateHeaderProps, mapDispatchToProps, mapStateToProps } from './private-header.scheme';
import useStyles from '../header.styles';
import BackOrMenuBtn from 'components/BackOrMenuBtn';
import LanguageSelect from '../components/LanguageSelect';
import FloatingMenu from 'components/FloatingMenu';
import SiteSelect from 'components/SiteSelect';
import { UserInfoRoleEnum } from 'services/generatedClientFromSwagger';

const PrivateHeader: React.FunctionComponent<PrivateHeaderProps> = (props) => {
    const { user_rolePermitted, system_toggleSideMenu } = props;
    const classes = useStyles();

    return (
        <header className={classes.header_container__private}>
            <div className={classes.header_left_section}>
                <Hidden smUp>
                    <BackOrMenuBtn toggleSideMenu={system_toggleSideMenu} />
                </Hidden>
                <Logo />
                {user_rolePermitted([
                    UserInfoRoleEnum.Admin,
                    UserInfoRoleEnum.Readonly,
                    UserInfoRoleEnum.SuccessManagerAdmin,
                    UserInfoRoleEnum.SuccessManagerAgent,
                    UserInfoRoleEnum.Agent,
                ]) && (
                    <Hidden smUp>
                        <SiteSelect />
                    </Hidden>
                )}
            </div>
            <Hidden xsDown>
                <div className={classes.header_right_section}>
                    {user_rolePermitted([
                        UserInfoRoleEnum.Admin,
                        UserInfoRoleEnum.Readonly,
                        UserInfoRoleEnum.SuccessManagerAdmin,
                        UserInfoRoleEnum.SuccessManagerAgent,
                        UserInfoRoleEnum.Agent,
                    ]) && <SiteSelect />}
                    <LanguageSelect />
                    <FloatingMenu />
                </div>
            </Hidden>
        </header>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PrivateHeader));
