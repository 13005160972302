import { createStyles } from '@material-ui/core';

export default createStyles({
    root: {
        boxShadow: 'unset',
    },
    sizeSmall: {
        height: '36px',
        width: '36px',
    },
});
