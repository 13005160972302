import { ESystemTypes } from 'store/system/actionsTypes';
import { EUserTypes } from './actionsTypes';
import { UserReducerState } from './interfaces';

const initialState: UserReducerState = {
    loader: true,
    userInfo: undefined,
    sites: [],
    sitesLoading: false,
    selectedSite: undefined,
    selectedSiteDataLoading: false,
    selectedSiteData: undefined,

    selectedSiteAssets: undefined,
    selectedSiteAssetsLoading: false,

    sitesData: {},
    sitesDataLoading: false,
    allFlags: undefined,
    disabledSitesLoading: false,

    downloadEmailLoading: false,

    clustersLBs: [],

    auditHistoryLoading: false,
    auditHistory: [],

    chatBaseChatHistoryLoading: false,
    chatBaseChatHistory: {},

    usersLoading: false,
    users: [],
    selectedSiteCloudflareProxy: undefined,

    deleteDomainErrorMessage: undefined,
    deleteDomainLoading: false,

    updateDomainLoading: false,
    updateDomainErrorMessage: undefined,
};

const systemReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case EUserTypes.START_LOADER:
            return {
                ...state,
                loader: true,
            };
        case EUserTypes.STOP_LOADER:
            return {
                ...state,
                loader: false,
            };
        case EUserTypes.GET_USER_DATA_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case EUserTypes.GET_USER_DATA_FAILED: {
            return {
                ...state,
                loader: false,
            };
        }
        case EUserTypes.GET_USER_DATA_SUCCESS: {
            return {
                ...state,
                loader: false,
                userInfo: payload,
            };
        }
        case EUserTypes.GET_SITES_START: {
            return {
                ...state,
                sitesLoading: true,
            };
        }
        case EUserTypes.GET_SITES_SUCCESS: {
            return {
                ...state,
                sitesLoading: false,
                sites: payload,
            };
        }
        case EUserTypes.GET_SITES_FAILED: {
            return {
                ...state,
                disabledSitesLoading: false,
            };
        }
        case EUserTypes.GET_DISABLED_SITES_START: {
            return {
                ...state,
                disabledSitesLoading: true,
            };
        }
        case EUserTypes.GET_DISABLED_SITES_SUCCESS: {
            return {
                ...state,
                disabledSitesLoading: false,
                sites: payload,
            };
        }
        case EUserTypes.GET_DISABLED_SITES_FAILED: {
            return {
                ...state,
                sitesLoading: false,
            };
        }
        case EUserTypes.CHANGE_SELECTED_SITE: {
            return {
                ...state,
                selectedSite: payload,
            };
        }
        case EUserTypes.GET_SELECTED_SITE_START: {
            return {
                ...state,
                selectedSiteDataLoading: true,
            };
        }
        case EUserTypes.GET_SELECTED_SITE_SUCCESS: {
            return {
                ...state,
                selectedSiteDataLoading: false,
                selectedSiteData: payload,
            };
        }
        case EUserTypes.GET_SELECTED_SITE_FAILED: {
            return {
                ...state,
                selectedSiteDataLoading: false,
            };
        }
        case EUserTypes.GET_SELECTED_SITE_ASSETS_START: {
            return {
                ...state,
                selectedSiteAssetsLoading: true,
            };
        }
        case EUserTypes.GET_SELECTED_SITE_ASSETS_SUCCESS: {
            return {
                ...state,
                selectedSiteAssetsLoading: false,
                selectedSiteAssets: payload,
            };
        }
        case EUserTypes.GET_SELECTED_SITE_ASSETS_FAILED: {
            return {
                ...state,
                selectedSiteAssetsLoading: false,
            };
        }
        case EUserTypes.CREATE_NEW_SITE_START:
        case EUserTypes.CREATE_NEW_SITE_SUCCESS:
        case EUserTypes.CREATE_NEW_SITE_FAILED: {
            return {
                ...state,
            };
        }
        case EUserTypes.UPDATE_SITE_START: {
            return {
                ...state,
            };
        }
        case EUserTypes.UPDATE_SITE_SUCCESS: {
            return {
                ...state,
                selectedSiteData: payload,
            };
        }
        case EUserTypes.UPDATE_SITE_FAILED: {
            return {
                ...state,
            };
        }
        case EUserTypes.GET_SITE_DATA_START: {
            return {
                ...state,
                sitesDataLoading: true,
            };
        }
        case EUserTypes.GET_SITE_DATA_SUCCESS: {
            const { data, site_name } = payload;
            return {
                ...state,
                sitesDataLoading: false,
                sitesData: {
                    ...state.sitesData,
                    [site_name]: data,
                },
            };
        }
        case EUserTypes.GET_SITE_DATA_FAILED: {
            return {
                ...state,
                sitesDataLoading: false,
            };
        }
        case ESystemTypes.RESET_APP: {
            return {
                ...state,
                loader: false,
                userInfo: undefined,
                sites: [],
                sitesLoading: false,
                selectedSite: undefined,
                selectedSiteDataLoading: false,
                selectedSiteData: undefined,
                selectedSiteAssetsLoading: false,
                selectedSiteAssets: undefined,
                sitesData: {},
                sitesDataLoading: false,
            };
        }
        case EUserTypes.GET_ALL_FLAGS: {
            return {
                ...state,
                allFlags: payload,
            };
        }
        case EUserTypes.DOWNLOAD_EMAIL_TEMPLATES_START: {
            return {
                ...state,
                downloadEmailLoading: true,
            };
        }
        case EUserTypes.DOWNLOAD_EMAIL_TEMPLATES_FAILED: {
            return {
                ...state,
                downloadEmailLoading: false,
            };
        }
        case EUserTypes.DOWNLOAD_EMAIL_TEMPLATES_SUCCESS: {
            return {
                ...state,
                downloadEmailLoading: false,
            };
        }
        case EUserTypes.GET_CLUSTER_LBS_SUCCESS: {
            return {
                ...state,
                clustersLBs: payload,
            };
        }
        case EUserTypes.GET_AUDIT_HISTORY_START: {
            return {
                ...state,
                auditHistoryLoading: true,
            };
        }
        case EUserTypes.GET_AUDIT_HISTORY_SUCCESS: {
            return {
                ...state,
                auditHistoryLoading: false,
                auditHistory: payload,
            };
        }
        case EUserTypes.GET_AUDIT_HISTORY_FAILED: {
            return {
                ...state,
                auditHistoryLoading: false,
            };
        }

        case EUserTypes.GET_CHATBASE_CHAT_HISTORY_START: {
            return {
                ...state,
                chatBaseChatHistoryLoading: true,
            };
        }
        case EUserTypes.GET_CHATBASE_CHAT_HISTORY_SUCCESS: {
            return {
                ...state,
                chatBaseChatHistoryLoading: false,
                chatBaseChatHistory: payload,
            };
        }
        case EUserTypes.GET_CHATBASE_CHAT_HISTORY_FAILED: {
            return {
                ...state,
                chatBaseChatHistoryLoading: false,
            };
        }
        case EUserTypes.GET_USERS_START: {
            return {
                ...state,
                usersLoading: true,
            };
        }
        case EUserTypes.GET_USERS_SUCCESS: {
            return {
                ...state,
                users: payload,
                usersLoading: false,
            };
        }
        case EUserTypes.GET_USERS_FAILED: {
            return {
                ...state,
                usersLoading: false,
            };
        }
        case EUserTypes.CREATE_USERS_START: {
            return {
                ...state,
                usersLoading: true,
            };
        }
        case EUserTypes.CREATE_USERS_SUCCESS: {
            return {
                ...state,
                users: [...(state.users || []), payload],
                usersLoading: false,
            };
        }
        case EUserTypes.CREATE_USERS_FAILED: {
            return {
                ...state,
                usersLoading: false,
            };
        }
        case EUserTypes.DELETE_USERS_START: {
            return {
                ...state,
                usersLoading: true,
            };
        }
        case EUserTypes.DELETE_USERS_SUCCESS: {
            return {
                ...state,
                users: state.users?.filter((user) => user._id !== payload._id),
                usersLoading: false,
            };
        }
        case EUserTypes.DELETE_USERS_FAILED: {
            return {
                ...state,
                usersLoading: false,
            };
        }
        case EUserTypes.EDIT_USERS_START: {
            return {
                ...state,
                usersLoading: true,
            };
        }
        case EUserTypes.EDIT_USERS_SUCCESS: {
            return {
                ...state,
                users: state.users?.map((user) => {
                    if (user._id === payload._id) {
                        return { ...user, ...payload };
                    }
                    return user;
                }),
                usersLoading: false,
            };
        }
        case EUserTypes.EDIT_USERS_FAILED: {
            return {
                ...state,
                usersLoading: false,
            };
        }
        case EUserTypes.GET_IS_PROXY_BY_CLOUDFLARE_ENABLED: {
            return {
                ...state,
                selectedSiteCloudflareProxy: payload,
            };
        }
        case EUserTypes.DELETE_DOMAIN_START: {
            return {
                ...state,
                deleteDomainLoading: true,
            };
        }
        case EUserTypes.DELETE_DOMAIN_SUCCESS: {
            return {
                ...state,
                deleteDomainLoading: false,
            };
        }
        case EUserTypes.DELETE_DOMAIN_FAILED: {
            return {
                ...state,
                deleteDomainLoading: false,
                deleteDomainErrorMessage: payload,
            };
        }
        case EUserTypes.UPDATE_DOMAIN_START: {
            return {
                ...state,
                updateDomainLoading: true,
            };
        }
        case EUserTypes.UPDATE_DOMAIN_SUCCESS: {
            return {
                ...state,
                updateDomainLoading: false,
            };
        }
        case EUserTypes.UPDATE_DOMAIN_FAILED: {
            return {
                ...state,
                updateDomainLoading: false,
                updateDomainErrorMessage: payload,
            };
        }
        case EUserTypes.RESET_UPDATE_DOMAIN_ERROR: {
            return {
                ...state,
                updateDomainErrorMessage: undefined,
            };
        }

        default:
            return state;
    }
};

export default systemReducer;
