import { Button, withStyles } from '@material-ui/core';

export default withStyles((theme) => ({
    contained: {
        backgroundColor: theme.palette.default.main,
        '&:hover': {
            backgroundColor: theme.palette.default.light,
        },
        '&:active': {
            backgroundColor: theme.palette.default.dark,
        },
    },
    outlined: {
        borderColor: theme.palette.default.main,
        '&:hover': {
            borderColor: theme.palette.default.light,
        },
        '&:active': {
            borderColor: theme.palette.default.dark,
        },
    },
    containedPrimary: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
        '&:active': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    outlinedPrimary: {
        borderColor: theme.palette.primary.main,
        '&:hover': {
            borderColor: theme.palette.primary.light,
        },
        '&:active': {
            borderColor: theme.palette.primary.dark,
        },
    },
    containedSecondary: {
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
        '&:active': {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
    outlinedSecondary: {
        borderColor: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.secondary.light,
        },
        '&:active': {
            borderColor: theme.palette.secondary.dark,
        },
    },
}))(Button);
