import config from 'config/common';

export const saveLastSelectedSite = (site_name: string | undefined) => {
    if (!site_name) return;
    localStorage.setItem(config.lastSelectedSiteKey, site_name);
};

export const getLastSelectedSiteFromLocalStorage = () => {
    try {
        const fromStorage = localStorage.getItem(config.lastSelectedSiteKey) || '';
        return fromStorage;
    } catch (error) {
        return '';
    }
};

export const deleteLastSelectedSite = () => {
    localStorage.removeItem(config.lastSelectedSiteKey);
};
