/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PropGoal } from './prop-goal';

/**
 *
 * @export
 * @interface PropDB
 */
export class PropDB {
    /**
     *
     * @type {Array<PropGoal>}
     * @memberof PropDB
     */
    PROP_OPTIONS?: Array<PropGoal>;
    /**
     *
     * @type {string}
     * @memberof PropDB
     */
    PROP_URL?: string;
    /**
     *
     * @type {string}
     * @memberof PropDB
     */
    PROP_IFRAME_URL?: string;
    /**
     *
     * @type {boolean}
     * @memberof PropDB
     */
    PROP_WEEKEND_TRADING_DEFAULT?: boolean;
}
