/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface PropAddonItem
 */
export class PropAddonItem {
    /**
     *
     * @type {string}
     * @memberof PropAddonItem
     */
    _id?: string;
    /**
     *
     * @type {number}
     * @memberof PropAddonItem
     */
    chargeAmount: number;
    /**
     *
     * @type {object}
     * @memberof PropAddonItem
     */
    featureValue?: object;
    /**
     *
     * @type {boolean}
     * @memberof PropAddonItem
     */
    active: boolean;
    /**
     *
     * @type {string}
     * @memberof PropAddonItem
     */
    symbol: PropAddonItemSymbolEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PropAddonItemSymbolEnum {
    Usd = 'USD',
    Percent = 'PERCENT',
}
