import React from 'react';
import { makeStyles } from '@material-ui/core';
import logoLightMode from 'assets/images/logo_light_mode.png';
import logoDarkMode from 'assets/images/logo_dark_mode.png';
import { Link } from 'react-router-dom';

export interface LogoProps {}

const useStyles = makeStyles((theme) => ({
    logo_img: {
        display: 'block',
        backgroundImage: `url(${theme.palette.type === 'dark' ? logoDarkMode : logoLightMode})`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '3.6rem',
        width: '120px',
        cursor: 'pointer',
    },
}));

const Logo: React.FunctionComponent<LogoProps> = () => {
    const classes = useStyles();
    return (
        <Link to="/" replace>
            <span className={classes.logo_img} />
        </Link>
    );
};

export default Logo;
