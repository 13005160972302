import { createSelector } from 'reselect';
import { RootState } from '../index';

const systemReducerSelector = (state: RootState) => state.system;

export const system_loader = createSelector(systemReducerSelector, (system) => system.loader);

export const system_theme = createSelector(systemReducerSelector, (system) => system.theme);

export const system_side_menu_state = createSelector(systemReducerSelector, (system) => system.sideMenuState);

export const system_snackbarState = createSelector(systemReducerSelector, (system) => system.snackbarIsOpen);

export const system_snackbarObject = createSelector(systemReducerSelector, (system) => system.snackbarObject);

export const getFiltersSelector = createSelector(systemReducerSelector, (system) => system.filters);

export const getFilterStateSelector = createSelector(systemReducerSelector, (system) => system.filterState);

export const getScrollToTableState = createSelector(systemReducerSelector, (system) => system.scrollToTable);
