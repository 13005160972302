import { getTokenFromLocalStorage } from 'helpers/token';
import { ESystemTypes } from 'store/system/actionsTypes';
import { EAuthTypes } from './actionsTypes';
import { AuthReducerState } from './interfaces';

const initialState: AuthReducerState = {
    loader: false,
    hasJwtToken: !!getTokenFromLocalStorage(),
};

const loginReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case EAuthTypes.LOGIN_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case EAuthTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                loader: false,
                hasJwtToken: true,
            };
        }
        case EAuthTypes.LOGIN_FAILED: {
            return {
                ...state,
                loader: false,
            };
        }
        case EAuthTypes.START_LOADER:
            return {
                ...state,
                loader: true,
            };
        case EAuthTypes.STOP_LOADER:
            return {
                ...state,
                loader: false,
            };
        case ESystemTypes.RESET_APP: {
            return {
                ...state,
                loader: false,
                hasJwtToken: false,
            };
        }
        default:
            return state;
    }
};

export default loginReducer;
