import { Hidden } from '@material-ui/core';
import Button from 'components/Button';
import Icon, { IconsType } from 'components/Icon';
import { getGoBackPath } from 'interfaces/main';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useStyles from './back-or-menu-btn.styles';

export interface BackOrMenuBtnProps {
    toggleSideMenu: () => void;
}

const BackOrMenuBtn: React.FunctionComponent<BackOrMenuBtnProps> = (props) => {
    const { toggleSideMenu } = props;
    const [backTo, setBackTo] = React.useState<string>();
    const classes = useStyles();
    const location = useLocation();

    React.useEffect(() => {
        setBackTo(getGoBackPath(location.pathname) as string);
    }, [location.pathname]);

    const MenuButton = () => (
        <Button variant="text" onClick={toggleSideMenu}>
            <Icon name={IconsType.menu} />
        </Button>
    );

    const BackButton = () => {
        if (!backTo) return <MenuButton />;
        return (
            <Link to={backTo}>
                <Button variant="text">
                    <Icon name={IconsType.arrow_back} fontSize="large" className={classes.backBtn} />
                </Button>
            </Link>
        );
    };

    return (
        <React.Fragment>
            <Hidden mdDown>
                <MenuButton />
            </Hidden>
            <Hidden lgUp>
                <BackButton />
            </Hidden>
        </React.Fragment>
    );
};

export default BackOrMenuBtn;
