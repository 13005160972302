import { setThemeToLocalStorage } from 'helpers/local_storage';
import { ThemeOptions } from 'interfaces/main';
import { RootState } from '..';
import { ESystemTypes } from './actionsTypes';
import { system_theme } from './selectors';
import { history } from 'utils/history';
import { SnackbarProps } from 'components/Snackbar';
import { IFilterModel, IHeadCell } from 'components/EnhancedTable/interfaces';

export const system_setSelectedPage = (newPage: string, replace: boolean = false): any => {
    return () => (replace ? history.replace(newPage) : history.push(newPage));
};

export const system_startLoader = () => ({
    type: ESystemTypes.START_LOADER,
});

export const system_stopLoader = () => ({
    type: ESystemTypes.STOP_LOADER,
});

export const system_resetApp = () => {
    return (dispatch) => {
        dispatch(resetApp());
    };
};

const resetApp = () => ({
    type: ESystemTypes.RESET_APP,
});

export const system_toggleTheme = () => {
    return (dispatch, getState) => {
        const state: RootState = getState();
        const currentTheme = system_theme(state);
        const newTheme = currentTheme === ThemeOptions.DARK ? ThemeOptions.LIGHT : ThemeOptions.DARK;
        setThemeToLocalStorage(newTheme);
        dispatch({
            type: ESystemTypes.TOGGLE_THEME,
            payload: newTheme,
        });
    };
};

export const system_toggleSideMenu = () => ({
    type: ESystemTypes.TOGGLE_SIDE_MENU,
});

export const system_openSnackbar = (snackbarObject: SnackbarProps) => {
    return async (dispatch) => {
        setTimeout(() => {
            dispatch({
                type: ESystemTypes.OPEN_SNACKBAR,
                payload: snackbarObject,
            });
        }, 100);
    };
};

export const system_closeSnackbar = () => ({
    type: ESystemTypes.CLOSE_SNACKBAR,
});

export const updateFilters = (filters: IFilterModel, dontSaveToLocal = false) => {
    return async (dispatch) => {
        dispatch({ type: ESystemTypes.UPDATE_FILTERS, payload: filters });
    };
};

export const getFiltersFromLocalStorage = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: ESystemTypes.UPDATE_FILTERS, payload: {} });
        } catch (error) {
            dispatch({ type: ESystemTypes.UPDATE_FILTERS, payload: {} });
        }
    };
};

export const scrollToTable = (newState) => ({
    type: ESystemTypes.SCROLL_TO_TABLE,
    payload: newState,
});

export const system_genericPromptWithSnackbar = (
    message: { error: string; success: string },
    duration: number = 1000
) => {
    return async (dispatch, getState) => {
        const state: RootState = getState();
        const nameSpaceValidation = prompt(`Enter name space: ${state.user.selectedSite}`);

        if (!nameSpaceValidation || nameSpaceValidation !== state.user.selectedSite) {
            dispatch(
                system_openSnackbar({
                    message: message.error,
                    onClose: system_closeSnackbar,
                    severty: 'warning',
                    duration,
                })
            );
            return false;
        }
        dispatch(
            system_openSnackbar({
                message: message.success,
                onClose: system_closeSnackbar,
                severty: 'success',
                duration,
            })
        );
        return true;
    };
};
