import { createStyles } from '@material-ui/core';
import palette from 'muiTheme/config/palette';

export default createStyles({
    switchHeader: {
        margin: '8px',
        padding: '8px',
        borderRadius: '8px',
        backgroundColor: palette.dark.default.main,
        color: palette.dark.default.contrastText,
    },
    iconButton: {
        padding: 0,
        backgroundColor: 'transparent',
        color: palette.dark.default.contrastText,
    },
    transitionContainer: {
        height: '1.6rem',
    },
});
