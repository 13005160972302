export enum ESystemTypes {
    START_LOADER = '[system] start loader',
    STOP_LOADER = '[system] stop loader',
    TOGGLE_THEME = '[system] toggle theme',
    TOGGLE_SIDE_MENU = '[system] toogle side menu',
    RESET_APP = '[system] reset app',
    OPEN_SNACKBAR = '[system] open message snackbar',
    CLOSE_SNACKBAR = '[system] close message snackbar',
    DOWNLOAD_CSV = '[system] download csv',
    TOGGLE_FILTER_STATE = '[system] toggle filter state',
    UPDATE_FILTERS = '[system] update filters',
    SCROLL_TO_TABLE = '[system] scroll to table',
}
