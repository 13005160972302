export default {
    light: {
        common: { black: '#000', white: '#fff' },
        background: { error: '#fad9dc', paper: '#fff', default: '#fff', action: '#F4F5F7' },
        side_menu: { background: '#ffffff', text: '#717284' },
        default: { main: '#ecf0f9', light: '#faf7fb', dark: '#ccd8e5', contrastText: '#000000de' },
        primary: { main: '#f16b27', contrastText: '#fff' },
        secondary: { main: '#7fd7a9', contrastText: '#fff' },
        success: { main: '#7fd7a9', contrastText: '#fff' },
        error: { main: '#ff5757', contrastText: '#fff' },
        props: { default: '#ecf0f9', red: 'red' },
        text: {
            default: '#11142d',
            lightDefault: '#717284',
            primary: '#11142d',
            secondary: '#6585a4',
            action: '#2a52d3',
            disabled: '#000000',
            hint: '#00000061',
        },
        tooltip: { background: '#1b1d21', text: '#ffffff' },
        disabled: { background: '#ecf0f9', backgroundDark: '#6585a4', border: '#d1dce8', contrastText: '#fff' },
    },
    dark: {
        common: { black: '#000', white: '#fff' },
        background: { error: '#fad9dc', paper: '#1f2128', default: '#1f2128', action: '#2F323C' },
        side_menu: { background: '#1f2128', text: '#a5a5a5' },
        default: { main: '#313a48', contrastText: '#fff' },
        primary: { main: '#f16b27', contrastText: '#fff' },
        secondary: { main: '#7fd7a9', contrastText: '#fff' },
        success: { main: '#7fd7a9', contrastText: '#fff' },
        error: { main: '#ff5757', contrastText: '#fff' },
        props: { default: '#ecf0f9', red: 'red' },
        text: {
            default: '#ffffff',
            lightDefault: '#ffffff',
            primary: '#ffffff',
            secondary: '#a7a8b1',
            action: '#0065ff',
            disabled: '#ffffff',
            hint: '#ffffff61',
            textPrimary: '#f16b27',
        },
        tooltip: { background: '#ffffff', text: '#11142d' },
        disabled: { background: '#242731', backgroundDark: '#6585a4', border: '#35363e', contrastText: '#fff' },
    },
};
