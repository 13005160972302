import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    select_wrapper: {
        '& .MuiInputBase-root': {
            marginTop: 'unset',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '20px',
            width: 'auto',
            minHeight: 'unset',
        },
        '& .MuiOutlinedInput-input': {
            padding: '9px 32px 9px 16px',
            color: theme.palette.primary.main,
        },
        '& .MuiSelect-iconOutlined': {
            color: theme.palette.primary.main,
        },
    },
}));
