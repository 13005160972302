export enum EOptionTypes {
    GET_PAYMENT_IPS_START = '[option] get payment ips start',
    GET_PAYMENT_IPS_SUCCESS = '[option] get payment ips success',
    GET_PAYMENT_IPS_FAILED = '[option] get payment ips failed',

    CREATE_PAYMENT_IPS_START = '[option] create payment ips start',
    CREATE_PAYMENT_IPS_SUCCESS = '[option] create payment ips success',
    CREATE_PAYMENT_IPS_FAILED = '[option] create payment ips failed',

    DELETE_PAYMENT_IPS_START = '[option] delete payment ips start',
    DELETE_PAYMENT_IPS_SUCCESS = '[option] delete payment ips success',
    DELETE_PAYMENT_IPS_FAILED = '[option] delete payment ips failed',

    DEPLOY_PAYMENT_IPS_START = '[option] deploy payment ips start',
    DEPLOY_PAYMENT_IPS_SUCCESS = '[option] deploy payment ips success',
    DEPLOY_PAYMENT_IPS_FAILED = '[option] deploy payment ips failed',

    GET_LANGUAGES_START = '[option] get languages start',
    GET_LANGUAGES_SUCCESS = '[option] get languages success',
    GET_LANGUAGES_FAILED = '[option] get languages failed',

    CREATE_LANGUAGES_START = '[option] create languages start',
    CREATE_LANGUAGES_SUCCESS = '[option] create languages success',
    CREATE_LANGUAGES_FAILED = '[option] create languages failed',

    DELETE_LANGUAGES_START = '[option] delete languages start',
    DELETE_LANGUAGES_SUCCESS = '[option] delete languages success',
    DELETE_LANGUAGES_FAILED = '[option] delete languages failed',

    EDIT_LANGUAGES_START = '[option] edit languages start',
    EDIT_LANGUAGES_SUCCESS = '[option] edit languages success',
    EDIT_LANGUAGES_FAILED = '[option] edit languages failed',
}
