/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreatePropChallengeDto } from '../models';
// @ts-ignore
import { CreatePropGoalDto } from '../models';
// @ts-ignore
import { CreatePropPackageDto } from '../models';
// @ts-ignore
import { PropDB } from '../models';
// @ts-ignore
import { UpdateGoalTree } from '../models';
// @ts-ignore
import { UpdatePropChallengeDto } from '../models';
// @ts-ignore
import { UpdatePropEnvDto } from '../models';
// @ts-ignore
import { UpdatePropGoalDto } from '../models';
// @ts-ignore
import { UpdatePropPackageDto } from '../models';
/**
 * PropApi - axios parameter creator
 * @export
 */
export const PropApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CreatePropChallengeDto} createPropChallengeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerAddChallenge: async (
            createPropChallengeDto: CreatePropChallengeDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'createPropChallengeDto' is not null or undefined
            assertParamExists('propApiControllerAddChallenge', 'createPropChallengeDto', createPropChallengeDto);
            const localVarPath = `/api/prop/addChallenge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createPropChallengeDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreatePropGoalDto} createPropGoalDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerAddGoal: async (
            createPropGoalDto: CreatePropGoalDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'createPropGoalDto' is not null or undefined
            assertParamExists('propApiControllerAddGoal', 'createPropGoalDto', createPropGoalDto);
            const localVarPath = `/api/prop/addGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createPropGoalDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdateGoalTree} updateGoalTree
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerAddGoalTree: async (
            updateGoalTree: UpdateGoalTree,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'updateGoalTree' is not null or undefined
            assertParamExists('propApiControllerAddGoalTree', 'updateGoalTree', updateGoalTree);
            const localVarPath = `/api/prop/addGoalTree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(updateGoalTree, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreatePropPackageDto} createPropPackageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerAddPackage: async (
            createPropPackageDto: CreatePropPackageDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'createPropPackageDto' is not null or undefined
            assertParamExists('propApiControllerAddPackage', 'createPropPackageDto', createPropPackageDto);
            const localVarPath = `/api/prop/addPackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createPropPackageDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} namespace
         * @param {string} goalId
         * @param {string} challengeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerDeleteChallenge: async (
            namespace: string,
            goalId: string,
            challengeId: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('propApiControllerDeleteChallenge', 'namespace', namespace);
            // verify required parameter 'goalId' is not null or undefined
            assertParamExists('propApiControllerDeleteChallenge', 'goalId', goalId);
            // verify required parameter 'challengeId' is not null or undefined
            assertParamExists('propApiControllerDeleteChallenge', 'challengeId', challengeId);
            const localVarPath = `/api/prop/deleteChallenge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (namespace !== undefined) {
                localVarQueryParameter['namespace'] = namespace;
            }

            if (goalId !== undefined) {
                localVarQueryParameter['goalId'] = goalId;
            }

            if (challengeId !== undefined) {
                localVarQueryParameter['challengeId'] = challengeId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} namespace
         * @param {string} goalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerDeleteGoal: async (
            namespace: string,
            goalId: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('propApiControllerDeleteGoal', 'namespace', namespace);
            // verify required parameter 'goalId' is not null or undefined
            assertParamExists('propApiControllerDeleteGoal', 'goalId', goalId);
            const localVarPath = `/api/prop/deleteGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (namespace !== undefined) {
                localVarQueryParameter['namespace'] = namespace;
            }

            if (goalId !== undefined) {
                localVarQueryParameter['goalId'] = goalId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} namespace
         * @param {string} goalId
         * @param {string} challengeId
         * @param {string} packageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerDeletePackage: async (
            namespace: string,
            goalId: string,
            challengeId: string,
            packageId: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('propApiControllerDeletePackage', 'namespace', namespace);
            // verify required parameter 'goalId' is not null or undefined
            assertParamExists('propApiControllerDeletePackage', 'goalId', goalId);
            // verify required parameter 'challengeId' is not null or undefined
            assertParamExists('propApiControllerDeletePackage', 'challengeId', challengeId);
            // verify required parameter 'packageId' is not null or undefined
            assertParamExists('propApiControllerDeletePackage', 'packageId', packageId);
            const localVarPath = `/api/prop/deletePackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (namespace !== undefined) {
                localVarQueryParameter['namespace'] = namespace;
            }

            if (goalId !== undefined) {
                localVarQueryParameter['goalId'] = goalId;
            }

            if (challengeId !== undefined) {
                localVarQueryParameter['challengeId'] = challengeId;
            }

            if (packageId !== undefined) {
                localVarQueryParameter['packageId'] = packageId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} namespace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerGet: async (namespace: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('propApiControllerGet', 'namespace', namespace);
            const localVarPath = `/api/prop/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (namespace !== undefined) {
                localVarQueryParameter['namespace'] = namespace;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} namespace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerSyncPropOptions: async (namespace: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('propApiControllerSyncPropOptions', 'namespace', namespace);
            const localVarPath = `/api/prop/syncPropOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (namespace !== undefined) {
                localVarQueryParameter['namespace'] = namespace;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdatePropChallengeDto} updatePropChallengeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerUpdateChallenge: async (
            updatePropChallengeDto: UpdatePropChallengeDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'updatePropChallengeDto' is not null or undefined
            assertParamExists('propApiControllerUpdateChallenge', 'updatePropChallengeDto', updatePropChallengeDto);
            const localVarPath = `/api/prop/updateChallenge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updatePropChallengeDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdatePropGoalDto} updatePropGoalDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerUpdateGoal: async (
            updatePropGoalDto: UpdatePropGoalDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'updatePropGoalDto' is not null or undefined
            assertParamExists('propApiControllerUpdateGoal', 'updatePropGoalDto', updatePropGoalDto);
            const localVarPath = `/api/prop/updateGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updatePropGoalDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdateGoalTree} updateGoalTree
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerUpdateGoalTree: async (
            updateGoalTree: UpdateGoalTree,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'updateGoalTree' is not null or undefined
            assertParamExists('propApiControllerUpdateGoalTree', 'updateGoalTree', updateGoalTree);
            const localVarPath = `/api/prop/updateGoalTree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(updateGoalTree, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdatePropPackageDto} updatePropPackageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerUpdatePackage: async (
            updatePropPackageDto: UpdatePropPackageDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'updatePropPackageDto' is not null or undefined
            assertParamExists('propApiControllerUpdatePackage', 'updatePropPackageDto', updatePropPackageDto);
            const localVarPath = `/api/prop/updatePackage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updatePropPackageDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdatePropEnvDto} updatePropEnvDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerUpdatePropEnv: async (
            updatePropEnvDto: UpdatePropEnvDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'updatePropEnvDto' is not null or undefined
            assertParamExists('propApiControllerUpdatePropEnv', 'updatePropEnvDto', updatePropEnvDto);
            const localVarPath = `/api/prop/updatePropEnv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updatePropEnvDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * PropApi - functional programming interface
 * @export
 */
export const PropApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = PropApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {CreatePropChallengeDto} createPropChallengeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerAddChallenge(
            createPropChallengeDto: CreatePropChallengeDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerAddChallenge(
                createPropChallengeDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {CreatePropGoalDto} createPropGoalDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerAddGoal(
            createPropGoalDto: CreatePropGoalDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerAddGoal(
                createPropGoalDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UpdateGoalTree} updateGoalTree
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerAddGoalTree(
            updateGoalTree: UpdateGoalTree,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerAddGoalTree(
                updateGoalTree,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {CreatePropPackageDto} createPropPackageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerAddPackage(
            createPropPackageDto: CreatePropPackageDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerAddPackage(
                createPropPackageDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} namespace
         * @param {string} goalId
         * @param {string} challengeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerDeleteChallenge(
            namespace: string,
            goalId: string,
            challengeId: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerDeleteChallenge(
                namespace,
                goalId,
                challengeId,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} namespace
         * @param {string} goalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerDeleteGoal(
            namespace: string,
            goalId: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerDeleteGoal(
                namespace,
                goalId,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} namespace
         * @param {string} goalId
         * @param {string} challengeId
         * @param {string} packageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerDeletePackage(
            namespace: string,
            goalId: string,
            challengeId: string,
            packageId: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerDeletePackage(
                namespace,
                goalId,
                challengeId,
                packageId,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} namespace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerGet(
            namespace: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerGet(namespace, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} namespace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerSyncPropOptions(
            namespace: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerSyncPropOptions(
                namespace,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UpdatePropChallengeDto} updatePropChallengeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerUpdateChallenge(
            updatePropChallengeDto: UpdatePropChallengeDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerUpdateChallenge(
                updatePropChallengeDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UpdatePropGoalDto} updatePropGoalDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerUpdateGoal(
            updatePropGoalDto: UpdatePropGoalDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerUpdateGoal(
                updatePropGoalDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UpdateGoalTree} updateGoalTree
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerUpdateGoalTree(
            updateGoalTree: UpdateGoalTree,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerUpdateGoalTree(
                updateGoalTree,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UpdatePropPackageDto} updatePropPackageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerUpdatePackage(
            updatePropPackageDto: UpdatePropPackageDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerUpdatePackage(
                updatePropPackageDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UpdatePropEnvDto} updatePropEnvDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propApiControllerUpdatePropEnv(
            updatePropEnvDto: UpdatePropEnvDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropDB>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propApiControllerUpdatePropEnv(
                updatePropEnvDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * PropApi - factory interface
 * @export
 */
export const PropApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropApiFp(configuration);
    return {
        /**
         *
         * @param {CreatePropChallengeDto} createPropChallengeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerAddChallenge(
            createPropChallengeDto: CreatePropChallengeDto,
            options?: any
        ): AxiosPromise<PropDB> {
            return localVarFp
                .propApiControllerAddChallenge(createPropChallengeDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CreatePropGoalDto} createPropGoalDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerAddGoal(createPropGoalDto: CreatePropGoalDto, options?: any): AxiosPromise<PropDB> {
            return localVarFp
                .propApiControllerAddGoal(createPropGoalDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdateGoalTree} updateGoalTree
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerAddGoalTree(updateGoalTree: UpdateGoalTree, options?: any): AxiosPromise<PropDB> {
            return localVarFp
                .propApiControllerAddGoalTree(updateGoalTree, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CreatePropPackageDto} createPropPackageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerAddPackage(createPropPackageDto: CreatePropPackageDto, options?: any): AxiosPromise<PropDB> {
            return localVarFp
                .propApiControllerAddPackage(createPropPackageDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} namespace
         * @param {string} goalId
         * @param {string} challengeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerDeleteChallenge(
            namespace: string,
            goalId: string,
            challengeId: string,
            options?: any
        ): AxiosPromise<PropDB> {
            return localVarFp
                .propApiControllerDeleteChallenge(namespace, goalId, challengeId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} namespace
         * @param {string} goalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerDeleteGoal(namespace: string, goalId: string, options?: any): AxiosPromise<PropDB> {
            return localVarFp
                .propApiControllerDeleteGoal(namespace, goalId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} namespace
         * @param {string} goalId
         * @param {string} challengeId
         * @param {string} packageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerDeletePackage(
            namespace: string,
            goalId: string,
            challengeId: string,
            packageId: string,
            options?: any
        ): AxiosPromise<PropDB> {
            return localVarFp
                .propApiControllerDeletePackage(namespace, goalId, challengeId, packageId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} namespace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerGet(namespace: string, options?: any): AxiosPromise<PropDB> {
            return localVarFp.propApiControllerGet(namespace, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} namespace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerSyncPropOptions(namespace: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .propApiControllerSyncPropOptions(namespace, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdatePropChallengeDto} updatePropChallengeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerUpdateChallenge(
            updatePropChallengeDto: UpdatePropChallengeDto,
            options?: any
        ): AxiosPromise<PropDB> {
            return localVarFp
                .propApiControllerUpdateChallenge(updatePropChallengeDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdatePropGoalDto} updatePropGoalDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerUpdateGoal(updatePropGoalDto: UpdatePropGoalDto, options?: any): AxiosPromise<PropDB> {
            return localVarFp
                .propApiControllerUpdateGoal(updatePropGoalDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdateGoalTree} updateGoalTree
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerUpdateGoalTree(updateGoalTree: UpdateGoalTree, options?: any): AxiosPromise<PropDB> {
            return localVarFp
                .propApiControllerUpdateGoalTree(updateGoalTree, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdatePropPackageDto} updatePropPackageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerUpdatePackage(
            updatePropPackageDto: UpdatePropPackageDto,
            options?: any
        ): AxiosPromise<PropDB> {
            return localVarFp
                .propApiControllerUpdatePackage(updatePropPackageDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdatePropEnvDto} updatePropEnvDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propApiControllerUpdatePropEnv(updatePropEnvDto: UpdatePropEnvDto, options?: any): AxiosPromise<PropDB> {
            return localVarFp
                .propApiControllerUpdatePropEnv(updatePropEnvDto, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropApi - object-oriented interface
 * @export
 * @class PropApi
 * @extends {BaseAPI}
 */
export class PropApi extends BaseAPI {
    /**
     *
     * @param {CreatePropChallengeDto} createPropChallengeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerAddChallenge(createPropChallengeDto: CreatePropChallengeDto, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerAddChallenge(createPropChallengeDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {CreatePropGoalDto} createPropGoalDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerAddGoal(createPropGoalDto: CreatePropGoalDto, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerAddGoal(createPropGoalDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UpdateGoalTree} updateGoalTree
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerAddGoalTree(updateGoalTree: UpdateGoalTree, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerAddGoalTree(updateGoalTree, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {CreatePropPackageDto} createPropPackageDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerAddPackage(createPropPackageDto: CreatePropPackageDto, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerAddPackage(createPropPackageDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} namespace
     * @param {string} goalId
     * @param {string} challengeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerDeleteChallenge(namespace: string, goalId: string, challengeId: string, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerDeleteChallenge(namespace, goalId, challengeId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} namespace
     * @param {string} goalId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerDeleteGoal(namespace: string, goalId: string, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerDeleteGoal(namespace, goalId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} namespace
     * @param {string} goalId
     * @param {string} challengeId
     * @param {string} packageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerDeletePackage(
        namespace: string,
        goalId: string,
        challengeId: string,
        packageId: string,
        options?: any
    ) {
        return PropApiFp(this.configuration)
            .propApiControllerDeletePackage(namespace, goalId, challengeId, packageId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} namespace
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerGet(namespace: string, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerGet(namespace, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} namespace
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerSyncPropOptions(namespace: string, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerSyncPropOptions(namespace, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UpdatePropChallengeDto} updatePropChallengeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerUpdateChallenge(updatePropChallengeDto: UpdatePropChallengeDto, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerUpdateChallenge(updatePropChallengeDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UpdatePropGoalDto} updatePropGoalDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerUpdateGoal(updatePropGoalDto: UpdatePropGoalDto, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerUpdateGoal(updatePropGoalDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UpdateGoalTree} updateGoalTree
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerUpdateGoalTree(updateGoalTree: UpdateGoalTree, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerUpdateGoalTree(updateGoalTree, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UpdatePropPackageDto} updatePropPackageDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerUpdatePackage(updatePropPackageDto: UpdatePropPackageDto, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerUpdatePackage(updatePropPackageDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UpdatePropEnvDto} updatePropEnvDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropApi
     */
    public propApiControllerUpdatePropEnv(updatePropEnvDto: UpdatePropEnvDto, options?: any) {
        return PropApiFp(this.configuration)
            .propApiControllerUpdatePropEnv(updatePropEnvDto, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
