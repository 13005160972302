import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    iconBtn: {
        height: '3.4rem',
        width: '3.4rem',
        borderRadius: '50%',
        background: 'transparent',
        color: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    iconBtn__active: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    toolTipPopper: {
        pointerEvents: 'initial',
    },
    toolTipPaper: {
        borderRadius: '8px',
        padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
        backgroundColor: theme.palette.background.paper,
        boxShadow:
            '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',

        display: 'flex',
        flexDirection: 'column',
        gap: `${theme.spacing(3)}px`,
    },
    toolTipArrow: {
        color: theme.palette.background.paper,
        height: '1rem',
        width: '1.5rem',
    },
    toolTipTop: {
        '&& .MuiTooltip-arrow': {
            marginTop: '-1rem',
        },
    },
    btn: {
        color: theme.palette.text.primary,
        padding: '8px',
        width: '100%',
        alignItems: 'center',
        lineHeight: 1,
        justifyContent: 'flex-start',
        '&:hover,&:focus': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
}));
