/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { FieldAndValue } from './field-and-value';

/**
 *
 * @export
 * @interface WebHookEntity
 */
export class WebHookEntity {
    /**
     *
     * @type {boolean}
     * @memberof WebHookEntity
     */
    active: boolean;
    /**
     *
     * @type {string}
     * @memberof WebHookEntity
     */
    url: string;
    /**
     *
     * @type {string}
     * @memberof WebHookEntity
     */
    method: WebHookEntityMethodEnum;
    /**
     *
     * @type {Array<FieldAndValue>}
     * @memberof WebHookEntity
     */
    params: Array<FieldAndValue>;
    /**
     *
     * @type {Array<FieldAndValue>}
     * @memberof WebHookEntity
     */
    headers: Array<FieldAndValue>;
}

/**
 * @export
 * @enum {string}
 */
export enum WebHookEntityMethodEnum {
    Post = 'POST',
    Get = 'GET',
}
