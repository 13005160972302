/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PropChallengeConditions } from './prop-challenge-conditions';

/**
 *
 * @export
 * @interface UpdatePropChallengeDto
 */
export class UpdatePropChallengeDto {
    /**
     *
     * @type {string}
     * @memberof UpdatePropChallengeDto
     */
    namespace: string;
    /**
     *
     * @type {string}
     * @memberof UpdatePropChallengeDto
     */
    goalId: string;
    /**
     *
     * @type {string}
     * @memberof UpdatePropChallengeDto
     */
    entityId: string;
    /**
     *
     * @type {string}
     * @memberof UpdatePropChallengeDto
     */
    entityName: string;
    /**
     *
     * @type {string}
     * @memberof UpdatePropChallengeDto
     */
    promoCode?: string;
    /**
     *
     * @type {number}
     * @memberof UpdatePropChallengeDto
     */
    retryDiscountPercent?: number;
    /**
     *
     * @type {PropChallengeConditions}
     * @memberof UpdatePropChallengeDto
     */
    conditions: PropChallengeConditions;
    /**
     *
     * @type {string}
     * @memberof UpdatePropChallengeDto
     */
    type: UpdatePropChallengeDtoTypeEnum;
    /**
     *
     * @type {string}
     * @memberof UpdatePropChallengeDto
     */
    iconName?: string;
    /**
     *
     * @type {boolean}
     * @memberof UpdatePropChallengeDto
     */
    autoApproved?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UpdatePropChallengeDto
     */
    acceptTermsAndCondition?: boolean;
    /**
     *
     * @type {string}
     * @memberof UpdatePropChallengeDto
     */
    challengeId: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdatePropChallengeDtoTypeEnum {
    Audition = 'audition',
    Funded = 'funded',
    Demo = 'demo',
}
