/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UserInfoRequest
 */
export class UserInfoRequest {
    /**
     *
     * @type {string}
     * @memberof UserInfoRequest
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof UserInfoRequest
     */
    firstName: string;
    /**
     *
     * @type {string}
     * @memberof UserInfoRequest
     */
    lastName: string;
    /**
     *
     * @type {string}
     * @memberof UserInfoRequest
     */
    role: UserInfoRequestRoleEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof UserInfoRequest
     */
    namespaces?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof UserInfoRequest
     */
    ips: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum UserInfoRequestRoleEnum {
    Cicd = 'cicd',
    Admin = 'admin',
    Translator = 'translator',
    Readonly = 'readonly',
    SuccessManagerAgent = 'success_manager_agent',
    SuccessManagerAdmin = 'success_manager_admin',
    Agent = 'agent',
    CryptoAdmin = 'crypto_admin',
}
