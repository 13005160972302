import { ThemeOptions } from '@material-ui/core';
import { auth_logout } from 'store/auth/actions';
import { system_toggleTheme } from 'store/system/actions';
import { system_theme } from 'store/system/selectors';

export interface FloatingMenuProps {
    theme: ThemeOptions;
    logOut: () => void;
    toggleTheme: () => void;
}

export const mapStateToProps = (state) => ({
    theme: system_theme(state),
});

export const mapDispatchToProps = {
    logOut: auth_logout,
    toggleTheme: system_toggleTheme,
};
