/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DepositLinkConfig } from './deposit-link-config';
import { WithdrawLinkConfig } from './withdraw-link-config';

/**
 *
 * @export
 * @interface PlatformServerApiDBI
 */
export class PlatformServerApiDBI {
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof PlatformServerApiDBI
     */
    WT_FEATURES_FLAGS: { [key: string]: boolean };
    /**
     *
     * @type {string}
     * @memberof PlatformServerApiDBI
     */
    WEBTRADER_SECRET?: string;
    /**
     *
     * @type {string}
     * @memberof PlatformServerApiDBI
     */
    MIX_PANEL_KEY?: string;
    /**
     *
     * @type {DepositLinkConfig}
     * @memberof PlatformServerApiDBI
     */
    DEPOSIT_LINK_CONFIG?: DepositLinkConfig;
    /**
     *
     * @type {WithdrawLinkConfig}
     * @memberof PlatformServerApiDBI
     */
    WITHDRAW_LINK_CONFIG?: WithdrawLinkConfig;
}
