import React from 'react';
import {
    Book,
    AcUnit,
    AccessAlarm,
    Brightness4,
    Brightness5,
    EmailOutlined,
    PersonOutlined,
    VisibilityOutlined,
    VisibilityOffOutlined,
    PhoneOutlined,
    GpsFixedOutlined,
    KeyboardArrowDown,
    Clear,
    ExitToAppOutlined,
    DateRange,
    RadioButtonUnchecked,
    RadioButtonChecked,
    ArrowBack,
    TranslateRounded,
    BackupOutlined,
    SettingsOutlined,
    VisibilityOff,
    FileCopy,
    QueryBuilder,
} from '@material-ui/icons';
import { IconProps as IconPropsMat, SvgIcon } from '@material-ui/core';

import { ReactComponent as GoogleIcon } from 'assets/icons/google_icon.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook_icon.svg';
import { ReactComponent as LogoLabelIcon } from 'assets/icons/logo_label.svg';

import { ReactComponent as AccountSettings } from 'assets/icons/account_settings.svg';
import { ReactComponent as ClientSupport } from 'assets/icons/client_support.svg';
import { ReactComponent as Dashboard } from 'assets/icons/dashboard.svg';
import { ReactComponent as MyProfile } from 'assets/icons/my_profile.svg';
import { ReactComponent as MyWallet } from 'assets/icons/my_wallet.svg';
import { ReactComponent as Platforms } from 'assets/icons/platforms.svg';
import { ReactComponent as TradingAccounts } from 'assets/icons/trading_accounts.svg';
import { ReactComponent as TradingAnalysis } from 'assets/icons/trading_analysis.svg';
import { ReactComponent as UploadDocuments } from 'assets/icons/upload_documents.svg';
import { ReactComponent as Notification } from 'assets/icons/notification.svg';
import { ReactComponent as Menu } from 'assets/icons/menu.svg';
import { ReactComponent as CreditCard } from 'assets/icons/credit-card.svg';
import { ReactComponent as Paypal } from 'assets/icons/paypal.svg';
import { ReactComponent as Transfer } from 'assets/icons/transfer.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as ExportCsv } from 'assets/icons/export-csv.svg';
import { ReactComponent as AddSort } from 'assets/icons/addSort.svg';
import { ReactComponent as AddSortFull } from 'assets/icons/addSortFull.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as Cancel } from 'assets/icons/cancel.svg';
import { ReactComponent as Sort } from 'assets/icons/sort.svg';
import { ReactComponent as SortDown } from 'assets/icons/sort-down.svg';
import { ReactComponent as CheckboxIntermidiate } from 'assets/icons/checkbox intermidiate.svg';
import { ReactComponent as Triangle } from 'assets/icons/triangle.svg';
import { ReactComponent as ElectronicPay } from 'assets/icons/electronic_pay.svg';
import { ReactComponent as Withdraw } from 'assets/icons/withdraw_icon.svg';
import { ReactComponent as Deposit } from 'assets/icons/deposit_icon.svg';
import { ReactComponent as Trade } from 'assets/icons/trade_icon.svg';
import { ReactComponent as SuggestionBuy } from 'assets/icons/suggestion_buy.svg';
import { ReactComponent as SuggestionSell } from 'assets/icons/suggestion_sell.svg';
import { ReactComponent as Star } from 'assets/icons/star_icon.svg';
import { ReactComponent as Success } from 'assets/icons/success_icon.svg';
import { ReactComponent as SearchChat } from 'assets/icons/search_chat.svg';
import { ReactComponent as Bitcoin } from 'assets/icons/bitcoin.svg';

const Icons = {
    book: Book,
    darkModeIcon: Brightness5,
    lightModeIcon: Brightness4,
    acUnit: AcUnit,
    accessAlarm: AccessAlarm,
    email: EmailOutlined,
    person: PersonOutlined,
    visible: VisibilityOutlined,
    un_visible: VisibilityOffOutlined,
    phone: PhoneOutlined,
    gps_fixed: GpsFixedOutlined,
    chevron_down: KeyboardArrowDown,
    logout: ExitToAppOutlined,
    date: DateRange,
    radio_unchecked: RadioButtonUnchecked,
    radio_checked: RadioButtonChecked,
    arrow_back: ArrowBack,
    translate: TranslateRounded,
    settings: SettingsOutlined,
    visibility_Off: VisibilityOff,
    fileCopy: FileCopy,
    close: (args) => <Clear {...args} fontSize="large" style={{ margin: '-1px' }} />,
    google: (args) => <SvgIcon component={GoogleIcon} {...args} viewBox="0 0 512 512" />,
    facebook: (args) => <SvgIcon component={FacebookIcon} {...args} viewBox="0 0 112.196 112.196" />,
    backup: (args) => <BackupOutlined {...args} style={{ fontSize: '6rem' }} />,
    logo_label: (args) => <LogoLabelIcon />,
    account_settings: (args) => <AccountSettings />,
    client_support: (args) => <ClientSupport />,
    dashboard: (args) => <Dashboard />,
    my_profile: (args) => <MyProfile />,
    my_wallet: (args) => <MyWallet />,
    platforms: (args) => <Platforms />,
    trading_accounts: (args) => <TradingAccounts />,
    trading_analysis: (args) => <TradingAnalysis />,
    upload_documents: (args) => <UploadDocuments />,
    notification: (args) => <Notification />,
    menu: (args) => <Menu />,
    transfer: (args) => <Transfer />,
    credit_card: (args) => <CreditCard />,
    paypal: (args) => <Paypal />,
    check: (args) => <Check />,
    add_sort: (args) => <AddSort />,
    add_sort_full: (args) => <AddSortFull />,
    export_csv: (args) => <ExportCsv />,
    search: (args) => <Search />,
    cancel: (args) => <Cancel />,
    sort: (args) => <Sort />,
    sort_down: (args) => <SortDown />,
    checkbox_intermidiate: (args) => <CheckboxIntermidiate />,
    triangle: (args) => <Triangle />,
    electronic_pay: (args) => <ElectronicPay />,
    withdraw: (args) => <Withdraw />,
    deposit: (args) => <Deposit />,
    trade: (args) => <Trade />,
    suggestion_buy: (args) => <SuggestionBuy />,
    suggestion_sell: (args) => <SuggestionSell />,
    star: (args) => <Star />,
    success: (args) => <Success />,
    custom: (args) => <span>{args?.children?.label}</span>,
    search_chat: (args) => <SearchChat />,
    bitcoin: (args) => <Bitcoin style={{ width: '30px', height: '30px' }} />,
    query_builder: (args) => <QueryBuilder />,
};

export enum IconsType {
    book = 'book',
    darkModeIcon = 'darkModeIcon',
    lightModeIcon = 'lightModeIcon',
    acUnit = 'acUnit',
    accessAlarm = 'accessAlarm',
    email = 'email',
    person = 'person',
    visible = 'visible',
    un_visible = 'un_visible',
    phone = 'phone',
    gps_fixed = 'gps_fixed',
    google = 'google',
    facebook = 'facebook',
    logo_label = 'logo_label',
    chevron_down = 'chevron_down',
    account_settings = 'account_settings',
    client_support = 'client_support',
    dashboard = 'dashboard',
    my_profile = 'my_profile',
    my_wallet = 'my_wallet',
    platforms = 'platforms',
    trading_accounts = 'trading_accounts',
    trading_analysis = 'trading_analysis',
    upload_documents = 'upload_documents',
    notification = 'notification',
    close = 'close',
    menu = 'menu',
    logout = 'logout',
    date = 'date',
    transfer = 'transfer',
    credit_card = 'credit_card',
    paypal = 'paypal',
    radio_unchecked = 'radio_unchecked',
    radio_checked = 'radio_checked',
    check = 'check',
    custom = 'custom',
    arrow_back = 'arrow_back',
    add_sort = 'add_sort',
    add_sort_full = 'add_sort_full',
    export_csv = 'export_csv',
    search = 'search',
    cancel = 'cancel',
    success = 'success',
    sort = 'sort',
    sort_down = 'sort_down',
    checkbox_intermidiate = 'checkbox_intermidiate',
    triangle = 'triangle',
    electronic_pay = 'electronic_pay',
    trade = 'trade',
    withdraw = 'withdraw',
    deposit = 'deposit',
    suggestion_buy = 'suggestion_buy',
    suggestion_sell = 'suggestion_sell',
    star = 'star',
    translate = 'translate',
    backup = 'backup',
    settings = 'settings',
    visibility_Off = 'visibility_Off',
    file_copy = 'fileCopy',
    search_chat = 'search_chat',
    bitcoin = 'bitcoin',
    query_builder = 'query_builder',
}

export interface IconProps extends IconPropsMat {
    name: IconsType;
}

const Icon: React.FunctionComponent<IconProps> = (props) => {
    const { name, ...rest } = props;
    return React.createElement(Icons[name], rest as any);
};

export default Icon;
