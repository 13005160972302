import { IconsType } from 'components/Icon';
import { Pages, ProjectEnum, ThemeOptions } from 'interfaces/main';
import { UserInfoRoleEnum } from 'services/generatedClientFromSwagger';
import { auth_logout } from 'store/auth/actions';
import { system_toggleSideMenu, system_toggleTheme } from 'store/system/actions';
import { system_side_menu_state, system_theme } from 'store/system/selectors';
import { user_rolePermitted } from 'store/user/selectors';

export interface SideMenuProps {
    sideMenuState: boolean;
    theme: ThemeOptions;
    user_rolePermitted: (roles: Array<UserInfoRoleEnum>) => boolean;
    toggleTheme: () => void;
    logOut: () => void;
    system_toggleSideMenu: () => void;
}

export const mapStateToProps = (state) => ({
    sideMenuState: system_side_menu_state(state),
    theme: system_theme(state),
    user_rolePermitted: (roles: Array<UserInfoRoleEnum>) => user_rolePermitted(state, roles),
});

export const mapDispatchToProps = {
    logOut: auth_logout,
    system_toggleSideMenu,
    toggleTheme: system_toggleTheme,
};

export interface SideMenuItem {
    t_name: string;
    icon: IconsType;
    link?: string;
    hidden?: 'smDown' | 'smUp' | 'xlDown' | 'xlUp' | 'xsDown' | 'xsUp' | 'mdUp' | 'mdDown' | 'lgUp' | 'lgDown';
    roles?: Array<UserInfoRoleEnum>;
    onClick?: () => void;
}

export interface SideMenuList extends Array<SideMenuItem> {}

export const menuLinks: SideMenuList = [
    {
        t_name: 'side_menu.item_names.dashboard',
        icon: IconsType.dashboard,
        link: `/${Pages.DASHBOARD}`,
    },
    {
        t_name: 'side_menu.item_names.site',
        icon: IconsType.book,
        link: `/${Pages.SITE}`,
        roles: [UserInfoRoleEnum.Admin, UserInfoRoleEnum.Readonly],
    },
    {
        t_name: 'side_menu.item_names.new_site',
        icon: IconsType.add_sort,
        link: `/${Pages.NEW_SITE}`,
        roles: [UserInfoRoleEnum.Admin],
    },
    {
        t_name: 'side_menu.item_names.payment_ips',
        icon: IconsType.visible,
        link: `/${Pages.PAYMENT_IPS}`,
        roles: [UserInfoRoleEnum.Admin],
    },
    {
        t_name: 'side_menu.item_names.copy_site',
        icon: IconsType.file_copy,
        link: `/${Pages.COPY_SITE}`,
        roles: [UserInfoRoleEnum.Admin],
    },
    {
        t_name: 'side_menu.item_names.translations_cz',
        icon: IconsType.translate,
        link: `/${Pages.TRANSLATIONS}/${ProjectEnum.Cz}`,
        roles: [UserInfoRoleEnum.Admin, UserInfoRoleEnum.Translator, UserInfoRoleEnum.Readonly],
    },
    {
        t_name: 'side_menu.item_names.translations_wt',
        icon: IconsType.translate,
        link: `/${Pages.TRANSLATIONS}/${ProjectEnum.Wt}`,
        roles: [UserInfoRoleEnum.Admin, UserInfoRoleEnum.Translator, UserInfoRoleEnum.Readonly],
    },
    {
        t_name: 'side_menu.item_names.translations_et',
        icon: IconsType.translate,
        link: `/${Pages.TRANSLATIONS}/${ProjectEnum.Et}`,
        roles: [UserInfoRoleEnum.Admin, UserInfoRoleEnum.Readonly],
    },
    {
        t_name: 'side_menu.item_names.translations_et-prop',
        icon: IconsType.translate,
        link: `/${Pages.TRANSLATIONS}/${ProjectEnum.EtProp}`,
        roles: [UserInfoRoleEnum.Admin, UserInfoRoleEnum.Readonly],
    },
    {
        t_name: 'side_menu.item_names.translations_prop',
        icon: IconsType.translate,
        link: `/${Pages.TRANSLATIONS}/${ProjectEnum.Prop}`,
        roles: [UserInfoRoleEnum.Admin, UserInfoRoleEnum.Translator, UserInfoRoleEnum.Readonly],
    },
    {
        t_name: 'side_menu.item_names.translations_crypto',
        icon: IconsType.translate,
        link: `/${Pages.TRANSLATIONS}/${ProjectEnum.Crypto}`,
        roles: [UserInfoRoleEnum.Admin, UserInfoRoleEnum.Translator, UserInfoRoleEnum.Readonly],
    },
    {
        t_name: 'side_menu.item_names.brand_management',
        icon: IconsType.settings,
        link: `/${Pages.BRAND_MANAGEMENT}`,
        roles: [
            UserInfoRoleEnum.Admin,
            UserInfoRoleEnum.SuccessManagerAdmin,
            UserInfoRoleEnum.SuccessManagerAgent,
            UserInfoRoleEnum.Agent,
        ],
    },
    {
        t_name: 'side_menu.item_names.languages',
        icon: IconsType.translate,
        link: `/${Pages.LANGUAGES}`,
        roles: [UserInfoRoleEnum.Admin],
    },
    {
        t_name: 'side_menu.item_names.users',
        icon: IconsType.settings,
        link: `/${Pages.USERS}`,
        roles: [UserInfoRoleEnum.Admin, UserInfoRoleEnum.SuccessManagerAdmin],
    },
    {
        t_name: 'side_menu.item_names.crypto_admin',
        icon: IconsType.bitcoin,
        link: `/${Pages.CRYPTO_ADMIN}`,
        roles: [UserInfoRoleEnum.Admin, UserInfoRoleEnum.CryptoAdmin],
    },
];
