import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Root from 'Root';
import LoaderFullScreen from 'components/LoaderFullScreen';

ReactDOM.render(
    <Suspense fallback={<LoaderFullScreen />}>
        <Root />
    </Suspense>,
    document.getElementById('root')
);

serviceWorker.unregister();
