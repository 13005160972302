import { makeStyles } from '@material-ui/core';
import { customGap, isLaptop, isTablet } from 'helpers/device_helper';

export default makeStyles((theme) => ({
    app_bar_root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'transparent',
        boxShadow: 'unset',
        justifyContent: 'space-between',
    },
    header_title_sector: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        ...customGap(theme.spacing(3), 'horizontal'),
        color: theme.palette.text.primary,
    },
    header_actions_sector: {
        display: 'flex',
        flexDirection: 'row',
        ...customGap(theme.spacing(3), 'horizontal'),
    },
    header_container: {
        width: '100%',
        height: '73px',
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        borderBottom: `1px solid ${theme.palette.action.disabledBackground}`,
        ...isTablet({
            gridTemplateColumns: '2fr 1fr',
            borderBottom: 'unset',
        }),
    },
    header_container__private: {
        width: '100%',
        height: '73px',
        display: 'grid',
        gridTemplateColumns: '1fr',
        borderBottom: `1px solid ${theme.palette.action.disabledBackground}`,
        ...isTablet({
            gridTemplateColumns: '2fr auto',
            borderBottom: 'unset',
        }),
        ...isLaptop({
            gridTemplateColumns: '2fr 1fr',
        }),
    },
    header_left_section: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: `0px ${theme.spacing(5)}px`,
        // maxWidth: '78.6rem',
        // width: '100%',
        // margin: '0 auto',
        ...customGap(theme.spacing(3), 'horizontal'),
        ...isTablet({
            justifyContent: 'space-between',
        }),
    },
    header_right_section: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'flex-end',
        padding: `0px ${theme.spacing(5)}px`,
        // maxWidth: '32rem',
        // width: '100%',
        // margin: '0 auto',
        ...customGap(theme.spacing(3), 'horizontal'),
    },
}));
