import Snackbar, { SnackbarProps } from 'components/Snackbar';
import React from 'react';
import { connect } from 'react-redux';
import { system_snackbarObject, system_snackbarState } from 'store/system/selectors';

export interface SnackbarWrapperProps {
    snackbarState: boolean;
    snackbarObject: SnackbarProps;
}
export const mapStateToProps = (state) => ({
    snackbarState: system_snackbarState(state),
    snackbarObject: system_snackbarObject(state),
});

export const mapDispatchToProps = {};

const SnackbarWrapper: React.FunctionComponent<SnackbarWrapperProps> = ({ snackbarObject, snackbarState }) => {
    return <Snackbar {...snackbarObject} snackbarState={snackbarState} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SnackbarWrapper));
