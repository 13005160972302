/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CpOptionPaymentIpValue } from '../models';
// @ts-ignore
import { CpOptionPaymentIps } from '../models';
// @ts-ignore
import { CpOptionsDBI } from '../models';
// @ts-ignore
import { CreateOptionDto } from '../models';
// @ts-ignore
import { UpdateOptionDto } from '../models';
/**
 * OptionsApi - axios parameter creator
 * @export
 */
export const OptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CreateOptionDto} createOptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsApiControllerCreateOption: async (
            createOptionDto: CreateOptionDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'createOptionDto' is not null or undefined
            assertParamExists('optionsApiControllerCreateOption', 'createOptionDto', createOptionDto);
            const localVarPath = `/api/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(createOptionDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CpOptionPaymentIpValue} cpOptionPaymentIpValue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsApiControllerCreatePaymentIp: async (
            cpOptionPaymentIpValue: CpOptionPaymentIpValue,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'cpOptionPaymentIpValue' is not null or undefined
            assertParamExists('optionsApiControllerCreatePaymentIp', 'cpOptionPaymentIpValue', cpOptionPaymentIpValue);
            const localVarPath = `/api/options/createPaymentIp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                cpOptionPaymentIpValue,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsApiControllerDeleteOption: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('optionsApiControllerDeleteOption', 'id', id);
            const localVarPath = `/api/options/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsApiControllerDeployPaymentIps: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/options/deployPaymentIps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {'cluster_lbs' | 'general' | 'payment_ips' | 'payment_ips_deployed' | 'languages' | 'PROP_OPTIONS'} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsApiControllerGetOptionsByName: async (
            name: 'cluster_lbs' | 'general' | 'payment_ips' | 'payment_ips_deployed' | 'languages' | 'PROP_OPTIONS',
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('optionsApiControllerGetOptionsByName', 'name', name);
            const localVarPath = `/api/options/{name}`.replace(`{${'name'}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsApiControllerGetPaymentIps: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/options/getPaymentIps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {UpdateOptionDto} updateOptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsApiControllerUpdateOption: async (
            id: string,
            updateOptionDto: UpdateOptionDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('optionsApiControllerUpdateOption', 'id', id);
            // verify required parameter 'updateOptionDto' is not null or undefined
            assertParamExists('optionsApiControllerUpdateOption', 'updateOptionDto', updateOptionDto);
            const localVarPath = `/api/options/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(updateOptionDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * OptionsApi - functional programming interface
 * @export
 */
export const OptionsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = OptionsApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {CreateOptionDto} createOptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionsApiControllerCreateOption(
            createOptionDto: CreateOptionDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CpOptionsDBI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionsApiControllerCreateOption(
                createOptionDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {CpOptionPaymentIpValue} cpOptionPaymentIpValue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionsApiControllerCreatePaymentIp(
            cpOptionPaymentIpValue: CpOptionPaymentIpValue,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CpOptionPaymentIps>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionsApiControllerCreatePaymentIp(
                cpOptionPaymentIpValue,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionsApiControllerDeleteOption(
            id: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CpOptionsDBI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionsApiControllerDeleteOption(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionsApiControllerDeployPaymentIps(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionsApiControllerDeployPaymentIps(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {'cluster_lbs' | 'general' | 'payment_ips' | 'payment_ips_deployed' | 'languages' | 'PROP_OPTIONS'} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionsApiControllerGetOptionsByName(
            name: 'cluster_lbs' | 'general' | 'payment_ips' | 'payment_ips_deployed' | 'languages' | 'PROP_OPTIONS',
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CpOptionsDBI>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionsApiControllerGetOptionsByName(
                name,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionsApiControllerGetPaymentIps(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionsApiControllerGetPaymentIps(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {UpdateOptionDto} updateOptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionsApiControllerUpdateOption(
            id: string,
            updateOptionDto: UpdateOptionDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CpOptionsDBI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionsApiControllerUpdateOption(
                id,
                updateOptionDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * OptionsApi - factory interface
 * @export
 */
export const OptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OptionsApiFp(configuration);
    return {
        /**
         *
         * @param {CreateOptionDto} createOptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsApiControllerCreateOption(createOptionDto: CreateOptionDto, options?: any): AxiosPromise<CpOptionsDBI> {
            return localVarFp
                .optionsApiControllerCreateOption(createOptionDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CpOptionPaymentIpValue} cpOptionPaymentIpValue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsApiControllerCreatePaymentIp(
            cpOptionPaymentIpValue: CpOptionPaymentIpValue,
            options?: any
        ): AxiosPromise<CpOptionPaymentIps> {
            return localVarFp
                .optionsApiControllerCreatePaymentIp(cpOptionPaymentIpValue, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsApiControllerDeleteOption(id: string, options?: any): AxiosPromise<CpOptionsDBI> {
            return localVarFp.optionsApiControllerDeleteOption(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsApiControllerDeployPaymentIps(options?: any): AxiosPromise<boolean> {
            return localVarFp.optionsApiControllerDeployPaymentIps(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {'cluster_lbs' | 'general' | 'payment_ips' | 'payment_ips_deployed' | 'languages' | 'PROP_OPTIONS'} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsApiControllerGetOptionsByName(
            name: 'cluster_lbs' | 'general' | 'payment_ips' | 'payment_ips_deployed' | 'languages' | 'PROP_OPTIONS',
            options?: any
        ): AxiosPromise<Array<CpOptionsDBI>> {
            return localVarFp
                .optionsApiControllerGetOptionsByName(name, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsApiControllerGetPaymentIps(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.optionsApiControllerGetPaymentIps(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {UpdateOptionDto} updateOptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsApiControllerUpdateOption(
            id: string,
            updateOptionDto: UpdateOptionDto,
            options?: any
        ): AxiosPromise<CpOptionsDBI> {
            return localVarFp
                .optionsApiControllerUpdateOption(id, updateOptionDto, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * OptionsApi - object-oriented interface
 * @export
 * @class OptionsApi
 * @extends {BaseAPI}
 */
export class OptionsApi extends BaseAPI {
    /**
     *
     * @param {CreateOptionDto} createOptionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public optionsApiControllerCreateOption(createOptionDto: CreateOptionDto, options?: any) {
        return OptionsApiFp(this.configuration)
            .optionsApiControllerCreateOption(createOptionDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {CpOptionPaymentIpValue} cpOptionPaymentIpValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public optionsApiControllerCreatePaymentIp(cpOptionPaymentIpValue: CpOptionPaymentIpValue, options?: any) {
        return OptionsApiFp(this.configuration)
            .optionsApiControllerCreatePaymentIp(cpOptionPaymentIpValue, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public optionsApiControllerDeleteOption(id: string, options?: any) {
        return OptionsApiFp(this.configuration)
            .optionsApiControllerDeleteOption(id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public optionsApiControllerDeployPaymentIps(options?: any) {
        return OptionsApiFp(this.configuration)
            .optionsApiControllerDeployPaymentIps(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {'cluster_lbs' | 'general' | 'payment_ips' | 'payment_ips_deployed' | 'languages' | 'PROP_OPTIONS'} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public optionsApiControllerGetOptionsByName(
        name: 'cluster_lbs' | 'general' | 'payment_ips' | 'payment_ips_deployed' | 'languages' | 'PROP_OPTIONS',
        options?: any
    ) {
        return OptionsApiFp(this.configuration)
            .optionsApiControllerGetOptionsByName(name, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public optionsApiControllerGetPaymentIps(options?: any) {
        return OptionsApiFp(this.configuration)
            .optionsApiControllerGetPaymentIps(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {UpdateOptionDto} updateOptionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsApi
     */
    public optionsApiControllerUpdateOption(id: string, updateOptionDto: UpdateOptionDto, options?: any) {
        return OptionsApiFp(this.configuration)
            .optionsApiControllerUpdateOption(id, updateOptionDto, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
