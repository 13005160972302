import React from 'react';
import LanguageSelect from '../components/LanguageSelect';
import Logo from '../components/Logo';
import ThemeSwitch from '../components/ThemeSwitch';
import useStyles from '../header.styles';

export interface PublicHeaderProps {}

const PublicHeader: React.FunctionComponent<PublicHeaderProps> = () => {
    const classes = useStyles();
    return (
        <header className={classes.header_container}>
            <div className={classes.header_left_section}>
                <Logo />
            </div>
            <div className={classes.header_right_section}>
                <ThemeSwitch />
                <LanguageSelect />
            </div>
        </header>
    );
};

export default PublicHeader;
