import React from 'react';
import Loader from '../Loader';

const LoaderFullScreen = () => {
    return (
        <div
            style={{
                position: 'fixed',
                width: '100%',
                height: '100%',
                top: 0,
                right: 0,
                backgroundColor: 'rgba(0,0,0,0.3)',
                zIndex: 10000,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Loader />
        </div>
    );
};

export default LoaderFullScreen;
