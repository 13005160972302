export enum Pages {
    LOGIN = 'login',
    DASHBOARD = 'dashboard',
    SITE = 'site',
    NEW_SITE = 'new_site',
    COPY_SITE = 'copy_site',
    TRANSLATIONS = 'translations',
    WT_TRANSLATIONS = 'wt-translations',
    AUDIT_HISTORY = 'audit-history',
    SUCCESS_MANAGER_AGENT = 'success-manager-agent',
    SUCCESS_MANAGER_ADMIN = 'success-manager-admin',
    BRAND_MANAGEMENT = 'brand-management',
    PAYMENT_IPS = 'payment-ips',
    LANGUAGES = 'languages',
    USERS = 'users',
    CRYPTO_ADMIN = 'crypto-admin',
}

export enum ProjectEnum {
    Cz = 'cz',
    Wt = 'wt',
    Et = 'et',
    Prop = 'prop',
    EtProp = 'et-prop',
    Crypto = 'crypto',
}

export const getGoBackPath = (
    currectPathname: string,
    asArray = false
): undefined | string | { link: string; labelT: string }[] => {
    switch (currectPathname) {
        // case `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__TRADING_FOR_PRO}`:
        //     if (asArray)
        //         return [
        //             {
        //                 link: `/${Pages.TRADING_ANALYSIS}`,
        //                 labelT: 'common:breadcrump.trading_analysis',
        //             },
        //             {
        //                 link: `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__TRADING_FOR_PRO}`,
        //                 labelT: 'common:breadcrump.trading_analysis__trading_for_pro',
        //             },
        //         ];
        //     return `/${Pages.TRADING_ANALYSIS}`;
        default:
            return undefined;
    }
};

export enum ThemeOptions {
    LIGHT = 'light',
    DARK = 'dark',
}

export interface SingleErrorScheme {
    error: boolean;
    error_label: string;
}

export interface ErrorsScheme {
    [field: string]: SingleErrorScheme;
}
