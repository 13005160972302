import { auth_hasJwtToken } from 'store/auth/selectors';

export interface HeaderProps {
    hasJwtToken: boolean;
}

export const mapStateToProps = (state) => ({
    hasJwtToken: auth_hasJwtToken(state),
});

export const mapDispatchToProps = {};
