import { saveTokenToLocalStorage } from 'helpers/token';
import { EAuthTypes } from './actionsTypes';
import { system_resetApp } from 'store/system/actions';
import axios from 'services/axios';

export const auth_loginStart = (token: string) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: EAuthTypes.LOGIN_START,
            });
            const jwt = await axios.AuthApi.authControllerLogin({
                token,
            });
            console.log('jwt', jwt);
            if (!jwt.data.jwt) {
                throw new Error('');
            }
            saveTokenToLocalStorage(jwt.data.jwt);
            dispatch({
                type: EAuthTypes.LOGIN_SUCCESS,
            });
            return true;
        } catch (error) {
            dispatch({
                type: EAuthTypes.LOGIN_FAILED,
            });
            return true;
        }
    };
};

export const auth_logout = () => {
    return async (dispatch) => {
        dispatch(system_resetApp());
    };
};
