/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SessionHoursDayHoursDto } from './session-hours-day-hours-dto';

/**
 *
 * @export
 * @interface UpdateSessionHoursDto
 */
export class UpdateSessionHoursDto {
    /**
     *
     * @type {string}
     * @memberof UpdateSessionHoursDto
     */
    day: UpdateSessionHoursDtoDayEnum;
    /**
     *
     * @type {Array<SessionHoursDayHoursDto>}
     * @memberof UpdateSessionHoursDto
     */
    hours: Array<SessionHoursDayHoursDto>;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateSessionHoursDtoDayEnum {
    Sunday = 'sunday',
    Monday = 'monday',
    Tuesday = 'tuesday',
    Wednesday = 'wednesday',
    Thursday = 'thursday',
    Friday = 'friday',
    Saturday = 'saturday',
}
