/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PropChallengeConditions } from './prop-challenge-conditions';
import { PropChallengePackageTag } from './prop-challenge-package-tag';

/**
 *
 * @export
 * @interface PropPackage
 */
export class PropPackage {
    /**
     *
     * @type {string}
     * @memberof PropPackage
     */
    _id?: string;
    /**
     *
     * @type {string}
     * @memberof PropPackage
     */
    type: PropPackageTypeEnum;
    /**
     *
     * @type {number}
     * @memberof PropPackage
     */
    amount: number;
    /**
     *
     * @type {number}
     * @memberof PropPackage
     */
    funding: number;
    /**
     *
     * @type {PropChallengePackageTag}
     * @memberof PropPackage
     */
    tag?: PropChallengePackageTag;
    /**
     *
     * @type {string}
     * @memberof PropPackage
     */
    promoCode?: string;
    /**
     *
     * @type {number}
     * @memberof PropPackage
     */
    retryDiscountPercent?: number;
    /**
     *
     * @type {PropChallengeConditions}
     * @memberof PropPackage
     */
    conditions: PropChallengeConditions;
}

/**
 * @export
 * @enum {string}
 */
export enum PropPackageTypeEnum {
    Starter = 'starter',
    Standard = 'standard',
    Advanced = 'advanced',
    Expert = 'expert',
    Master = 'master',
    Professional = 'professional',
    Champion = 'champion',
    Elite = 'elite',
    Supreme = 'supreme',
    Ultimate = 'ultimate',
    Legendary = 'legendary',
    Mythical = 'mythical',
    Titan = 'titan',
    Olympian = 'olympian',
    Cosmic = 'cosmic',
    Bronze = 'bronze',
    Silver = 'silver',
    Gold = 'gold',
    Platinum = 'platinum',
    Diamond = 'diamond',
}
