import i18n, { InitOptions } from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { defaultI18Lng, locales, localesCodeMap } from './config/i18n';
import config from 'config/common';
// import { changePlatformSrc } from 'helpers/changePlatformSrc';

const options: InitOptions = {
    debug: false,
    fallbackLng: defaultI18Lng,
    preload: [defaultI18Lng],
    supportedLngs: localesCodeMap,
    defaultNS: 'common',
    load: 'languageOnly',
    interpolation: { escapeValue: false },
    lowerCaseLng: true,
    react: { wait: false },
    backend: {
        queryStringParams: {
            v: config.version,
        },
    },
};

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(options);

export const onChangeLng = (lng: string) => {
    if (!Object.keys(locales).some((language) => lng === language)) {
        const cleanedLanguage = lng.split('-')[0];
        const cleanedLanguageIsFound = Object.keys(locales).some((language) => cleanedLanguage === language);
        const newLanguage = cleanedLanguageIsFound ? cleanedLanguage : defaultI18Lng;
        return i18n.changeLanguage(newLanguage);
    }
    // changePlatformSrc(undefined, lng, undefined);
    const html = document.getElementsByTagName('html')[0];
    try {
        lng = lng.substr(0, 2);
    } catch {}
    html.setAttribute('lang', lng);
    let dir = 'ltr';
    try {
        dir = locales[lng].dir;
    } catch {}
    try {
        document.body.dir = dir;
        return { direction: dir };
    } catch {}
};

export default i18n;
