import { makeStyles } from '@material-ui/core';
import { cssEnd, customGap, isDesktop, isTablet, oppositeCss } from 'helpers/device_helper';

interface StylesProps {
    sideMenuState: boolean;
}

export default makeStyles((theme) => ({
    overlay: (props: StylesProps) => ({
        height: '100%',
        width: 'auto',
        zIndex: 'initial',
        overflow: 'hidden',
        background: 'unset',
        position: 'fixed',
        ...isTablet({
            position: 'initial',
        }),
        ...isDesktop({
            height: '100%',
            width: 'auto',
            zIndex: 'initial',
            overflow: 'auto',
            background: 'unset',
            position: 'initial',
        }),
    }),
    overlay__open: (props: StylesProps) => ({
        height: '100vh',
        width: '100vw',
        zIndex: theme.zIndex.drawer,
        overflow: 'hidden',
        background: 'rgba(0, 0, 0, 0.4)',
        position: 'fixed',
        ...isTablet({
            position: 'fixed',
        }),
        ...isDesktop({
            height: '100%',
            width: 'auto',
            zIndex: 'initial',
            overflow: 'auto',
            background: 'unset',
            position: 'initial',
        }),
    }),
    menu_container: (props: StylesProps) => ({
        width: '275px',
        overflowX: 'hidden',
        overflowY: 'overlay',
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'repeat(3, auto) 1fr',
        gap: `${theme.spacing(9)}px`,
        backgroundColor: theme.palette.side_menu.background,
        ...oppositeCss(
            theme.direction,
            'padding',
            `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(6)}px ${theme.spacing(2)}px`
        ),
        ...oppositeCss(theme.direction, 'margin', '0px 0px 0px -275px'),
        ...cssEnd(theme.direction, 'border', `1px solid ${theme.palette.action.disabledBackground}`),
        ...isTablet({
            width: '64px',
            margin: 'unset',
        }),
    }),
    menu_container__open: () => ({
        margin: 'unset',
        ...isTablet({
            width: '275px',
            margin: 'unset',
        }),
    }),
    menu_list_wrapper: (props: StylesProps) => ({
        position: 'relative',
        display: 'grid',
        width: '100%',
        gap: `${theme.spacing(1)}px`,
        '&:not(:last-child):after': {
            content: "''",
            position: 'absolute',
            height: '1px',
            bottom: `-${theme.spacing(4)}px`,
            ...oppositeCss(theme.direction, 'margin', `0px 0px 0px -${theme.spacing(2)}px`),
            width: `calc(100% + ${theme.spacing(4)}px)`,
            backgroundColor: theme.palette.action.disabledBackground,
        },
        '&:first-child:after': {
            display: props.sideMenuState ? 'block' : 'none',
        },
        '&:last-child': {
            gap: 'unset',
            ...customGap(theme.spacing(1), 'vertical'),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
    }),
    seperator: {
        width: '100%',
        height: '1px',
        backgroundColor: 'grey',
    },
}));
