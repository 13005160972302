/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface CrmApiDBI
 */
export class CrmApiDBI {
    /**
     *
     * @type {string}
     * @memberof CrmApiDBI
     */
    ws_url: string;
    /**
     *
     * @type {string}
     * @memberof CrmApiDBI
     */
    ws_user: string;
    /**
     *
     * @type {string}
     * @memberof CrmApiDBI
     */
    ws_pass: string;
    /**
     *
     * @type {string}
     * @memberof CrmApiDBI
     */
    wsdl_endpoint?: string;
    /**
     *
     * @type {string}
     * @memberof CrmApiDBI
     */
    CRM_ALT_URL?: string;
    /**
     *
     * @type {string}
     * @memberof CrmApiDBI
     */
    CRM_ALT_JWT_SECRET?: string;
    /**
     *
     * @type {string}
     * @memberof CrmApiDBI
     */
    CRM_ALT_REGULATION_TYPE?: CrmApiDBICRMALTREGULATIONTYPEEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CrmApiDBICRMALTREGULATIONTYPEEnum {
    Ltt = 'LTT',
    Lfs = 'LFS',
    Kimbo = 'KIMBO',
}
