import { CryptoReducerState } from './interfaces';
import { ECryptoTypes } from './actionsTypes';

const initialState: CryptoReducerState = {
    sessionHours: undefined,
    sessionHoursLoading: false,
    activePairs: undefined,
    activePairsLoading: false,
    markupRange: undefined,
    markupRangeLoading: false,
    updateSessionHoursDayLoading: false,
    maximumOrderAmount: undefined,
    maximumOrderAmountLoading: false,
    minimumOrderAmount: undefined,
    minimumOrderAmountLoading: false,
    liquidityProviderSettings: undefined,
    liquidityProviderSettingsLoading: false,
    activeLiquidityProvider: undefined,
    activeLiquidityProviderLoading: false,
    pairsMinimumOrderAmount: {},
    pairsMinimumOrderAmountLoading: false,
};

const cryptoReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ECryptoTypes.GET_SESSION_HOURS:
            return {
                ...state,
                sessionHoursLoading: true,
            };

        case ECryptoTypes.GET_SESSION_HOURS_SUCCESS:
            return {
                ...state,
                sessionHours: payload,
                sessionHoursLoading: false,
            };

        case ECryptoTypes.GET_SESSION_HOURS_FAILED:
            return {
                ...state,
                sessionHoursLoading: false,
            };

        case ECryptoTypes.UPDATE_SESSION_HOURS:
            return {
                ...state,
                updateSessionHoursDayLoading: true,
            };

        case ECryptoTypes.UPDATE_SESSION_HOURS_SUCCESS:
            return {
                ...state,
                sessionHours: payload,
                updateSessionHoursDayLoading: false,
            };

        case ECryptoTypes.UPDATE_SESSION_HOURS_FAILED:
            return {
                ...state,
                updateSessionHoursDayLoading: false,
            };

        case ECryptoTypes.GET_MINIMUM_ORDER_AMOUNT:
            return {
                ...state,
                minimumOrderAmountLoading: true,
            };

        case ECryptoTypes.GET_MINIMUM_ORDER_AMOUNT_SUCCESS:
            return {
                ...state,
                minimumOrderAmount: payload,
                minimumOrderAmountLoading: false,
            };

        case ECryptoTypes.GET_MINIMUM_ORDER_AMOUNT_FAILED:
            return {
                ...state,
                minimumOrderAmountLoading: false,
            };

        case ECryptoTypes.UPDATE_MINIMUM_ORDER_AMOUNT:
            return {
                ...state,
                minimumOrderAmountLoading: true,
            };

        case ECryptoTypes.UPDATE_MINIMUM_ORDER_AMOUNT_SUCCESS:
            return {
                ...state,
                minimumOrderAmount: payload,
                minimumOrderAmountLoading: false,
            };

        case ECryptoTypes.UPDATE_MINIMUM_ORDER_AMOUNT_FAILED:
            return {
                ...state,
                minimumOrderAmountLoading: false,
            };

        case ECryptoTypes.GET_MAXIMUM_ORDER_AMOUNT:
            return {
                ...state,
                maximumOrderAmountLoading: true,
            };

        case ECryptoTypes.GET_MAXIMUM_ORDER_AMOUNT_SUCCESS:
            return {
                ...state,
                maximumOrderAmount: payload,
                maximumOrderAmountLoading: false,
            };

        case ECryptoTypes.GET_MAXIMUM_ORDER_AMOUNT_FAILED:
            return {
                ...state,
                maximumOrderAmountLoading: false,
            };

        case ECryptoTypes.UPDATE_MAXIMUM_ORDER_AMOUNT:
            return {
                ...state,
                maximumOrderAmountLoading: true,
            };

        case ECryptoTypes.UPDATE_MAXIMUM_ORDER_AMOUNT_SUCCESS:
            return {
                ...state,
                maximumOrderAmount: payload,
                maximumOrderAmountLoading: false,
            };

        case ECryptoTypes.UPDATE_MAXIMUM_ORDER_AMOUNT_FAILED:
            return {
                ...state,
                maximumOrderAmountLoading: false,
            };
        case ECryptoTypes.GET_ACTIVE_PAIRS:
            return {
                ...state,
                activePairsLoading: true,
            };
        case ECryptoTypes.GET_ACTIVE_PAIRS_SUCCESS:
            return {
                ...state,
                activePairs: payload,
                activePairsLoading: false,
            };
        case ECryptoTypes.GET_ACTIVE_PAIRS_FAILED:
            return {
                ...state,
                activePairsLoading: false,
            };
        case ECryptoTypes.GET_MARKUP_RANGE:
            return {
                ...state,
                markupRangeLoading: true,
            };
        case ECryptoTypes.GET_MARKUP_RANGE_SUCCESS:
            return {
                ...state,
                markupRange: payload,
                markupRangeLoading: false,
            };
        case ECryptoTypes.GET_MARKUP_RANGE_FAILED:
            return {
                ...state,
                markupRangeLoading: false,
            };
        case ECryptoTypes.UPDATE_MARKUP_RANGE:
            return {
                ...state,
                markupRangeLoading: true,
            };
        case ECryptoTypes.UPDATE_MARKUP_RANGE_SUCCESS:
            return {
                ...state,
                markupRange: payload,
                markupRangeLoading: false,
            };
        case ECryptoTypes.UPDATE_MARKUP_RANGE_FAiLED:
            return {
                ...state,
                markupRangeLoading: false,
            };
        case ECryptoTypes.UPDATE_ACTIVE_PAIRS:
            return {
                ...state,
                activePairsLoading: true,
            };
        case ECryptoTypes.UPDATE_ACTIVE_PAIRS_SUCCESS:
            return {
                ...state,
                activePairs: payload,
                activePairsLoading: false,
            };
        case ECryptoTypes.UPDATE_ACTIVE_PAIRS_FAiLED:
            return {
                ...state,
                activePairsLoading: false,
            };

        case ECryptoTypes.GET_MINIMUM_ORDER_AMOUNT_BY_SYMBOL:
            return {
                ...state,
                pairsMinimumOrderAmountLoading: true,
            };

        case ECryptoTypes.GET_MINIMUM_ORDER_AMOUNT_BY_SYMBOL_SUCCESS:
            return {
                ...state,
                pairsMinimumOrderAmount: payload,
                pairsMinimumOrderAmountLoading: false,
            };

        case ECryptoTypes.GET_MINIMUM_ORDER_AMOUNT_BY_SYMBOL_FAILED:
            return {
                ...state,
                pairsMinimumOrderAmountLoading: false,
            };

        case ECryptoTypes.UPDATE_MINIMUM_ORDER_AMOUNT_BY_SYMBOL:
            return {
                ...state,
                pairsMinimumOrderAmountLoading: true,
            };

        case ECryptoTypes.UPDATE_MINIMUM_ORDER_AMOUNT_BY_SYMBOL_SUCCESS:
            return {
                ...state,
                pairsMinimumOrderAmount: { ...payload.pairs },
                pairsMinimumOrderAmountLoading: false,
            };

        case ECryptoTypes.UPDATE_MINIMUM_ORDER_AMOUNT_BY_SYMBOL_FAILED:
            return {
                ...state,
                pairsMinimumOrderAmountLoading: false,
            };

        case ECryptoTypes.DELETE_MIN_ORDER_AMOUNT_BY_SYMBOL:
            return {
                ...state,
                pairsMinimumOrderAmountLoading: true,
            };

        case ECryptoTypes.DELETE_MIN_ORDER_AMOUNT_BY_SYMBOL_SUCCESS:
            return {
                ...state,
                pairsMinimumOrderAmount: { ...payload.pairs },
                pairsMinimumOrderAmountLoading: false,
            };

        case ECryptoTypes.DELETE_MIN_ORDER_AMOUNT_BY_SYMBOL_FAILED:
            return {
                ...state,
                pairsMinimumOrderAmountLoading: false,
            };

        case ECryptoTypes.CREATE_NEW_PAIR_MARKUP_RANGES:
            return {
                ...state,
                markupRange: {
                    defaultMarkups: state.markupRange?.defaultMarkups,
                    pairMarkups: state.markupRange?.pairMarkups
                        ? {
                              ...state.markupRange?.pairMarkups,
                              [payload]: {
                                  ranges: [],
                              },
                          }
                        : {
                              [payload]: {
                                  ranges: [],
                              },
                          },
                },
            };
        case ECryptoTypes.GET_LIQUIDITY_PROVIDER_SETTINGS:
            return {
                ...state,
                liquidityProviderSettingsLoading: true,
            };
        case ECryptoTypes.GET_LIQUIDITY_PROVIDER_SETTINGS_SUCCESS:
            return {
                ...state,
                liquidityProviderSettings: payload,
                liquidityProviderSettingsLoading: false,
            };
        case ECryptoTypes.GET_LIQUIDITY_PROVIDER_SETTINGS_FAILED:
            return {
                ...state,
                liquidityProviderSettingsLoading: false,
            };
        case ECryptoTypes.GET_ACTIVE_LIQUIDITY_PROVIDER:
            return {
                ...state,
                activeLiquidityProviderLoading: true,
            };
        case ECryptoTypes.GET_ACTIVE_LIQUIDITY_PROVIDER_SUCCESS:
            return {
                ...state,
                activeLiquidityProvider: payload,
                activeLiquidityProviderLoading: false,
            };
        case ECryptoTypes.GET_ACTIVE_LIQUIDITY_PROVIDER_FAILED:
            return {
                ...state,
                activeLiquidityProviderLoading: false,
            };
        case ECryptoTypes.UPDATE_ACTIVE_LIQUIDITY_PROVIDER:
            return {
                ...state,
                activeLiquidityProviderLoading: true,
            };
        case ECryptoTypes.UPDATE_ACTIVE_LIQUIDITY_PROVIDER_SUCCESS:
            return {
                ...state,
                activeLiquidityProvider: payload,
                activeLiquidityProviderLoading: false,
            };
        case ECryptoTypes.UPDATE_ACTIVE_LIQUIDITY_PROVIDER_FAILED:
            return {
                ...state,
                activeLiquidityProviderLoading: false,
            };

        default:
            return state;
    }
};

export default cryptoReducer;
