import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { auth_hasJwtToken } from 'store/auth/selectors';
import { connect } from 'react-redux';
import ToHomePage from './ToHomePage';
import { getAndRemoveLinkFromSessionStorage } from 'helpers/linkRemember';

export interface LoginRouteProps {
    component: any;
    hasJwtToken: boolean;
}

const LoginRoute: React.FunctionComponent<LoginRouteProps> = (props) => {
    const { component: Component, hasJwtToken, ...restProps } = props;

    if (hasJwtToken) {
        const linkToRedirect = getAndRemoveLinkFromSessionStorage();
        if (linkToRedirect) {
            return <Redirect to={linkToRedirect} />;
        }
        return <ToHomePage />;
    }
    return <Route {...restProps} render={(props) => <Component {...props} />} />;
};

export const mapStateToProps = (state) => ({
    hasJwtToken: auth_hasJwtToken(state),
});

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(LoginRoute));
