import { createStyles } from '@material-ui/core';
import palette from 'muiTheme/config/palette';

export default createStyles({
    root: {
        fontSize: '1.2rem',
        letterSpacing: '0.26px',
        lineHeight: 1.19,
        whiteSpace: 'pre-line',
        '&$error': {
            margin: '-6px 0px 0px 0px',
            backgroundColor: palette.dark.error.main,
            color: palette.dark.error.contrastText,
            padding: '10px 16px 4px',
            borderRadius: '0px 0px 8px 8px',
        },
    },
});
