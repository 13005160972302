/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActiveLiquidityProvider } from '../models';
// @ts-ignore
import { ActivePairs } from '../models';
// @ts-ignore
import { DeleteMarkupRangeWithSiteNameDto } from '../models';
// @ts-ignore
import { DeleteMinOrderAmountBySymbolDto } from '../models';
// @ts-ignore
import { LiquidityProviderSettings } from '../models';
// @ts-ignore
import { Markups } from '../models';
// @ts-ignore
import { MaxOrderAmount } from '../models';
// @ts-ignore
import { MaxOrderAmountRequestDto } from '../models';
// @ts-ignore
import { MinOrderAmount } from '../models';
// @ts-ignore
import { MinOrderAmountBySymbolRequestDto } from '../models';
// @ts-ignore
import { MinOrderAmountRequestDto } from '../models';
// @ts-ignore
import { MinimumRangeBySymbolInterface } from '../models';
// @ts-ignore
import { SessionHours } from '../models';
// @ts-ignore
import { SessionHoursRequestDto } from '../models';
// @ts-ignore
import { UpdateActiveLiquidityProviderRequestDto } from '../models';
// @ts-ignore
import { UpdateActivePairsDtoRequestDto } from '../models';
// @ts-ignore
import { UpdateMarkupRangeDtoRequestDto } from '../models';
/**
 * CryptoApi - axios parameter creator
 * @export
 */
export const CryptoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {DeleteMarkupRangeWithSiteNameDto} deleteMarkupRangeWithSiteNameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerDeleteMarkupRange: async (
            deleteMarkupRangeWithSiteNameDto: DeleteMarkupRangeWithSiteNameDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'deleteMarkupRangeWithSiteNameDto' is not null or undefined
            assertParamExists(
                'cryptoControllerDeleteMarkupRange',
                'deleteMarkupRangeWithSiteNameDto',
                deleteMarkupRangeWithSiteNameDto
            );
            const localVarPath = `/api/crypto/delete-active-pairs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                deleteMarkupRangeWithSiteNameDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DeleteMinOrderAmountBySymbolDto} deleteMinOrderAmountBySymbolDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerDeleteMinOrderAmountBySymbol: async (
            deleteMinOrderAmountBySymbolDto: DeleteMinOrderAmountBySymbolDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'deleteMinOrderAmountBySymbolDto' is not null or undefined
            assertParamExists(
                'cryptoControllerDeleteMinOrderAmountBySymbol',
                'deleteMinOrderAmountBySymbolDto',
                deleteMinOrderAmountBySymbolDto
            );
            const localVarPath = `/api/crypto/delete-min-order-amount-by-symbol`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                deleteMinOrderAmountBySymbolDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerGetActiveLiquidityProvider: async (
            siteName: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'siteName' is not null or undefined
            assertParamExists('cryptoControllerGetActiveLiquidityProvider', 'siteName', siteName);
            const localVarPath = `/api/crypto/get-active-liquidity-provider/{siteName}`.replace(
                `{${'siteName'}}`,
                encodeURIComponent(String(siteName))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerGetActivePairs: async (siteName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteName' is not null or undefined
            assertParamExists('cryptoControllerGetActivePairs', 'siteName', siteName);
            const localVarPath = `/api/crypto/get-active-pairs/{siteName}`.replace(
                `{${'siteName'}}`,
                encodeURIComponent(String(siteName))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerGetLiquidityProviderSettings: async (
            siteName: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'siteName' is not null or undefined
            assertParamExists('cryptoControllerGetLiquidityProviderSettings', 'siteName', siteName);
            const localVarPath = `/api/crypto/get-liquidity-provider-settings/{siteName}`.replace(
                `{${'siteName'}}`,
                encodeURIComponent(String(siteName))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerGetMarkups: async (siteName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteName' is not null or undefined
            assertParamExists('cryptoControllerGetMarkups', 'siteName', siteName);
            const localVarPath = `/api/crypto/get-markups/{siteName}`.replace(
                `{${'siteName'}}`,
                encodeURIComponent(String(siteName))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerGetMaxOrderAmount: async (siteName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteName' is not null or undefined
            assertParamExists('cryptoControllerGetMaxOrderAmount', 'siteName', siteName);
            const localVarPath = `/api/crypto/get-max-order-amount/{siteName}`.replace(
                `{${'siteName'}}`,
                encodeURIComponent(String(siteName))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerGetMinOrderAmount: async (siteName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteName' is not null or undefined
            assertParamExists('cryptoControllerGetMinOrderAmount', 'siteName', siteName);
            const localVarPath = `/api/crypto/get-min-order-amount/{siteName}`.replace(
                `{${'siteName'}}`,
                encodeURIComponent(String(siteName))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerGetMinOrderAmountBySymbols: async (
            siteName: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'siteName' is not null or undefined
            assertParamExists('cryptoControllerGetMinOrderAmountBySymbols', 'siteName', siteName);
            const localVarPath = `/api/crypto/get-min-order-amount-by-symbol/{siteName}`.replace(
                `{${'siteName'}}`,
                encodeURIComponent(String(siteName))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerGetSessionHours: async (siteName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteName' is not null or undefined
            assertParamExists('cryptoControllerGetSessionHours', 'siteName', siteName);
            const localVarPath = `/api/crypto/get-session-hours/{siteName}`.replace(
                `{${'siteName'}}`,
                encodeURIComponent(String(siteName))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdateActiveLiquidityProviderRequestDto} updateActiveLiquidityProviderRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerUpdateActiveLiquidityProvider: async (
            updateActiveLiquidityProviderRequestDto: UpdateActiveLiquidityProviderRequestDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'updateActiveLiquidityProviderRequestDto' is not null or undefined
            assertParamExists(
                'cryptoControllerUpdateActiveLiquidityProvider',
                'updateActiveLiquidityProviderRequestDto',
                updateActiveLiquidityProviderRequestDto
            );
            const localVarPath = `/api/crypto/update-active-liquidity-provider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updateActiveLiquidityProviderRequestDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdateActivePairsDtoRequestDto} updateActivePairsDtoRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerUpdateActivePairs: async (
            updateActivePairsDtoRequestDto: UpdateActivePairsDtoRequestDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'updateActivePairsDtoRequestDto' is not null or undefined
            assertParamExists(
                'cryptoControllerUpdateActivePairs',
                'updateActivePairsDtoRequestDto',
                updateActivePairsDtoRequestDto
            );
            const localVarPath = `/api/crypto/update-active-pairs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updateActivePairsDtoRequestDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdateMarkupRangeDtoRequestDto} updateMarkupRangeDtoRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerUpdateMarkupsRange: async (
            updateMarkupRangeDtoRequestDto: UpdateMarkupRangeDtoRequestDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'updateMarkupRangeDtoRequestDto' is not null or undefined
            assertParamExists(
                'cryptoControllerUpdateMarkupsRange',
                'updateMarkupRangeDtoRequestDto',
                updateMarkupRangeDtoRequestDto
            );
            const localVarPath = `/api/crypto/update-markups-range`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updateMarkupRangeDtoRequestDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {MaxOrderAmountRequestDto} maxOrderAmountRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerUpdateMaxOrderAmount: async (
            maxOrderAmountRequestDto: MaxOrderAmountRequestDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'maxOrderAmountRequestDto' is not null or undefined
            assertParamExists(
                'cryptoControllerUpdateMaxOrderAmount',
                'maxOrderAmountRequestDto',
                maxOrderAmountRequestDto
            );
            const localVarPath = `/api/crypto/update-max-order-amount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                maxOrderAmountRequestDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {MinOrderAmountRequestDto} minOrderAmountRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerUpdateMinOrderAmount: async (
            minOrderAmountRequestDto: MinOrderAmountRequestDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'minOrderAmountRequestDto' is not null or undefined
            assertParamExists(
                'cryptoControllerUpdateMinOrderAmount',
                'minOrderAmountRequestDto',
                minOrderAmountRequestDto
            );
            const localVarPath = `/api/crypto/update-min-order-amount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                minOrderAmountRequestDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {MinOrderAmountBySymbolRequestDto} minOrderAmountBySymbolRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerUpdateMinOrderAmountBySymbol: async (
            minOrderAmountBySymbolRequestDto: MinOrderAmountBySymbolRequestDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'minOrderAmountBySymbolRequestDto' is not null or undefined
            assertParamExists(
                'cryptoControllerUpdateMinOrderAmountBySymbol',
                'minOrderAmountBySymbolRequestDto',
                minOrderAmountBySymbolRequestDto
            );
            const localVarPath = `/api/crypto/update-min-order-amount-by-symbol`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                minOrderAmountBySymbolRequestDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SessionHoursRequestDto} sessionHoursRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerUpdateSessionHoursDay: async (
            sessionHoursRequestDto: SessionHoursRequestDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'sessionHoursRequestDto' is not null or undefined
            assertParamExists(
                'cryptoControllerUpdateSessionHoursDay',
                'sessionHoursRequestDto',
                sessionHoursRequestDto
            );
            const localVarPath = `/api/crypto/update-session-hours-day`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                sessionHoursRequestDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * CryptoApi - functional programming interface
 * @export
 */
export const CryptoApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = CryptoApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {DeleteMarkupRangeWithSiteNameDto} deleteMarkupRangeWithSiteNameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerDeleteMarkupRange(
            deleteMarkupRangeWithSiteNameDto: DeleteMarkupRangeWithSiteNameDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Markups>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerDeleteMarkupRange(
                deleteMarkupRangeWithSiteNameDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {DeleteMinOrderAmountBySymbolDto} deleteMinOrderAmountBySymbolDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerDeleteMinOrderAmountBySymbol(
            deleteMinOrderAmountBySymbolDto: DeleteMinOrderAmountBySymbolDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimumRangeBySymbolInterface>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerDeleteMinOrderAmountBySymbol(
                deleteMinOrderAmountBySymbolDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerGetActiveLiquidityProvider(
            siteName: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActiveLiquidityProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerGetActiveLiquidityProvider(
                siteName,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerGetActivePairs(
            siteName: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivePairs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerGetActivePairs(siteName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerGetLiquidityProviderSettings(
            siteName: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LiquidityProviderSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerGetLiquidityProviderSettings(
                siteName,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerGetMarkups(
            siteName: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Markups>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerGetMarkups(siteName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerGetMaxOrderAmount(
            siteName: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaxOrderAmount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerGetMaxOrderAmount(
                siteName,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerGetMinOrderAmount(
            siteName: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinOrderAmount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerGetMinOrderAmount(
                siteName,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerGetMinOrderAmountBySymbols(
            siteName: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimumRangeBySymbolInterface>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerGetMinOrderAmountBySymbols(
                siteName,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerGetSessionHours(
            siteName: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionHours>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerGetSessionHours(
                siteName,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UpdateActiveLiquidityProviderRequestDto} updateActiveLiquidityProviderRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerUpdateActiveLiquidityProvider(
            updateActiveLiquidityProviderRequestDto: UpdateActiveLiquidityProviderRequestDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActiveLiquidityProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerUpdateActiveLiquidityProvider(
                updateActiveLiquidityProviderRequestDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UpdateActivePairsDtoRequestDto} updateActivePairsDtoRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerUpdateActivePairs(
            updateActivePairsDtoRequestDto: UpdateActivePairsDtoRequestDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivePairs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerUpdateActivePairs(
                updateActivePairsDtoRequestDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UpdateMarkupRangeDtoRequestDto} updateMarkupRangeDtoRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerUpdateMarkupsRange(
            updateMarkupRangeDtoRequestDto: UpdateMarkupRangeDtoRequestDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Markups>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerUpdateMarkupsRange(
                updateMarkupRangeDtoRequestDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {MaxOrderAmountRequestDto} maxOrderAmountRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerUpdateMaxOrderAmount(
            maxOrderAmountRequestDto: MaxOrderAmountRequestDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaxOrderAmount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerUpdateMaxOrderAmount(
                maxOrderAmountRequestDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {MinOrderAmountRequestDto} minOrderAmountRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerUpdateMinOrderAmount(
            minOrderAmountRequestDto: MinOrderAmountRequestDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinOrderAmount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerUpdateMinOrderAmount(
                minOrderAmountRequestDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {MinOrderAmountBySymbolRequestDto} minOrderAmountBySymbolRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerUpdateMinOrderAmountBySymbol(
            minOrderAmountBySymbolRequestDto: MinOrderAmountBySymbolRequestDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimumRangeBySymbolInterface>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerUpdateMinOrderAmountBySymbol(
                minOrderAmountBySymbolRequestDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {SessionHoursRequestDto} sessionHoursRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerUpdateSessionHoursDay(
            sessionHoursRequestDto: SessionHoursRequestDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionHours>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerUpdateSessionHoursDay(
                sessionHoursRequestDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * CryptoApi - factory interface
 * @export
 */
export const CryptoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CryptoApiFp(configuration);
    return {
        /**
         *
         * @param {DeleteMarkupRangeWithSiteNameDto} deleteMarkupRangeWithSiteNameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerDeleteMarkupRange(
            deleteMarkupRangeWithSiteNameDto: DeleteMarkupRangeWithSiteNameDto,
            options?: any
        ): AxiosPromise<Markups> {
            return localVarFp
                .cryptoControllerDeleteMarkupRange(deleteMarkupRangeWithSiteNameDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DeleteMinOrderAmountBySymbolDto} deleteMinOrderAmountBySymbolDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerDeleteMinOrderAmountBySymbol(
            deleteMinOrderAmountBySymbolDto: DeleteMinOrderAmountBySymbolDto,
            options?: any
        ): AxiosPromise<MinimumRangeBySymbolInterface> {
            return localVarFp
                .cryptoControllerDeleteMinOrderAmountBySymbol(deleteMinOrderAmountBySymbolDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerGetActiveLiquidityProvider(
            siteName: string,
            options?: any
        ): AxiosPromise<ActiveLiquidityProvider> {
            return localVarFp
                .cryptoControllerGetActiveLiquidityProvider(siteName, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerGetActivePairs(siteName: string, options?: any): AxiosPromise<ActivePairs> {
            return localVarFp
                .cryptoControllerGetActivePairs(siteName, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerGetLiquidityProviderSettings(
            siteName: string,
            options?: any
        ): AxiosPromise<LiquidityProviderSettings> {
            return localVarFp
                .cryptoControllerGetLiquidityProviderSettings(siteName, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerGetMarkups(siteName: string, options?: any): AxiosPromise<Markups> {
            return localVarFp.cryptoControllerGetMarkups(siteName, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerGetMaxOrderAmount(siteName: string, options?: any): AxiosPromise<MaxOrderAmount> {
            return localVarFp
                .cryptoControllerGetMaxOrderAmount(siteName, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerGetMinOrderAmount(siteName: string, options?: any): AxiosPromise<MinOrderAmount> {
            return localVarFp
                .cryptoControllerGetMinOrderAmount(siteName, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerGetMinOrderAmountBySymbols(
            siteName: string,
            options?: any
        ): AxiosPromise<MinimumRangeBySymbolInterface> {
            return localVarFp
                .cryptoControllerGetMinOrderAmountBySymbols(siteName, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} siteName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerGetSessionHours(siteName: string, options?: any): AxiosPromise<SessionHours> {
            return localVarFp
                .cryptoControllerGetSessionHours(siteName, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdateActiveLiquidityProviderRequestDto} updateActiveLiquidityProviderRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerUpdateActiveLiquidityProvider(
            updateActiveLiquidityProviderRequestDto: UpdateActiveLiquidityProviderRequestDto,
            options?: any
        ): AxiosPromise<ActiveLiquidityProvider> {
            return localVarFp
                .cryptoControllerUpdateActiveLiquidityProvider(updateActiveLiquidityProviderRequestDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdateActivePairsDtoRequestDto} updateActivePairsDtoRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerUpdateActivePairs(
            updateActivePairsDtoRequestDto: UpdateActivePairsDtoRequestDto,
            options?: any
        ): AxiosPromise<ActivePairs> {
            return localVarFp
                .cryptoControllerUpdateActivePairs(updateActivePairsDtoRequestDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdateMarkupRangeDtoRequestDto} updateMarkupRangeDtoRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerUpdateMarkupsRange(
            updateMarkupRangeDtoRequestDto: UpdateMarkupRangeDtoRequestDto,
            options?: any
        ): AxiosPromise<Markups> {
            return localVarFp
                .cryptoControllerUpdateMarkupsRange(updateMarkupRangeDtoRequestDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MaxOrderAmountRequestDto} maxOrderAmountRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerUpdateMaxOrderAmount(
            maxOrderAmountRequestDto: MaxOrderAmountRequestDto,
            options?: any
        ): AxiosPromise<MaxOrderAmount> {
            return localVarFp
                .cryptoControllerUpdateMaxOrderAmount(maxOrderAmountRequestDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MinOrderAmountRequestDto} minOrderAmountRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerUpdateMinOrderAmount(
            minOrderAmountRequestDto: MinOrderAmountRequestDto,
            options?: any
        ): AxiosPromise<MinOrderAmount> {
            return localVarFp
                .cryptoControllerUpdateMinOrderAmount(minOrderAmountRequestDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MinOrderAmountBySymbolRequestDto} minOrderAmountBySymbolRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerUpdateMinOrderAmountBySymbol(
            minOrderAmountBySymbolRequestDto: MinOrderAmountBySymbolRequestDto,
            options?: any
        ): AxiosPromise<MinimumRangeBySymbolInterface> {
            return localVarFp
                .cryptoControllerUpdateMinOrderAmountBySymbol(minOrderAmountBySymbolRequestDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SessionHoursRequestDto} sessionHoursRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerUpdateSessionHoursDay(
            sessionHoursRequestDto: SessionHoursRequestDto,
            options?: any
        ): AxiosPromise<SessionHours> {
            return localVarFp
                .cryptoControllerUpdateSessionHoursDay(sessionHoursRequestDto, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * CryptoApi - object-oriented interface
 * @export
 * @class CryptoApi
 * @extends {BaseAPI}
 */
export class CryptoApi extends BaseAPI {
    /**
     *
     * @param {DeleteMarkupRangeWithSiteNameDto} deleteMarkupRangeWithSiteNameDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerDeleteMarkupRange(
        deleteMarkupRangeWithSiteNameDto: DeleteMarkupRangeWithSiteNameDto,
        options?: any
    ) {
        return CryptoApiFp(this.configuration)
            .cryptoControllerDeleteMarkupRange(deleteMarkupRangeWithSiteNameDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DeleteMinOrderAmountBySymbolDto} deleteMinOrderAmountBySymbolDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerDeleteMinOrderAmountBySymbol(
        deleteMinOrderAmountBySymbolDto: DeleteMinOrderAmountBySymbolDto,
        options?: any
    ) {
        return CryptoApiFp(this.configuration)
            .cryptoControllerDeleteMinOrderAmountBySymbol(deleteMinOrderAmountBySymbolDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} siteName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerGetActiveLiquidityProvider(siteName: string, options?: any) {
        return CryptoApiFp(this.configuration)
            .cryptoControllerGetActiveLiquidityProvider(siteName, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} siteName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerGetActivePairs(siteName: string, options?: any) {
        return CryptoApiFp(this.configuration)
            .cryptoControllerGetActivePairs(siteName, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} siteName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerGetLiquidityProviderSettings(siteName: string, options?: any) {
        return CryptoApiFp(this.configuration)
            .cryptoControllerGetLiquidityProviderSettings(siteName, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} siteName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerGetMarkups(siteName: string, options?: any) {
        return CryptoApiFp(this.configuration)
            .cryptoControllerGetMarkups(siteName, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} siteName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerGetMaxOrderAmount(siteName: string, options?: any) {
        return CryptoApiFp(this.configuration)
            .cryptoControllerGetMaxOrderAmount(siteName, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} siteName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerGetMinOrderAmount(siteName: string, options?: any) {
        return CryptoApiFp(this.configuration)
            .cryptoControllerGetMinOrderAmount(siteName, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} siteName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerGetMinOrderAmountBySymbols(siteName: string, options?: any) {
        return CryptoApiFp(this.configuration)
            .cryptoControllerGetMinOrderAmountBySymbols(siteName, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} siteName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerGetSessionHours(siteName: string, options?: any) {
        return CryptoApiFp(this.configuration)
            .cryptoControllerGetSessionHours(siteName, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UpdateActiveLiquidityProviderRequestDto} updateActiveLiquidityProviderRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerUpdateActiveLiquidityProvider(
        updateActiveLiquidityProviderRequestDto: UpdateActiveLiquidityProviderRequestDto,
        options?: any
    ) {
        return CryptoApiFp(this.configuration)
            .cryptoControllerUpdateActiveLiquidityProvider(updateActiveLiquidityProviderRequestDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UpdateActivePairsDtoRequestDto} updateActivePairsDtoRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerUpdateActivePairs(
        updateActivePairsDtoRequestDto: UpdateActivePairsDtoRequestDto,
        options?: any
    ) {
        return CryptoApiFp(this.configuration)
            .cryptoControllerUpdateActivePairs(updateActivePairsDtoRequestDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UpdateMarkupRangeDtoRequestDto} updateMarkupRangeDtoRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerUpdateMarkupsRange(
        updateMarkupRangeDtoRequestDto: UpdateMarkupRangeDtoRequestDto,
        options?: any
    ) {
        return CryptoApiFp(this.configuration)
            .cryptoControllerUpdateMarkupsRange(updateMarkupRangeDtoRequestDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MaxOrderAmountRequestDto} maxOrderAmountRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerUpdateMaxOrderAmount(maxOrderAmountRequestDto: MaxOrderAmountRequestDto, options?: any) {
        return CryptoApiFp(this.configuration)
            .cryptoControllerUpdateMaxOrderAmount(maxOrderAmountRequestDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MinOrderAmountRequestDto} minOrderAmountRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerUpdateMinOrderAmount(minOrderAmountRequestDto: MinOrderAmountRequestDto, options?: any) {
        return CryptoApiFp(this.configuration)
            .cryptoControllerUpdateMinOrderAmount(minOrderAmountRequestDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MinOrderAmountBySymbolRequestDto} minOrderAmountBySymbolRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerUpdateMinOrderAmountBySymbol(
        minOrderAmountBySymbolRequestDto: MinOrderAmountBySymbolRequestDto,
        options?: any
    ) {
        return CryptoApiFp(this.configuration)
            .cryptoControllerUpdateMinOrderAmountBySymbol(minOrderAmountBySymbolRequestDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SessionHoursRequestDto} sessionHoursRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerUpdateSessionHoursDay(sessionHoursRequestDto: SessionHoursRequestDto, options?: any) {
        return CryptoApiFp(this.configuration)
            .cryptoControllerUpdateSessionHoursDay(sessionHoursRequestDto, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
