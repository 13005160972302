/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CcsApiKeys } from './ccs-api-keys';

/**
 *
 * @export
 * @interface CcsApiDBI
 */
export class CcsApiDBI {
    /**
     *
     * @type {Array<CcsApiKeys>}
     * @memberof CcsApiDBI
     */
    CCS_APIKEYS?: Array<CcsApiKeys>;
    /**
     *
     * @type {string}
     * @memberof CcsApiDBI
     */
    CCS_CZ_TOKEN?: string;
    /**
     *
     * @type {string}
     * @memberof CcsApiDBI
     */
    CCS_INTERNAL_TOKEN?: string;
    /**
     *
     * @type {string}
     * @memberof CcsApiDBI
     */
    CCS_CP_TOKEN?: string;
    /**
     *
     * @type {number}
     * @memberof CcsApiDBI
     */
    CCS_TRADING_ORDER_MIN_FACTOR?: number;
    /**
     *
     * @type {number}
     * @memberof CcsApiDBI
     */
    CCS_LIMITS_ORDER_PER_DIRECTION?: number;
    /**
     *
     * @type {number}
     * @memberof CcsApiDBI
     */
    CCS_KRAKEN_MAX_FEE_PERCENTAGE?: number;
    /**
     *
     * @type {Array<string>}
     * @memberof CcsApiDBI
     */
    CCS_PROMOTED_PAIRS?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof CcsApiDBI
     */
    CCS_INCLUDE_PAIRS?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof CcsApiDBI
     */
    LXFEED_WEBSOCKET?: string;
    /**
     *
     * @type {number}
     * @memberof CcsApiDBI
     */
    KRAKEN_ORDER_EXPIRE_TIME?: number;
}
