import React from 'react';
import { PageLayoutProps } from './page-layout.scheme';
import useStyles from './page-layout.styles';
import LoaderFullScreen from 'components/LoaderFullScreen';
import Breadcrumb from 'components/Breakcrumb';

const PageLayout: React.FunctionComponent<PageLayoutProps> = (props) => {
    const { fullWidth, fullHeight, fullPageWidth, slimWidth, loading, spacing } = props;

    const classes = useStyles({
        fullWidth,
        fullHeight,
        fullPageWidth,
        slimWidth,
        spacing,
    });
    return (
        <div className={classes.container}>
            <div className={classes.innerContainer}>
                <div className={classes.contentContainer}>
                    <span>
                        <Breadcrumb />
                    </span>
                    <div className={classes.contentSide}>
                        <div className={classes.innerContentContainer}>{props.children}</div>
                    </div>
                </div>
            </div>
            {loading && <LoaderFullScreen />}
        </div>
    );
};

export default PageLayout;
