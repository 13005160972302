import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    root: (props: { disableMarginTop: boolean }) => ({
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.background.default,
        },
        ...(props.disableMarginTop && {
            '& .MuiInputBase-root': {
                marginTop: '0px',
            },
        }),
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            padding: '0px',
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
            padding: '12.5px 12px',
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            paddingLeft: '12.5px',
        },
        '& .MuiChip-label': {
            fontSize: '1.3rem',
        },
    }),
}));
