import { createStyles } from '@material-ui/core';
import { isRtl } from 'helpers/device_helper';

export default createStyles({
    root: {
        padding: '8px 28px',
        textTransform: 'none',
        borderRadius: '8px',
        fontSize: '1.6rem',
        fontWeight: 600,
        '&$disabled': {
            color: '#unknown',
            opacity: '0.5',
        },
    },
    sizeSmall: {
        fontSize: '1.2rem',
        padding: '0px 12px',
    },
    contained: {
        '&$disabled': {
            color: '#unknown',
            backgroundColor: '#unknown',
        },
    },
    outlined: {
        '&$disabled': {
            border: '#unknown',
        },
    },
    text: {
        padding: 0,
        minWidth: 'unset',
    },
    startIcon: {
        marginRight: '8px',
        marginLeft: '-4px',
        ...isRtl({
            marginRight: '-4p',
            marginLeft: '8px',
        }),
    },
    endIcon: {
        marginLeft: '8px',
        marginRight: '-4px',
        ...isRtl({
            marginLeft: '-4p',
            marginRight: '8px',
        }),
    },
});
