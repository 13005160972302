import { createStyles } from '@material-ui/core';

export default createStyles({
    root: {
        fontSize: '1.6rem',
    },
    yearSelected: {
        fontSize: '2rem',
    },
});
