import { makeStyles } from '@material-ui/core';
import { customGap, isLaptop, isTablet } from 'helpers/device_helper';

interface Props {
    fullWidth?: boolean;
    fullHeight?: boolean;
    fullPageWidth?: boolean;
    slimWidth?: boolean;
    spacing?: number;
}

export default makeStyles((theme) => ({
    container: (props: Props) => ({
        height: '100%',
        width: '100%',
    }),
    innerContainer: (props: Props) => ({
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        ...isLaptop({
            gridTemplateColumns: '1fr',
        }),
    }),
    contentContainer: {
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
    },
    contentSide: (props: Props) => ({
        height: '100%',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    innerContentContainer: (props: Props) => ({
        width: props.fullWidth ? '100%' : 'auto',
        height: props.fullHeight ? '100%' : 'auto',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: props.fullPageWidth
            ? '100%'
            : props.slimWidth
            ? `calc(456px + ${theme.spacing(14)}px)`
            : `calc(1024px + ${theme.spacing(14)}px)`,
        padding: `${theme.spacing(7)}px ${theme.spacing(4)}px`,
        ...isTablet({
            padding: `${theme.spacing(7)}px ${theme.spacing(7)}px`,
        }),
        ...customGap(theme.spacing(props.spacing || 5), 'vertical'),
    }),
}));
