export enum ECryptoTypes {
    GET_SESSION_HOURS = '[crypto] get session hours',
    GET_SESSION_HOURS_SUCCESS = '[crypto] get session hours success',
    GET_SESSION_HOURS_FAILED = '[crypto] get session hours failed',

    UPDATE_SESSION_HOURS = '[crypto] update session hours',
    UPDATE_SESSION_HOURS_SUCCESS = '[crypto] update session hours success',
    UPDATE_SESSION_HOURS_FAILED = '[crypto] update session hours failed',

    GET_ACTIVE_PAIRS = '[crypto] get active pairs',
    GET_ACTIVE_PAIRS_SUCCESS = '[crypto] get active pairs success',
    GET_ACTIVE_PAIRS_FAILED = '[crypto] get active pairs failed',

    GET_MARKUP_RANGE = '[crypto] get markup range',
    GET_MARKUP_RANGE_SUCCESS = '[crypto] get markup range success',
    GET_MARKUP_RANGE_FAILED = '[crypto] get markup range failed',

    UPDATE_ACTIVE_PAIRS = '[crypto] update active pairs',
    UPDATE_ACTIVE_PAIRS_SUCCESS = '[crypto] update active pairs success',
    UPDATE_ACTIVE_PAIRS_FAiLED = '[crypto] update active pairs failed',

    UPDATE_MARKUP_RANGE = '[crypto] update markup range',
    UPDATE_MARKUP_RANGE_SUCCESS = '[crypto] update markup range success',
    UPDATE_MARKUP_RANGE_FAiLED = '[crypto] update markup range failed',

    CREATE_NEW_PAIR_MARKUP_RANGES = '[crypto] create new pair markup ranges',
    CREATE_NEW_PAIR_MIN_ORDER_AMOUNT = '[crypto] create new pair markup min order amount',
    GET_MINIMUM_ORDER_AMOUNT = '[crypto] get minimum order amount',
    GET_MINIMUM_ORDER_AMOUNT_SUCCESS = '[crypto] get minimum order amount success',
    GET_MINIMUM_ORDER_AMOUNT_FAILED = '[crypto] get minimum order amount failed',

    UPDATE_MINIMUM_ORDER_AMOUNT = '[crypto] update minimum order amount',
    UPDATE_MINIMUM_ORDER_AMOUNT_SUCCESS = '[crypto] update minimum order amount success',
    UPDATE_MINIMUM_ORDER_AMOUNT_FAILED = '[crypto] update minimum order amount failed',

    GET_MAXIMUM_ORDER_AMOUNT = '[crypto] get maximum order amount',
    GET_MAXIMUM_ORDER_AMOUNT_SUCCESS = '[crypto] get maximum order amount success',
    GET_MAXIMUM_ORDER_AMOUNT_FAILED = '[crypto] get maximum order amount failed',

    UPDATE_MAXIMUM_ORDER_AMOUNT = '[crypto] update maximum order amount',
    UPDATE_MAXIMUM_ORDER_AMOUNT_SUCCESS = '[crypto] update maximum order amount success',
    UPDATE_MAXIMUM_ORDER_AMOUNT_FAILED = '[crypto] update maximum order amount failed',

    GET_LIQUIDITY_PROVIDER_SETTINGS = '[crypto] get liquidity provider settings',
    GET_LIQUIDITY_PROVIDER_SETTINGS_SUCCESS = '[crypto] get liquidity provider settings success',
    GET_LIQUIDITY_PROVIDER_SETTINGS_FAILED = '[crypto] get liquidity provider settings failed',

    GET_ACTIVE_LIQUIDITY_PROVIDER = '[crypto] get active liquidity provider',
    GET_ACTIVE_LIQUIDITY_PROVIDER_SUCCESS = '[crypto] get active liquidity provider success',
    GET_ACTIVE_LIQUIDITY_PROVIDER_FAILED = '[crypto] get active liquidity provider failed',

    UPDATE_ACTIVE_LIQUIDITY_PROVIDER = '[crypto] update active liquidity provider',
    UPDATE_ACTIVE_LIQUIDITY_PROVIDER_SUCCESS = '[crypto] update active liquidity provider success',
    UPDATE_ACTIVE_LIQUIDITY_PROVIDER_FAILED = '[crypto] update active liquidity provider failed',
    UPDATE_MINIMUM_ORDER_AMOUNT_BY_SYMBOL = '[crypto] update minimum order amount by symbol',
    UPDATE_MINIMUM_ORDER_AMOUNT_BY_SYMBOL_SUCCESS = '[crypto] update minimum order amount by symbol success',
    UPDATE_MINIMUM_ORDER_AMOUNT_BY_SYMBOL_FAILED = '[crypto] update minimum order amount by symbol failed',

    GET_MINIMUM_ORDER_AMOUNT_BY_SYMBOL = '[crypto] get minimum order amount by symbol',
    GET_MINIMUM_ORDER_AMOUNT_BY_SYMBOL_SUCCESS = '[crypto] get minimum order amount by symbol success',
    GET_MINIMUM_ORDER_AMOUNT_BY_SYMBOL_FAILED = '[crypto] get minimum order amount by symbol failed',

    DELETE_MIN_ORDER_AMOUNT_BY_SYMBOL = '[crypto] delete minimum order amount by symbol',
    DELETE_MIN_ORDER_AMOUNT_BY_SYMBOL_SUCCESS = '[crypto] delete minimum order amount by symbol success',
    DELETE_MIN_ORDER_AMOUNT_BY_SYMBOL_FAILED = '[crypto] delete minimum order amount by symbol failed',
}
