export enum EUserTypes {
    START_LOADER = '[user] start loader',
    STOP_LOADER = '[user] stop loader',

    GET_USER_DATA_START = '[user] get user data start',
    GET_USER_DATA_SUCCESS = '[user] get user data success',
    GET_USER_DATA_FAILED = '[user] get user data failed',

    GET_SITES_START = '[user] get sites start',
    GET_SITES_SUCCESS = '[user] get sites success',
    GET_SITES_FAILED = '[user] get sites failed',

    GET_DISABLED_SITES_START = '[user] get disabled sites start',
    GET_DISABLED_SITES_SUCCESS = '[user] get disabled sites success',
    GET_DISABLED_SITES_FAILED = '[user] get disabled sites failed',

    CHANGE_SELECTED_SITE = '[user] change selected site',

    GET_SELECTED_SITE_START = '[user] get selected site start',
    GET_SELECTED_SITE_SUCCESS = '[user] get selected site success',
    GET_SELECTED_SITE_FAILED = '[user] get selected site failed',

    GET_SELECTED_SITE_ASSETS_START = '[user] get selected site assets start',
    GET_SELECTED_SITE_ASSETS_SUCCESS = '[user] get selected site assets success',
    GET_SELECTED_SITE_ASSETS_FAILED = '[user] get selected site assets failed',

    UPDATE_SITE_START = '[user] update site start',
    UPDATE_SITE_SUCCESS = '[user] update site success',
    UPDATE_SITE_FAILED = '[user] update site failed',

    CREATE_NEW_SITE_START = '[user] create new site start',
    CREATE_NEW_SITE_SUCCESS = '[user] create new site success',
    CREATE_NEW_SITE_FAILED = '[user] create new site failed',

    GET_SITE_DATA_START = '[user] get site data start',
    GET_SITE_DATA_SUCCESS = '[user] get site data success',
    GET_SITE_DATA_FAILED = '[user] get site data failed',

    GET_ALL_FLAGS = '[user] get all flags',

    DOWNLOAD_EMAIL_TEMPLATES_START = '[user] download email templates start',
    DOWNLOAD_EMAIL_TEMPLATES_SUCCESS = '[user] download email templates success',
    DOWNLOAD_EMAIL_TEMPLATES_FAILED = '[user] download email templates failed',

    GET_CLUSTER_LBS_START = '[user] get cluster lbs start',
    GET_CLUSTER_LBS_SUCCESS = '[user] get cluster lbs success',
    GET_CLUSTER_LBS_FAILED = '[user] get cluster lbs failed',

    GET_AUDIT_HISTORY_START = '[user] get audit history start',
    GET_AUDIT_HISTORY_SUCCESS = '[user] get audit history success',
    GET_AUDIT_HISTORY_FAILED = '[user] get audit history failed',

    GET_CHATBASE_CHAT_HISTORY_START = '[user] get chatBase chat history start',
    GET_CHATBASE_CHAT_HISTORY_SUCCESS = '[user] get chatBase chat history success',
    GET_CHATBASE_CHAT_HISTORY_FAILED = '[user] get chatBase chat history failed ',

    GET_USERS_START = '[user] get users start',
    GET_USERS_SUCCESS = '[user] get users success',
    GET_USERS_FAILED = '[user] get users failed',

    CREATE_USERS_START = '[user] create users start',
    CREATE_USERS_SUCCESS = '[user] create users success',
    CREATE_USERS_FAILED = '[user] create users failed',

    DELETE_USERS_START = '[user] delete users start',
    DELETE_USERS_SUCCESS = '[user] delete users success',
    DELETE_USERS_FAILED = '[user] delete users failed',

    EDIT_USERS_START = '[user] edit users start',
    EDIT_USERS_SUCCESS = '[user] edit users success',
    EDIT_USERS_FAILED = '[user] edit users failed',

    GET_IS_PROXY_BY_CLOUDFLARE_ENABLED = '[user] is proxy by cloudflare enable',

    DELETE_DOMAIN_START = '[user] delete domain start',
    DELETE_DOMAIN_SUCCESS = '[user] delete domain success',
    DELETE_DOMAIN_FAILED = '[user] delete domain failed',

    UPDATE_DOMAIN_START = '[user] update domain start',
    UPDATE_DOMAIN_SUCCESS = '[user] update domain success',
    UPDATE_DOMAIN_FAILED = '[user] update domain failed',
    RESET_UPDATE_DOMAIN_ERROR = '[user] reset update domain error',
}
