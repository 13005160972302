/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CcsApiDBI } from './ccs-api-dbi';
import { ClientEnvDBI } from './client-env-dbi';
import { CrmApiDBI } from './crm-api-dbi';
import { DevOpsApiDBI } from './dev-ops-api-dbi';
import { OtpApiDBI } from './otp-api-dbi';
import { PlatformApiDBI } from './platform-api-dbi';
import { PlatformServerApiDBI } from './platform-server-api-dbi';
import { PropDB } from './prop-db';
import { ServerEnvDBI } from './server-env-dbi';
import { SiteDBI } from './site-dbi';

/**
 *
 * @export
 * @interface CreateSiteRequest
 */
export class CreateSiteRequest {
    /**
     *
     * @type {ServerEnvDBI}
     * @memberof CreateSiteRequest
     */
    server_env: ServerEnvDBI;
    /**
     *
     * @type {ClientEnvDBI}
     * @memberof CreateSiteRequest
     */
    client_env: ClientEnvDBI;
    /**
     *
     * @type {CrmApiDBI}
     * @memberof CreateSiteRequest
     */
    crm_api: CrmApiDBI;
    /**
     *
     * @type {DevOpsApiDBI}
     * @memberof CreateSiteRequest
     */
    devops_api: DevOpsApiDBI;
    /**
     *
     * @type {PlatformApiDBI}
     * @memberof CreateSiteRequest
     */
    platform_api: PlatformApiDBI;
    /**
     *
     * @type {PlatformServerApiDBI}
     * @memberof CreateSiteRequest
     */
    platform_server_api: PlatformServerApiDBI;
    /**
     *
     * @type {OtpApiDBI}
     * @memberof CreateSiteRequest
     */
    otp_api: OtpApiDBI;
    /**
     *
     * @type {SiteDBI}
     * @memberof CreateSiteRequest
     */
    site: SiteDBI;
    /**
     *
     * @type {string}
     * @memberof CreateSiteRequest
     */
    group_name?: string;
    /**
     *
     * @type {CcsApiDBI}
     * @memberof CreateSiteRequest
     */
    ccs_api: CcsApiDBI;
    /**
     *
     * @type {PropDB}
     * @memberof CreateSiteRequest
     */
    prop: PropDB;
}
