export default {
    root: {
        // borderTop: `1px solid ${colors.lightBorder}`,
        // borderBottom: `1px solid ${colors.lightBorder}`,
        '&:nth-child(n+2)': {
            paddingRight: 24,
        },
        '&:nth-child(n+4)': {
            paddingLeft: 0,
        },
    },
    head: {
        height: 44,
        border: 'unset',
    },
    body: {
        height: 52,
        position: 'relative' as 'relative',
        '& > div > p': {
            whiteSpace: 'nowrap' as 'nowrap',
            // textOverflow: 'ellipsis',
            // overflow: 'hidden',
        },
        borderBottom: '8px solid transparent',
        '&:first-child': {
            borderRadius: '8px 0px 0px 8px',
        },
        '&:last-child': {
            borderRadius: '0px 8px 8px 0px',
        },
    },
    paddingCheckbox: {
        width: 'unset',
        '&:first-child': {
            padding: '0 20px 0 12px',
        },
        '&:last-child': {
            paddingRight: '25px',
        },
    },
    footer: {},
};
