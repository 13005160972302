/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PropChallengeConditions } from './prop-challenge-conditions';
import { PropChallengePackageTag } from './prop-challenge-package-tag';

/**
 *
 * @export
 * @interface UpdatePropPackageDto
 */
export class UpdatePropPackageDto {
    /**
     *
     * @type {string}
     * @memberof UpdatePropPackageDto
     */
    namespace: string;
    /**
     *
     * @type {string}
     * @memberof UpdatePropPackageDto
     */
    goalId: string;
    /**
     *
     * @type {string}
     * @memberof UpdatePropPackageDto
     */
    challengeId: string;
    /**
     *
     * @type {string}
     * @memberof UpdatePropPackageDto
     */
    entityId: string;
    /**
     *
     * @type {string}
     * @memberof UpdatePropPackageDto
     */
    entityName: string;
    /**
     *
     * @type {string}
     * @memberof UpdatePropPackageDto
     */
    promoCode?: string;
    /**
     *
     * @type {number}
     * @memberof UpdatePropPackageDto
     */
    retryDiscountPercent?: number;
    /**
     *
     * @type {PropChallengeConditions}
     * @memberof UpdatePropPackageDto
     */
    conditions: PropChallengeConditions;
    /**
     *
     * @type {string}
     * @memberof UpdatePropPackageDto
     */
    type: UpdatePropPackageDtoTypeEnum;
    /**
     *
     * @type {number}
     * @memberof UpdatePropPackageDto
     */
    amount: number;
    /**
     *
     * @type {number}
     * @memberof UpdatePropPackageDto
     */
    funding: number;
    /**
     *
     * @type {PropChallengePackageTag}
     * @memberof UpdatePropPackageDto
     */
    tag?: PropChallengePackageTag;
    /**
     *
     * @type {string}
     * @memberof UpdatePropPackageDto
     */
    packageId: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdatePropPackageDtoTypeEnum {
    Starter = 'starter',
    Standard = 'standard',
    Advanced = 'advanced',
    Expert = 'expert',
    Master = 'master',
    Professional = 'professional',
    Champion = 'champion',
    Elite = 'elite',
    Supreme = 'supreme',
    Ultimate = 'ultimate',
    Legendary = 'legendary',
    Mythical = 'mythical',
    Titan = 'titan',
    Olympian = 'olympian',
    Cosmic = 'cosmic',
    Bronze = 'bronze',
    Silver = 'silver',
    Gold = 'gold',
    Platinum = 'platinum',
    Diamond = 'diamond',
}
