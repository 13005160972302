/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UpdateFullTranslationsDataRequest
 */
export class UpdateFullTranslationsDataRequest {
    /**
     *
     * @type {{ [key: string]: { [key: string]: { [key: string]: string; }; }; }}
     * @memberof UpdateFullTranslationsDataRequest
     */
    content: { [key: string]: { [key: string]: { [key: string]: string } } };
    /**
     *
     * @type {string}
     * @memberof UpdateFullTranslationsDataRequest
     */
    project: UpdateFullTranslationsDataRequestProjectEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateFullTranslationsDataRequestProjectEnum {
    Cz = 'cz',
    Wt = 'wt',
    Et = 'et',
    EtProp = 'et-prop',
    Prop = 'prop',
    Crypto = 'crypto',
}
