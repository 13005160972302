import * as React from 'react';
import { CircularProgress, makeStyles, CircularProgressProps } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        height: 'auto !important',
        color: 'currentColor',
    },
    circleIndeterminate: {
        animation: 'MuiCircularProgress-keyframes-circular-dash 1s ease-in-out infinite',
    },
});

const Loader: React.FunctionComponent<CircularProgressProps> = (props) => {
    const classes = useStyles();
    return (
        <CircularProgress
            {...props}
            classes={{
                root: classes.root,
                circleIndeterminate: classes.circleIndeterminate,
            }}
        />
    );
};

export default Loader;
