import { makeStyles } from '@material-ui/core';
import { isRtl } from 'helpers/device_helper';

interface Props {
    error: boolean;
    inheritColor?: boolean;
    disableSidesMargin?: boolean;
}

export default makeStyles((theme) => ({
    formControlRoot: (props: Props) => ({
        alignItems: 'flex-start',
        ...(props.disableSidesMargin
            ? {
                  marginLeft: '-11px',
                  marginRight: 'unset',
                  ...isRtl({
                      marginLeft: 'unset',
                      marginRight: '-11px',
                  }),
              }
            : {}),
    }),
    label: {
        fontSize: '1.3rem',
        fontWeight: 'normal',
        lineHeight: '2rem',
    },
    checkboxRoot: (props: Props) => ({
        color: props.inheritColor ? 'inherit' : undefined,
        padding: '0px 9px',
        '& svg': {
            fill: props.error ? theme.palette.error.main : 'currentColor',
        },
    }),
    formHelperTextError: {
        '&&': {
            color: theme.palette.error.main,
            backgroundColor: 'transparent',
            padding: 'unset',
            margin: '0',
            marginTop: `${theme.spacing(4)}px`,
            marginLeft: `${theme.spacing(6)}px`,
        },
    },
}));
