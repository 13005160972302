import { Tooltip as TooltipMat, TooltipProps as MatTooltipProps } from '@material-ui/core';
import React from 'react';
import useStyles from './tooltip.styles';

export interface TooltipProps extends MatTooltipProps {}

const Tooltip: React.FunctionComponent<TooltipProps> = (props) => {
    const { children } = props;
    const classes = useStyles();

    return (
        <TooltipMat
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            // title={title}
            arrow
            // placement={placement}
            {...props}
        >
            {children as any}
        </TooltipMat>
    );
};

export default Tooltip;
