/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface CpOptionsDBI
 */
export class CpOptionsDBI {
    /**
     *
     * @type {string}
     * @memberof CpOptionsDBI
     */
    _id?: string;
    /**
     *
     * @type {string}
     * @memberof CpOptionsDBI
     */
    optionName: CpOptionsDBIOptionNameEnum;
    /**
     *
     * @type {object}
     * @memberof CpOptionsDBI
     */
    optionValue: object;
}

/**
 * @export
 * @enum {string}
 */
export enum CpOptionsDBIOptionNameEnum {
    ClusterLbs = 'cluster_lbs',
    General = 'general',
    PaymentIps = 'payment_ips',
    PaymentIpsDeployed = 'payment_ips_deployed',
    Languages = 'languages',
    PropOptions = 'PROP_OPTIONS',
}
