import React from 'react';
import { useTranslation } from 'react-i18next';
import { SideMenuProps, mapDispatchToProps, mapStateToProps, menuLinks } from './side-menu.scheme';
import useStyles from './side-menu.styles';
import MenuItem from './components/MenuItem';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MenuTopSection from './components/MenuTopSection';
import { ThemeOptions } from 'interfaces/main';
import { IconsType } from 'components/Icon';
// import BottomBox from './components/BottomBox';
import classNames from 'classnames';
import { isDesktop } from 'helpers/device_helper';
import { Hidden } from '@material-ui/core';

const SideMenu: React.FunctionComponent<SideMenuProps> = (props) => {
    const { sideMenuState, theme, user_rolePermitted, toggleTheme, logOut, system_toggleSideMenu } = props;
    const classes = useStyles({ sideMenuState });
    const { t } = useTranslation();
    const location = useLocation();

    const handleOnOverlayClick = (e) => {
        if (e?.target?.id === 'overlay') {
            system_toggleSideMenu();
        }
    };

    const handleOnMenuItemClick = (obj?: { logoutClick?: boolean; toogleTheme?: boolean }) => {
        const { logoutClick, toogleTheme } = obj || {};
        if (logoutClick) {
            logOut();
        }
        if (toogleTheme) {
            toggleTheme();
        }
        if (sideMenuState && !isDesktop()) {
            system_toggleSideMenu();
        }
    };

    const checkIfIsActive = (link: string | Array<string> | undefined) => {
        if (!link) return false;
        if (Array.isArray(link)) {
            return link.some((url) => location.pathname.includes(url));
        }
        return location.pathname === link;
    };

    return (
        <div
            className={classNames({
                [classes.overlay]: true,
                [classes.overlay__open]: sideMenuState,
            })}
            id="overlay"
            onClick={handleOnOverlayClick}
        >
            <div
                className={classNames({
                    [classes.menu_container]: true,
                    [classes.menu_container__open]: sideMenuState,
                })}
            >
                <div className={classes.menu_list_wrapper}>
                    <MenuTopSection
                        notificationsAmount={3}
                        menuState={sideMenuState}
                        toggleMenuState={system_toggleSideMenu}
                    />
                </div>
                <div className={classes.menu_list_wrapper}>
                    {menuLinks
                        .filter((linkObject) => {
                            if (!linkObject.roles) return true;
                            return user_rolePermitted(linkObject.roles);
                        })
                        .map((linkObject, linkIndex) => (
                            <Hidden
                                key={`menuItem_${linkIndex}`}
                                {...(linkObject.hidden ? { [linkObject.hidden]: true } : {})}
                            >
                                <MenuItem
                                    onClick={handleOnMenuItemClick}
                                    menuState={sideMenuState}
                                    t={t}
                                    {...linkObject}
                                    active={checkIfIsActive(linkObject.link)}
                                />
                            </Hidden>
                        ))}
                </div>
                <Hidden smUp>
                    <div className={classes.menu_list_wrapper}>
                        <div>
                            <MenuItem
                                menuState={sideMenuState}
                                t={t}
                                t_name={`side_menu.item_names.${theme}_mode`}
                                onClick={() => handleOnMenuItemClick({ toogleTheme: true })}
                                icon={theme === ThemeOptions.DARK ? IconsType.darkModeIcon : IconsType.lightModeIcon}
                            />
                            <MenuItem
                                menuState={sideMenuState}
                                t={t}
                                t_name={'side_menu.item_names.logout'}
                                onClick={() => handleOnMenuItemClick({ logoutClick: true })}
                                icon={IconsType.logout}
                            />
                        </div>
                    </div>
                </Hidden>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SideMenu));
