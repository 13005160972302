/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ActiveLiquidityProvider
 */
export class ActiveLiquidityProvider {
    /**
     *
     * @type {string}
     * @memberof ActiveLiquidityProvider
     */
    value: ActiveLiquidityProviderValueEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ActiveLiquidityProviderValueEnum {
    Bitstamp = 'bitstamp',
    Gemini = 'gemini',
}
