import { makeStyles, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { cssStart } from 'helpers/device_helper';
import Tooltip from 'components/ToolTip';
import { SideMenuItem } from '../side-menu.scheme';

export interface MenuItemProps extends SideMenuItem {
    active?: boolean;
    percent?: string;
    menuState: boolean;
    t: (key: string) => string;
}

interface StyleProps {
    menuState: boolean;
    percentState: boolean;
    percent?: string;
}

const useStyles = makeStyles((theme) => ({
    menu_list_item: (props: StyleProps) => ({
        flex: '1 1 auto',
        minHeight: '0px',
        fontSize: '1.4rem',
        fontWeight: 600,
        height: '44px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'transparent',
        padding: `0px ${theme.spacing(2)}px`,
        borderRadius: '8px',
        justifyContent: props.menuState ? 'unset' : 'center',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
            '& p': {
                color: theme.palette.primary.contrastText,
            },
        },
        ...(props.percentState && {
            height: '56px',
            border: `1px solid ${theme.palette.action.disabledBackground}`,
        }),
        ...(!props.menuState && {
            maxWidth: 'calc(64px - 16px)',
        }),
    }),
    menu_list_item__active: (props) => ({
        color: theme.palette.primary.contrastText,
        position: 'relative',
        backgroundColor: theme.palette.primary.main,

        '& p': {
            color: theme.palette.primary.contrastText,
        },
    }),
    menu_list_item__menu_close__kyc: (props: StyleProps) => ({
        height: 'auto',
        padding: `${theme.spacing(1)}px`,
        flexDirection: 'column',
        backgroundColor: theme.palette.secondary.main,
        border: 'unset',
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            '& > div:first-child': {
                backgroundColor: theme.palette.secondary.light,
            },
        },
    }),
    endText: {
        color: theme.palette.secondary.main,
    },
    endText__close: {
        width: '100%',
        height: '2.6rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.secondary.main,
        borderRadius: '8px',
    },
    content_wrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        ...cssStart(theme.direction, 'padding', `${theme.spacing(3)}px`),
    },
    icon_wrapper: (props: StyleProps) => ({
        width: 'calc(47px - 24px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '4rem',
        minWidth: '4rem',
        ...(props.percentState && {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            borderRadius: '8px',
        }),
    }),
    text_wrapper: (props: StyleProps) => ({
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        ...(props.percentState && {
            fontWeight: 'bold',
            color: theme.palette.text.default,
        }),
    }),
    progressBar: (props: StyleProps) => ({
        width: '100%',
        height: '5px',
        background: theme.palette.default.dark,
        overflow: 'hidden',
        borderRadius: '8px',
        marginTop: '4px',
        position: 'relative',
        '&:after': {
            content: "''",
            position: 'absolute',
            left: 0,
            height: '5px',
            width: `${props.percent}%`,
            background: theme.palette.secondary.main,
        },
    }),
}));

const MenuItem: React.FunctionComponent<MenuItemProps> = (props) => {
    const { icon, active, t_name, link, percent, menuState, t, onClick } = props;
    const classes = useStyles({ menuState, percentState: Boolean(percent), percent: percent });
    const theme = useTheme();

    const handleOnClick = () => {
        if (onClick) onClick();
    };

    const InnerComponent = () => (
        <Tooltip title={!menuState ? t(t_name) : ''} placement={theme.direction === 'rtl' ? 'left' : 'right'}>
            <div
                className={classNames({
                    [classes.menu_list_item]: true,
                    [classes.menu_list_item__active]: active,
                    [classes.menu_list_item__menu_close__kyc]: !menuState && Boolean(percent),
                })}
                onClick={handleOnClick}
            >
                <div className={classes.icon_wrapper}>
                    <Icon name={icon} />
                </div>
                {menuState && (
                    <div className={classes.content_wrapper}>
                        <div className={classes.text_wrapper}>
                            <Typography component="p" variant="inherit">
                                {t(t_name)}
                            </Typography>
                            {Boolean(percent) && <div className={classes.endText}>{percent}%</div>}
                        </div>
                        {Boolean(percent) && <div className={classes.progressBar} />}
                    </div>
                )}
                {!menuState && Boolean(percent) && (
                    <div className={classNames({ [classes.endText]: true, [classes.endText__close]: true })}>
                        {percent}%
                    </div>
                )}
            </div>
        </Tooltip>
    );

    if (link) {
        return (
            <Link to={link}>
                <InnerComponent />
            </Link>
        );
    }

    return <InnerComponent />;
};

export default MenuItem;
