import React from 'react';
import { ButtonProps as ButtonMatProps } from '@material-ui/core';
import Icon, { IconsType } from 'components/Icon';
import Loader from 'components/Loader';
import ButtonMat from './styles';

export interface ButtonProps extends ButtonMatProps {
    loading?: boolean;
    startIcon?: IconsType;
    endIcon?: IconsType;
}

const Button: React.FunctionComponent<ButtonProps> = (props) => {
    const { loading, startIcon, endIcon, disabled, disableRipple = true, ...rest } = props;

    const getStartIcon = () => {
        return startIcon ? <Icon name={startIcon} /> : null;
    };

    const getEndIcon = () => {
        if (loading) return <Loader size={15} />;
        return endIcon ? <Icon name={endIcon} /> : null;
    };

    return (
        <ButtonMat
            disabled={disabled || loading}
            disableRipple={disableRipple}
            disableElevation
            {...rest}
            startIcon={getStartIcon()}
            endIcon={getEndIcon()}
        />
    );
};

export default Button;
