import { createStyles } from '@material-ui/core';
import palette from 'muiTheme/config/palette';

export default createStyles({
    root: {
        marginTop: '2.4rem',
        '&$error': {
            color: palette.dark.error.main,
        },
        '& > svg': {
            opacity: 0.42,
        },
        '&:hover': {
            '& > svg': {
                opacity: 1,
            },
        },
        '&$focused': {
            '& > svg': {
                opacity: 1,
            },
        },
    },
    input: {
        fontSize: '1.6rem',
        fontWeight: 'bold',
    },
    inputAdornedStart: {
        paddingInlineStart: '10px',
    },
});
