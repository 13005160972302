import { makeStyles } from '@material-ui/core';
import { customGap } from 'helpers/device_helper';

interface Props {
    fullWidth?: boolean;
    disableMarginTop?: boolean;
    slim?: boolean;
}

export default makeStyles((theme) => ({
    placeholder: {
        opacity: 0.42,
    },
    formControlRoot: (props: Props) => ({
        width: props.fullWidth ? '100%' : 'auto',
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.background.default,
            minHeight: props.slim ? '2.4rem' : '4.4rem',
            marginTop: props.disableMarginTop ? 'unset' : '2.4rem',
        },
    }),
    menuPaper: {
        marginTop: `${theme.spacing(2)}px`,
        borderRadius: '8px',
        maxHeight: '212px',
    },
    menuList: {
        padding: `${theme.spacing(2)}px`,
        ...customGap(theme.spacing(1), 'vertical'),
        '& .MuiMenuItem-root': {
            borderRadius: '8px',
            padding: '8px',
            fontWeight: 'bold',
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
            },
        },
        '& .Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
    select: (props: Props) => ({
        padding: props.slim ? '4px 12px' : '11px 12px',
        fontSize: props.slim ? '12px' : '16px',
    }),
}));
