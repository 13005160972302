import { ThemeOptions } from 'interfaces/main';
import { system_toggleTheme } from 'store/system/actions';
import { system_theme } from 'store/system/selectors';

export interface ThemeSwitchProps {
    theme: ThemeOptions;
    toggleTheme: () => void;
}

export const mapStateToProps = (state) => ({
    theme: system_theme(state),
});

export const mapDispatchToProps = {
    toggleTheme: system_toggleTheme,
};
