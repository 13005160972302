import { makeStyles } from '@material-ui/core';
import { isRtl } from 'helpers/device_helper';

export default makeStyles({
    backBtn: {
        ...isRtl({
            transform: 'rotate(180deg)',
        }),
    },
});
