import React from 'react';
import { Select as SelectMat, MenuItem, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import { isMobileDevice, isRtl } from 'helpers/device_helper';
import Loader from 'components/Loader';
import useStyles from './styles';
import Icon, { IconsType } from 'components/Icon';
import Checkbox from '../Checkbox';

export interface SelectProps {
    variant?: 'standard' | 'outlined' | 'filled';
    id?: string;
    label?: string;
    value?: string | number | Array<string> | Array<number>;
    options: Array<{ value: string | number; label: string | number | React.ReactText; selectedLabel?: string }>;
    startIcon?: IconsType;
    endIcon?: IconsType;
    loading?: boolean;
    placeholder?: string;
    color?: 'primary' | 'secondary';
    multiple?: boolean;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    fullWidth?: boolean;
    disableMarginTop?: boolean;
    defaultValue?: string | number | Array<string> | Array<number>;
    slim?: boolean;
    inputRef?: (el: any) => void;
    onChange?: (value: any) => void;
    containerClassName?: string;
}

const Select: React.FunctionComponent<SelectProps> = (props) => {
    const {
        id,
        label,
        value,
        options,
        variant = 'outlined',
        startIcon,
        endIcon,
        color,
        loading,
        multiple,
        placeholder,
        disabled,
        error,
        helperText,
        fullWidth,
        disableMarginTop,
        defaultValue,
        slim,
        inputRef,
        onChange,
        containerClassName,
    } = props;

    const classes = useStyles({ fullWidth, disableMarginTop, slim });

    const [selectValue, setSelectValue] = React.useState<string | number | Array<string> | Array<number>>(() => {
        if (value) return value;
        return multiple ? [] : '';
    });

    const handleSelectChange = (
        e: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        let newValue: any = e.target.value;
        setSelectValue(newValue);
        onChange && onChange(newValue);
    };

    const handleChangeMultipleNative = (event: React.ChangeEvent<{ value: unknown }>) => {
        const { options } = event.target as HTMLSelectElement;
        const value: string[] = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        setSelectValue(value);
        onChange && onChange(value);
    };

    React.useEffect(() => {
        if (defaultValue) {
            setSelectValue(defaultValue);
        }
        //eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (value) {
            setSelectValue(value);
        }
    }, [value]);

    const getEndAdorment = () => {
        if (loading) return <Loader size={15} />;
        return endIcon ? <Icon name={endIcon} /> : null;
    };

    const getStartAdorment = () => (startIcon ? <Icon name={startIcon} /> : null);

    const Placeholder = () => (
        <span id="placeholder" className={classes.placeholder}>
            {placeholder}
        </span>
    );

    return (
        <FormControl
            classes={{ root: `${classes.formControlRoot} ${containerClassName}` }}
            variant={variant}
            error={error}
        >
            {label && (
                <div>
                    <InputLabel error={false} shrink={true} id={`select-${id}-label`}>
                        {label}
                    </InputLabel>
                </div>
            )}
            <SelectMat
                color={color}
                inputRef={inputRef}
                disabled={disabled || loading}
                multiple={multiple}
                native={isMobileDevice()}
                className={containerClassName}
                classes={{ select: `${classes.select} ${containerClassName}`, root: ` ${containerClassName}` }}
                MenuProps={{
                    classes: { list: classes.menuList, paper: classes.menuPaper },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: isRtl() ? 'right' : 'left',
                    },
                }}
                IconComponent={loading ? () => null : (args) => <Icon {...args} name={IconsType.chevron_down} />}
                labelId={`select-${id}-label`}
                id={`select-${id}`}
                value={selectValue}
                onChange={isMobileDevice() && multiple ? handleChangeMultipleNative : handleSelectChange}
                startAdornment={getStartAdorment()}
                endAdornment={getEndAdorment()}
                displayEmpty
                renderValue={(selected) => {
                    if (multiple && Array.isArray(selected)) {
                        const selectedObject = selected
                            .map((item) => {
                                const itemFound = options.find((option) => option.value === item);
                                return itemFound?.selectedLabel || itemFound?.label || '';
                            })
                            .join(', ');
                        return selected.length === 0 ? <Placeholder /> : selectedObject;
                    }
                    const selectedItem = options.find((option) => option.value === selected);
                    return selected === '' ? <Placeholder /> : selectedItem?.selectedLabel || selectedItem?.label;
                }}
            >
                {placeholder ? (
                    isMobileDevice() ? (
                        <option style={{ display: 'none' }} disabled value={''}>
                            {placeholder}
                        </option>
                    ) : (
                        <MenuItem style={{ display: 'none' }} disabled value={''}>
                            <Placeholder />
                        </MenuItem>
                    )
                ) : isMobileDevice() ? (
                    <option disabled hidden></option>
                ) : null}
                {options.map((option, index) =>
                    isMobileDevice() ? (
                        <option key={`select-option-${id}-${index}`} value={option.value}>
                            {option.label}
                        </option>
                    ) : (
                        <MenuItem key={`select-option-${id}-${index}`} value={option.value}>
                            {multiple && (
                                <Checkbox
                                    color="default"
                                    inheritColor
                                    disableSidesMargin
                                    checked={
                                        Array.isArray(selectValue) &&
                                        selectValue.some((select) => select === option.value)
                                    }
                                />
                            )}
                            {option.label}
                        </MenuItem>
                    )
                )}
            </SelectMat>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default Select;
