// import { colors, font } from '../../../config';

export default {
    // root: {
    //     height: '55px',
    //     '&:last-child': {
    //         padding: '0 15px',
    //     },
    // },
    // caption: {
    //     // fontSize: font.s16,
    //     // fontWeight: font.light,
    //     fontSize: '16px',
    // },
    // selectRoot: {
    //     backgroundColor: 'transparent',
    //     padding: 0,
    //     marginLeft: 0,
    //     marginRight: 20,
    // },
    // selectIcon: {
    //     '&&': {
    //         '& > path': {
    //             // fill: colors.black,
    //         },
    //     },
    // },
    // select: {
    //     // color: colors.black,
    //     // fontWeight: font.light,
    //     // fontSize: font.s16,
    //     fontSize: '16px',
    //     '&&&': {
    //         paddingRight: '15px',
    //         paddingLeft: '12px',
    //     },
    // },
    // actions: {
    //     marginLeft: '12px',
    //     // color: colors.boulder,
    //     '& svg': {
    //         height: '3rem',
    //         width: '3rem',
    //     },
    // },
    input: {
        marginTop: 0,
        // marginRight: '8px'
    },
};
