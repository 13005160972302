/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface Epay
 */
export class Epay {
    /**
     *
     * @type {string}
     * @memberof Epay
     */
    _id: string;
    /**
     *
     * @type {string}
     * @memberof Epay
     */
    namespace: string;
    /**
     *
     * @type {string}
     * @memberof Epay
     */
    type: string;
    /**
     *
     * @type {string}
     * @memberof Epay
     */
    merchantId: string;
    /**
     *
     * @type {string}
     * @memberof Epay
     */
    secretKey: string;
    /**
     *
     * @type {boolean}
     * @memberof Epay
     */
    sandbox: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Epay
     */
    autoApprove: boolean;
}
