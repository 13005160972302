import { EOptionTypes } from 'store/option/actionsTypes';
import { OptionReducerState } from 'store/option/interfaces';

const initialState: OptionReducerState = {
    paymentIps: undefined,
    paymentIpsLoading: false,
    deployPaymentIpsLoading: false,
    languagesLoading: false,
    languages: undefined,
};

const optionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case EOptionTypes.GET_PAYMENT_IPS_START: {
            return {
                ...state,
                paymentIpsLoading: true,
            };
        }
        case EOptionTypes.GET_PAYMENT_IPS_SUCCESS: {
            return {
                ...state,
                paymentIps: payload,
                paymentIpsLoading: false,
            };
        }
        case EOptionTypes.GET_PAYMENT_IPS_FAILED: {
            return {
                ...state,
                paymentIpsLoading: false,
            };
        }
        case EOptionTypes.CREATE_PAYMENT_IPS_START: {
            return {
                ...state,
                paymentIpsLoading: true,
            };
        }
        case EOptionTypes.CREATE_PAYMENT_IPS_SUCCESS: {
            return {
                ...state,
                paymentIps: [...(state?.paymentIps || []), payload],
                paymentIpsLoading: false,
            };
        }
        case EOptionTypes.CREATE_PAYMENT_IPS_FAILED: {
            return {
                ...state,
                paymentIpsLoading: false,
            };
        }
        case EOptionTypes.DELETE_PAYMENT_IPS_START: {
            return {
                ...state,
                paymentIpsLoading: true,
            };
        }
        case EOptionTypes.DELETE_PAYMENT_IPS_SUCCESS: {
            return {
                ...state,
                paymentIps: state.paymentIps?.filter((item) => item._id !== payload._id),
                paymentIpsLoading: false,
            };
        }
        case EOptionTypes.DELETE_PAYMENT_IPS_FAILED: {
            return {
                ...state,
                paymentIpsLoading: false,
            };
        }
        case EOptionTypes.DEPLOY_PAYMENT_IPS_START: {
            return {
                ...state,
                deployPaymentIpsLoading: true,
            };
        }
        case EOptionTypes.DEPLOY_PAYMENT_IPS_SUCCESS: {
            return {
                ...state,
                deployPaymentIpsLoading: false,
            };
        }
        case EOptionTypes.DEPLOY_PAYMENT_IPS_FAILED: {
            return {
                ...state,
                deployPaymentIpsLoading: false,
            };
        }
        case EOptionTypes.GET_LANGUAGES_START: {
            return {
                ...state,
                languagesLoading: true,
            };
        }
        case EOptionTypes.GET_LANGUAGES_SUCCESS: {
            return {
                ...state,
                languages: payload,
                languagesLoading: false,
            };
        }
        case EOptionTypes.GET_LANGUAGES_FAILED: {
            return {
                ...state,
                languagesLoading: false,
            };
        }
        case EOptionTypes.CREATE_LANGUAGES_START: {
            return {
                ...state,
                languagesLoading: true,
            };
        }
        case EOptionTypes.CREATE_LANGUAGES_SUCCESS: {
            return {
                ...state,
                languages: [...(state?.languages || []), payload],
                languagesLoading: false,
            };
        }
        case EOptionTypes.CREATE_LANGUAGES_FAILED: {
            return {
                ...state,
                languagesLoading: false,
            };
        }
        case EOptionTypes.DELETE_LANGUAGES_START: {
            return {
                ...state,
                languagesLoading: true,
            };
        }
        case EOptionTypes.DELETE_LANGUAGES_SUCCESS: {
            return {
                ...state,
                languages: state.languages?.filter((item) => item._id !== payload._id),
                languagesLoading: false,
            };
        }
        case EOptionTypes.DELETE_LANGUAGES_FAILED: {
            return {
                ...state,
                languagesLoading: false,
            };
        }
        case EOptionTypes.EDIT_LANGUAGES_START: {
            return {
                ...state,
                languagesLoading: true,
            };
        }
        case EOptionTypes.EDIT_LANGUAGES_SUCCESS: {
            return {
                ...state,
                languages: state.languages?.map((language) => {
                    if (language._id === payload._id) {
                        return { ...language, ...payload };
                    }
                    return language;
                }),
                languagesLoading: false,
            };
        }
        case EOptionTypes.EDIT_LANGUAGES_FAILED: {
            return {
                ...state,
                languagesLoading: false,
            };
        }

        default:
            return state;
    }
};

export default optionReducer;
