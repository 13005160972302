import { createStyles } from '@material-ui/core';
import { isRtl } from 'helpers/device_helper';

export default createStyles({
    root: {
        fontSize: '1.2rem',
    },
    shrink: {
        '&&&': {
            transform: 'translate(0, 1.5px)',
        },
    },
    formControl: {
        left: 0,
        right: 'unset',
        ...isRtl({
            left: 'unset',
            right: 0,
        }),
    },
});
