import { createStyles } from '@material-ui/core';

export default createStyles({
    icon: {
        transition: 'all 200ms linear',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    selectMenu: {
        lineHeight: 'initial',
        padding: '11px 12px',
    },
    iconOpen: {
        transform: 'rotate(-180deg) translateY(50%)',
    },
    select: {
        '&:focus': {
            backgroundColor: 'inherit',
        },
    },
});
