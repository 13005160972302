/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface CreateNewLanguageRequest
 */
export class CreateNewLanguageRequest {
    /**
     *
     * @type {string}
     * @memberof CreateNewLanguageRequest
     */
    newLanguage: string;
    /**
     *
     * @type {string}
     * @memberof CreateNewLanguageRequest
     */
    project: CreateNewLanguageRequestProjectEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CreateNewLanguageRequestProjectEnum {
    Cz = 'cz',
    Wt = 'wt',
    Et = 'et',
    EtProp = 'et-prop',
    Prop = 'prop',
    Crypto = 'crypto',
}
