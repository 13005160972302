import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import systemReducer from './system/reducer';
import { SystemReducerState } from './system/interfaces';

import authReducer from './auth/reducer';
import { AuthReducerState } from './auth/interfaces';

import userReducer from './user/reducer';
import { UserReducerState } from './user/interfaces';

import TranslationsReducer from './translations/reducer';
import { TranslationsReducerState } from './translations/interfaces';

import { BrandManagementReducerState } from './brandManagement/interfaces';
import brandManagementReducer from './brandManagement/reducer';

import optionReducer from 'store/option/reducer';
import { OptionReducerState } from './option/interfaces';
import { CryptoReducerState } from './crypto/interfaces';
import cryptoReducer from './crypto/reducer';
import { PropReducerState } from './props/interface';
import propReducer from './props/reducer';

export interface RootState {
    system: SystemReducerState;
    auth: AuthReducerState;
    user: UserReducerState;
    translations: TranslationsReducerState;
    brandManagement: BrandManagementReducerState;
    option: OptionReducerState;
    crypto: CryptoReducerState;
    prop: PropReducerState;
}

const rootReducer = combineReducers({
    system: systemReducer,
    auth: authReducer,
    user: userReducer,
    translations: TranslationsReducer,
    brandManagement: brandManagementReducer,
    option: optionReducer,
    crypto: cryptoReducer,
    prop: propReducer,
});

const middleWares = composeWithDevTools(applyMiddleware(thunk));

export default createStore(rootReducer, middleWares);
