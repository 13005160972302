export enum ETranslationsTypes {
    GET_LANGUAGES_AND_TYPES_START = '[languages] get languages and types start',
    GET_LANGUAGES_AND_TYPES_SUCCESS = '[languages] get languages and types success',
    GET_LANGUAGES_AND_TYPES_FAILED = '[languages] get languages and types failed',

    SET_SELECTED_LANGUAGE = '[languages] set selected language',
    SET_SELECTED_FILE = '[languages] set selected file type',

    GET_SELECTED_FILE_START = '[languages] get selected file content start',
    GET_SELECTED_FILE_SUCCESS = '[languages] get selected file content success',
    GET_SELECTED_FILE_FAILED = '[languages] get selected file content failed',

    GET_FULL_FILES_START = '[languages] get full files content start',
    GET_FULL_FILES_SUCCESS = '[languages] get full files content success',
    GET_FULL_FILES_FAILED = '[languages] get full files content failed',

    UPDATE_SELECTED_FILE_START = '[languages] update selected file content start',
    UPDATE_SELECTED_FILE_SUCCESS = '[languages] update selected file content success',
    UPDATE_SELECTED_FILE_FAILED = '[languages] update selected file content failed',

    UPDATE_FULL_FILES_START = '[languages] update full files content start',
    UPDATE_FULL_FILES_SUCCESS = '[languages] update full files content success',
    UPDATE_FULL_FILES_FAILED = '[languages] update full files content failed',

    CREATE_NEW_LANGUAGE_START = '[languages] create new language start',
    CREATE_NEW_LANGUAGE_SUCCESS = '[languages] create new language success',
    CREATE_NEW_LANGUAGE_FAILED = '[languages] create new language failed',
}
