// import { colors } from 'muiTheme/config';
// import { fadeColor } from 'helpers/fadeColor';

export default {
    root: {
        '&$selected': {},
        '&$hover:hover': {
            backgroundColor: 'transparent',
            '&$selected': {
                // backgroundColor: fadeColor(colors.tableHover, 10),
            },
        },
    },
};
