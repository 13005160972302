import React from 'react';
import { connect } from 'react-redux';

import { HeaderProps, mapDispatchToProps, mapStateToProps } from './header.scheme';
import PrivateHeader from './PrivateHeader';
import PublicHeader from './PublicHeader';

const Header: React.FunctionComponent<HeaderProps> = (props) => {
    const { hasJwtToken } = props;

    if (hasJwtToken) {
        return <PrivateHeader />;
    }
    return <PublicHeader />;
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Header));
