import React from 'react';
import { SiteSelectProps, mapDispatchToProps, mapStateToProps } from './site-select.scheme';
import { ClickAwayListener, Tooltip } from '@material-ui/core';
import Icon, { IconsType } from 'components/Icon';
import { connect } from 'react-redux';
import classNames from 'classnames';
import useStyles from './site-select.styles';
import { useTranslation } from 'react-i18next';
import Typography from 'components/Typography';
import { Link } from 'react-router-dom';
import { Pages } from 'interfaces/main';
import TextField from 'components/Inputs/TextField';

const SiteSelect: React.FunctionComponent<SiteSelectProps> = (props) => {
    const { selectedSite, sites, user_changeSelectedSite, user_getSites } = props;

    const [menuState, setMenuState] = React.useState<boolean>(false);
    const { t } = useTranslation();
    const classes = useStyles();

    const [siteFilter, setSiteFilter] = React.useState<string>('');

    React.useEffect(() => {
        user_getSites();
    }, [user_getSites]);

    const toggleMenuState = () => setMenuState(!menuState);

    const onClickAway = () => {
        if (!menuState) return;
        setMenuState(false);
    };

    const handleOnRowClick = (site_name: string) => {
        toggleMenuState();
        setSiteFilter('');
        user_changeSelectedSite(site_name);
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.detailsWrapper}>
                <div className={classes.detailsItem}>
                    <Typography variant="body2" classes={{ root: classes.lightText }}>
                        {selectedSite}
                    </Typography>
                </div>
            </div>
            <ClickAwayListener onClickAway={onClickAway}>
                <div>
                    <Tooltip
                        arrow
                        open={menuState}
                        placement="bottom-end"
                        classes={{
                            tooltip: classes.toolTipPaper,
                            arrow: classes.toolTipArrow,
                            popper: classes.toolTipPopper,
                            tooltipPlacementBottom: classes.toolTipTop,
                        }}
                        title={
                            <React.Fragment>
                                <div className={classes.titleWrapper}>
                                    <Typography variant="body1" classes={{ root: classes.boldText }}>
                                        {t('site_select_menu.title')}
                                    </Typography>
                                </div>
                                <div className={classes.seperator} />
                                <div className={classes.filter}>
                                    <TextField
                                        disableMarginTop
                                        fullWidth
                                        autoFocus
                                        onChange={(e) => setSiteFilter(e.target.value)}
                                    />
                                </div>
                                <div className={classes.seperator} />
                                <div className={classes.rowsWrapper}>
                                    {sites
                                        .filter((site) => site.toLowerCase().includes(siteFilter))
                                        .sort()
                                        .map((site) => (
                                            <div
                                                key={site}
                                                className={classNames({
                                                    [classes.row]: true,
                                                    [classes.row__active]: site === selectedSite,
                                                })}
                                                onClick={() => handleOnRowClick(site)}
                                            >
                                                <div className={classes.rowContect}>
                                                    <Typography
                                                        variant="body1"
                                                        classes={{
                                                            root: classes.boldText,
                                                        }}
                                                    >
                                                        {site}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        classes={{
                                                            root: classes.lightText,
                                                        }}
                                                    >
                                                        {site}
                                                    </Typography>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                                <div className={classes.seperator} />
                                <div className={classes.rowsWrapper}>
                                    <Link to={`/${Pages.NEW_SITE}`}>
                                        <div className={classes.row}>
                                            <div className={classes.rowContect}>
                                                <Typography variant="body1">
                                                    {t('site_select_menu.create_new')}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </React.Fragment>
                        }
                    >
                        <div
                            className={classNames({
                                [classes.iconBtn]: true,
                                [classes.iconBtn__active]: menuState,
                            })}
                            onClick={toggleMenuState}
                        >
                            <Icon name={IconsType.chevron_down} />
                        </div>
                    </Tooltip>
                </div>
            </ClickAwayListener>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SiteSelect));
