/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface S3File
 */
export class S3File {
    /**
     *
     * @type {string}
     * @memberof S3File
     */
    key?: string;
    /**
     *
     * @type {string}
     * @memberof S3File
     */
    folderPrefix?: string;
    /**
     *
     * @type {number}
     * @memberof S3File
     */
    size?: number;
    /**
     *
     * @type {string}
     * @memberof S3File
     */
    lastModified?: string;
    /**
     *
     * @type {string}
     * @memberof S3File
     */
    base64?: string;
    /**
     *
     * @type {string}
     * @memberof S3File
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof S3File
     */
    mimeType?: string;
}
