/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateNewLanguageRequest } from '../models';
// @ts-ignore
import { CreateNewLanguageResponse } from '../models';
// @ts-ignore
import { GetFileContentResponse } from '../models';
// @ts-ignore
import { GetTranslationLangsAndTypesResponse } from '../models';
// @ts-ignore
import { GetTranslationsDataRequest } from '../models';
// @ts-ignore
import { GetTranslationsDataResponse } from '../models';
// @ts-ignore
import { UpdateFullTranslationsDataRequest } from '../models';
// @ts-ignore
import { UpdateFullTranslationsDataResponse } from '../models';
// @ts-ignore
import { UpdateTranslationsDataRequest } from '../models';
// @ts-ignore
import { UpdateTranslationsDataResponse } from '../models';
/**
 * TranslationsApi - axios parameter creator
 * @export
 */
export const TranslationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CreateNewLanguageRequest} createNewLanguageRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tranlationsControllerCreateNewLanguage: async (
            createNewLanguageRequest: CreateNewLanguageRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'createNewLanguageRequest' is not null or undefined
            assertParamExists(
                'tranlationsControllerCreateNewLanguage',
                'createNewLanguageRequest',
                createNewLanguageRequest
            );
            const localVarPath = `/api/translations/CreateNewLanguage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createNewLanguageRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} lang
         * @param {string} fileType
         * @param {'cz' | 'wt' | 'et' | 'et-prop' | 'prop' | 'crypto'} project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tranlationsControllerGetFileContent: async (
            lang: string,
            fileType: string,
            project: 'cz' | 'wt' | 'et' | 'et-prop' | 'prop' | 'crypto',
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('tranlationsControllerGetFileContent', 'lang', lang);
            // verify required parameter 'fileType' is not null or undefined
            assertParamExists('tranlationsControllerGetFileContent', 'fileType', fileType);
            // verify required parameter 'project' is not null or undefined
            assertParamExists('tranlationsControllerGetFileContent', 'project', project);
            const localVarPath = `/api/translations/GetFileContent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (fileType !== undefined) {
                localVarQueryParameter['fileType'] = fileType;
            }

            if (project !== undefined) {
                localVarQueryParameter['project'] = project;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tranlationsControllerGetTranslationLangsAndTypes: async (
            project: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'project' is not null or undefined
            assertParamExists('tranlationsControllerGetTranslationLangsAndTypes', 'project', project);
            const localVarPath = `/api/translations/GetTranslationLangsAndTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (project !== undefined) {
                localVarQueryParameter['project'] = project;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {GetTranslationsDataRequest} getTranslationsDataRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tranlationsControllerGetTranslationsData: async (
            getTranslationsDataRequest: GetTranslationsDataRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'getTranslationsDataRequest' is not null or undefined
            assertParamExists(
                'tranlationsControllerGetTranslationsData',
                'getTranslationsDataRequest',
                getTranslationsDataRequest
            );
            const localVarPath = `/api/translations/GetTranslationsData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                getTranslationsDataRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tranlationsControllerGetTranslationsWebhook: async (
            project: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'project' is not null or undefined
            assertParamExists('tranlationsControllerGetTranslationsWebhook', 'project', project);
            const localVarPath = `/api/translations/GetTranslationsWebhook/{project}`.replace(
                `{${'project'}}`,
                encodeURIComponent(String(project))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdateFullTranslationsDataRequest} updateFullTranslationsDataRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tranlationsControllerUpdateFullTranslationsData: async (
            updateFullTranslationsDataRequest: UpdateFullTranslationsDataRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'updateFullTranslationsDataRequest' is not null or undefined
            assertParamExists(
                'tranlationsControllerUpdateFullTranslationsData',
                'updateFullTranslationsDataRequest',
                updateFullTranslationsDataRequest
            );
            const localVarPath = `/api/translations/UpdateFullTranslationsData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updateFullTranslationsDataRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdateTranslationsDataRequest} updateTranslationsDataRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tranlationsControllerUpdateTranslationsData: async (
            updateTranslationsDataRequest: UpdateTranslationsDataRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'updateTranslationsDataRequest' is not null or undefined
            assertParamExists(
                'tranlationsControllerUpdateTranslationsData',
                'updateTranslationsDataRequest',
                updateTranslationsDataRequest
            );
            const localVarPath = `/api/translations/UpdateTranslationsData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updateTranslationsDataRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * TranslationsApi - functional programming interface
 * @export
 */
export const TranslationsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = TranslationsApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {CreateNewLanguageRequest} createNewLanguageRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tranlationsControllerCreateNewLanguage(
            createNewLanguageRequest: CreateNewLanguageRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNewLanguageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tranlationsControllerCreateNewLanguage(
                createNewLanguageRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} lang
         * @param {string} fileType
         * @param {'cz' | 'wt' | 'et' | 'et-prop' | 'prop' | 'crypto'} project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tranlationsControllerGetFileContent(
            lang: string,
            fileType: string,
            project: 'cz' | 'wt' | 'et' | 'et-prop' | 'prop' | 'crypto',
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFileContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tranlationsControllerGetFileContent(
                lang,
                fileType,
                project,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tranlationsControllerGetTranslationLangsAndTypes(
            project: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTranslationLangsAndTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tranlationsControllerGetTranslationLangsAndTypes(
                project,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {GetTranslationsDataRequest} getTranslationsDataRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tranlationsControllerGetTranslationsData(
            getTranslationsDataRequest: GetTranslationsDataRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTranslationsDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tranlationsControllerGetTranslationsData(
                getTranslationsDataRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tranlationsControllerGetTranslationsWebhook(
            project: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tranlationsControllerGetTranslationsWebhook(
                project,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UpdateFullTranslationsDataRequest} updateFullTranslationsDataRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tranlationsControllerUpdateFullTranslationsData(
            updateFullTranslationsDataRequest: UpdateFullTranslationsDataRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateFullTranslationsDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tranlationsControllerUpdateFullTranslationsData(
                updateFullTranslationsDataRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UpdateTranslationsDataRequest} updateTranslationsDataRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tranlationsControllerUpdateTranslationsData(
            updateTranslationsDataRequest: UpdateTranslationsDataRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateTranslationsDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tranlationsControllerUpdateTranslationsData(
                updateTranslationsDataRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * TranslationsApi - factory interface
 * @export
 */
export const TranslationsApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = TranslationsApiFp(configuration);
    return {
        /**
         *
         * @param {CreateNewLanguageRequest} createNewLanguageRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tranlationsControllerCreateNewLanguage(
            createNewLanguageRequest: CreateNewLanguageRequest,
            options?: any
        ): AxiosPromise<CreateNewLanguageResponse> {
            return localVarFp
                .tranlationsControllerCreateNewLanguage(createNewLanguageRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} lang
         * @param {string} fileType
         * @param {'cz' | 'wt' | 'et' | 'et-prop' | 'prop' | 'crypto'} project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tranlationsControllerGetFileContent(
            lang: string,
            fileType: string,
            project: 'cz' | 'wt' | 'et' | 'et-prop' | 'prop' | 'crypto',
            options?: any
        ): AxiosPromise<GetFileContentResponse> {
            return localVarFp
                .tranlationsControllerGetFileContent(lang, fileType, project, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tranlationsControllerGetTranslationLangsAndTypes(
            project: string,
            options?: any
        ): AxiosPromise<GetTranslationLangsAndTypesResponse> {
            return localVarFp
                .tranlationsControllerGetTranslationLangsAndTypes(project, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GetTranslationsDataRequest} getTranslationsDataRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tranlationsControllerGetTranslationsData(
            getTranslationsDataRequest: GetTranslationsDataRequest,
            options?: any
        ): AxiosPromise<GetTranslationsDataResponse> {
            return localVarFp
                .tranlationsControllerGetTranslationsData(getTranslationsDataRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tranlationsControllerGetTranslationsWebhook(project: string, options?: any): AxiosPromise<boolean> {
            return localVarFp
                .tranlationsControllerGetTranslationsWebhook(project, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdateFullTranslationsDataRequest} updateFullTranslationsDataRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tranlationsControllerUpdateFullTranslationsData(
            updateFullTranslationsDataRequest: UpdateFullTranslationsDataRequest,
            options?: any
        ): AxiosPromise<UpdateFullTranslationsDataResponse> {
            return localVarFp
                .tranlationsControllerUpdateFullTranslationsData(updateFullTranslationsDataRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdateTranslationsDataRequest} updateTranslationsDataRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tranlationsControllerUpdateTranslationsData(
            updateTranslationsDataRequest: UpdateTranslationsDataRequest,
            options?: any
        ): AxiosPromise<UpdateTranslationsDataResponse> {
            return localVarFp
                .tranlationsControllerUpdateTranslationsData(updateTranslationsDataRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * TranslationsApi - object-oriented interface
 * @export
 * @class TranslationsApi
 * @extends {BaseAPI}
 */
export class TranslationsApi extends BaseAPI {
    /**
     *
     * @param {CreateNewLanguageRequest} createNewLanguageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public tranlationsControllerCreateNewLanguage(createNewLanguageRequest: CreateNewLanguageRequest, options?: any) {
        return TranslationsApiFp(this.configuration)
            .tranlationsControllerCreateNewLanguage(createNewLanguageRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} lang
     * @param {string} fileType
     * @param {'cz' | 'wt' | 'et' | 'et-prop' | 'prop' | 'crypto'} project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public tranlationsControllerGetFileContent(
        lang: string,
        fileType: string,
        project: 'cz' | 'wt' | 'et' | 'et-prop' | 'prop' | 'crypto',
        options?: any
    ) {
        return TranslationsApiFp(this.configuration)
            .tranlationsControllerGetFileContent(lang, fileType, project, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public tranlationsControllerGetTranslationLangsAndTypes(project: string, options?: any) {
        return TranslationsApiFp(this.configuration)
            .tranlationsControllerGetTranslationLangsAndTypes(project, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GetTranslationsDataRequest} getTranslationsDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public tranlationsControllerGetTranslationsData(
        getTranslationsDataRequest: GetTranslationsDataRequest,
        options?: any
    ) {
        return TranslationsApiFp(this.configuration)
            .tranlationsControllerGetTranslationsData(getTranslationsDataRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public tranlationsControllerGetTranslationsWebhook(project: string, options?: any) {
        return TranslationsApiFp(this.configuration)
            .tranlationsControllerGetTranslationsWebhook(project, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UpdateFullTranslationsDataRequest} updateFullTranslationsDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public tranlationsControllerUpdateFullTranslationsData(
        updateFullTranslationsDataRequest: UpdateFullTranslationsDataRequest,
        options?: any
    ) {
        return TranslationsApiFp(this.configuration)
            .tranlationsControllerUpdateFullTranslationsData(updateFullTranslationsDataRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UpdateTranslationsDataRequest} updateTranslationsDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public tranlationsControllerUpdateTranslationsData(
        updateTranslationsDataRequest: UpdateTranslationsDataRequest,
        options?: any
    ) {
        return TranslationsApiFp(this.configuration)
            .tranlationsControllerUpdateTranslationsData(updateTranslationsDataRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
