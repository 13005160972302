import { createStyles } from '@material-ui/core';

export default createStyles({
    underline: {
        '&$disabled': {
            '&:before': {
                borderBottomStyle: 'solid',
            },
        },
    },
});
