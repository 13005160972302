/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PublicFilesFiles } from './public-files-files';
import { S3File } from './s3-file';

/**
 *
 * @export
 * @interface PublicFiles
 */
export class PublicFiles {
    /**
     *
     * @type {PublicFilesFiles}
     * @memberof PublicFiles
     */
    files: PublicFilesFiles;
    /**
     *
     * @type {S3File}
     * @memberof PublicFiles
     */
    favicon_ico?: S3File;
    /**
     *
     * @type {S3File}
     * @memberof PublicFiles
     */
    logo192_png?: S3File;
    /**
     *
     * @type {S3File}
     * @memberof PublicFiles
     */
    logo512_png?: S3File;
    /**
     *
     * @type {S3File}
     * @memberof PublicFiles
     */
    marketing_logo_png?: S3File;
    /**
     *
     * @type {S3File}
     * @memberof PublicFiles
     */
    splash_screen_png?: S3File;
}
