import React from 'react';
import { Hidden, makeStyles } from '@material-ui/core';
import LanguageSelect from 'components/Header/components/LanguageSelect';
import { customGap } from 'helpers/device_helper';
import BackOrMenuBtn from 'components/BackOrMenuBtn';

export interface MenuTopSectionProps {
    notificationsAmount: number;
    menuState: boolean;
    toggleMenuState: () => void;
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: `0px ${theme.spacing(3)}px`,
        height: '4rem',
    },
    section_one: {
        color: theme.palette.text.secondary,
    },
    section_two: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        ...customGap(theme.spacing(5), 'horizontal'),
    },
}));

const MenuTopSection: React.FunctionComponent<MenuTopSectionProps> = (props) => {
    const { menuState, toggleMenuState } = props;
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div className={classes.section_one}>
                <BackOrMenuBtn toggleSideMenu={toggleMenuState} />
            </div>
            {menuState && (
                <div className={classes.section_two}>
                    <Hidden smUp>
                        <LanguageSelect />
                    </Hidden>
                    {/* <NotificationAlert amount={notificationsAmount} /> */}
                </div>
            )}
        </div>
    );
};

export default MenuTopSection;
