import PageLayout from 'components/Layouts/PageLayout';
import LoaderFullScreen from 'components/LoaderFullScreen';
import LoginRoute from 'components/PrivateRoute/LoginRoute';
import UserRoute from 'components/PrivateRoute/UserRoute';
import { Pages } from 'interfaces/main';
import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

export interface MainRouteProps {
    path?: string;
    exact?: boolean;
    component: any;
    isPrivate?: boolean;
    loginRoute?: boolean;
}

const routes: Array<MainRouteProps> = [
    {
        component: React.lazy(() => import('scenes/Login')),
        path: `/${Pages.LOGIN}`,
        exact: true,
        isPrivate: false,
        loginRoute: true,
    },
    {
        component: React.lazy(() => import('scenes/Dashboard')),
        path: `/${Pages.DASHBOARD}`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/Site/site.guard')),
        path: `/${Pages.SITE}`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/AuditHistory')),
        path: `/${Pages.AUDIT_HISTORY}`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/AuditHistory/')),
        path: `/${Pages.AUDIT_HISTORY}/:id`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/PaymentIps')),
        path: `/${Pages.PAYMENT_IPS}`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/NewSite')),
        path: `/${Pages.NEW_SITE}`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/CopySite')),
        path: `/${Pages.COPY_SITE}`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/Translations')),
        path: `/${Pages.TRANSLATIONS}/:project(cz|wt|et|prop|et-prop|crypto)`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/BrandManagement')),
        path: `/${Pages.BRAND_MANAGEMENT}`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/Languages')),
        path: `/${Pages.LANGUAGES}`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/Languages')),
        path: `/${Pages.LANGUAGES}/:id`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/Users')),
        path: `/${Pages.USERS}/:id`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/Users')),
        path: `/${Pages.USERS}/`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('scenes/CryptoAdmin')),
        path: `/${Pages.CRYPTO_ADMIN}/`,
        exact: true,
        isPrivate: true,
    },
    {
        component: React.lazy(() => import('components/PrivateRoute/ToHomePage')),
        isPrivate: false,
    },
];
const MainRouter = () => {
    return (
        <Suspense fallback={<LoaderFullScreen />}>
            <PageLayout fullWidth fullHeight>
                <Switch>
                    {routes.map((route, index) => {
                        let tempRoute = Object.assign({}, route);
                        if (route.isPrivate) return <UserRoute key={`Route-${index}`} {...tempRoute} />;
                        if (route.loginRoute) return <LoginRoute key={`Route-${index}`} {...tempRoute} />;
                        return <Route key={`Route-${index}`} {...tempRoute} />;
                    })}
                </Switch>
            </PageLayout>
        </Suspense>
    );
};

export default MainRouter;
