export enum EPropsTypes {
    PROP_GET_NAMESPACE_OPTIONS_START = '[props] get namespace prop options start',
    PROP_GET_NAMESPACE_OPTIONS_END = '[props] get namespace prop options end',
    PROP_GET_NAMESPACE_OPTIONS_ERROR = '[props] get namespace prop options error',
    PROP_ADD_GOAL_START = '[props] add prop goal start',
    PROP_ADD_GOAL_END = '[props] add prop goal end',
    PROP_ADD_GOAL_ERROR = '[props] add prop goal error',
    PROP_ADD_CHALLENGE_END = '[props] add prop challenge end',
    PROP_ADD_CHALLENGE_START = '[props] add prop challenge start',
    PROP_ADD_CHALLENGE_ERROR = '[props] add prop challenge error',
    PROP_ADD_PACKAGE_START = '[props] add prop package start',
    PROP_ADD_PACKAGE_END = '[props] add prop package end',
    PROP_ADD_PACKAGE_ERROR = '[props] add prop package error',
    PROP_UPDATE_GOAL_START = '[props] update prop goal start',
    PROP_UPDATE_GOAL_END = '[props] update prop goal end',
    PROP_UPDATE_GOAL_ERROR = '[props] update prop goal error',
    PROP_ADD_GOAL_RESET = '[props] add prop goal reset',
    PROP_UPDATE_GOAL_START_TREE = '[props] update prop goal start tree',
    PROP_UPDATE_GOAL_END_TREE = '[props] update prop goal end tree',
    PROP_UPDATE_GOAL_ERROR_TREE = '[props] update prop goal error tree',
    PROP_UPDATE_CHALLENGE_START = '[props] update prop challenge start',
    PROP_UPDATE_CHALLENGE_END = '[props] update prop challenge end',
    PROP_UPDATE_CHALLENGE_ERROR = '[props] update prop challenge error',
    PROP_UPDATE_PACKAGE_START = '[props] update prop package start',
    PROP_UPDATE_PACKAGE_END = '[props] update prop package end',
    PROP_UPDATE_PACKAGE_ERROR = '[props] update prop package error',
    PROP_DELETE_GOAL_START = '[props] delete prop goal start',
    PROP_DELETE_GOAL_END = '[props] delete prop goal end',
    PROP_DELETE_GOAL_ERROR = '[props] delete prop goal error',
    PROP_DELETE_CHALLENGE_START = '[props] delete prop challenge start',
    PROP_DELETE_CHALLENGE_END = '[props] delete prop challenge end',
    PROP_DELETE_CHALLENGE_ERROR = '[props] delete prop challenge error',
    PROP_DELETE_PACKAGE_START = '[props] delete prop package start',
    PROP_DELETE_PACKAGE_END = '[props] delete prop package end',
    PROP_DELETE_PACKAGE_ERROR = '[props] delete prop package error',
    PROP_UPDATE_ENV_START = '[props] update prop env start',
    PROP_UPDATE_ENV_END = '[props] update prop env end',
    PROP_UPDATE_ENV_ERROR = '[props] update prop env error',
}
