import { getTokenFromLocalStorage } from 'helpers/token';
import { EUserTypes } from './actionsTypes';
import { batch } from 'react-redux';
import { system_stopLoader } from 'store/system/actions';
import { auth_logout } from 'store/auth/actions';
import axios from 'services/axios';
import { getLastSelectedSiteFromLocalStorage, saveLastSelectedSite } from 'helpers/lastSelectedTP';
import {
    CopySiteRequest,
    CreateSiteRequest,
    DeleteDomainRequest,
    UpdateDomainRequest,
    UpdateSiteRequest,
    UserInfoRequest,
    UserInfoRequestRoleEnum,
} from 'services/generatedClientFromSwagger/models';

export const user_startLoader = () => ({
    type: EUserTypes.START_LOADER,
});

export const user_stopLoader = () => ({
    type: EUserTypes.STOP_LOADER,
});

export const user_getUserData = () => {
    return async (dispatch) => {
        try {
            const token = getTokenFromLocalStorage();
            dispatch({ type: EUserTypes.GET_USER_DATA_START });
            if (!token) {
                return batch(() => {
                    dispatch(system_stopLoader());
                    dispatch({ type: EUserTypes.GET_USER_DATA_FAILED });
                });
            }
            const userInfo = await axios.UserApi.userControllerUserInfo();

            if (!userInfo?.data?.userInfo) throw new Error();

            batch(() => {
                dispatch(system_stopLoader());
                dispatch({
                    type: EUserTypes.GET_USER_DATA_SUCCESS,
                    payload: userInfo.data.userInfo,
                });
            });
        } catch (error) {
            return batch(() => {
                dispatch(auth_logout());
                dispatch({ type: EUserTypes.GET_USER_DATA_FAILED });
                dispatch(system_stopLoader());
            });
        }
    };
};

export const user_getSites = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: EUserTypes.GET_SITES_START });
            const sites = await axios.SiteApi.siteControllerGetAllSites();
            dispatch(user_getClusterLbs());

            if (!sites?.data?.sites) throw new Error();

            const lastSelectedSite = getLastSelectedSiteFromLocalStorage();
            let siteToSelect = sites.data.sites.find(
                (site) => site.name.toLowerCase() === lastSelectedSite.toLowerCase()
            )?.name;
            if (!siteToSelect) {
                siteToSelect = sites.data.sites[0]?.name;
            }

            batch(() => {
                dispatch(user_changeSelectedSite(siteToSelect));
                dispatch({
                    type: EUserTypes.GET_SITES_SUCCESS,
                    payload: sites.data.sites.map((site) => ({ name: site.name, disabled: site?.disabled === true })),
                });
            });
        } catch (error) {
            dispatch({ type: EUserTypes.GET_SITES_FAILED });
        }
    };
};

export const user_updateDisabledSites = (payload: { [key: string]: boolean }) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EUserTypes.GET_DISABLED_SITES_START });
            const sites = await axios.SiteApi.siteControllerUpdateDisabledSites(payload);
            if (!sites?.data?.sites) {
                throw new Error('');
            }
            const siteToSelect = sites.data.sites[0]?.name;
            batch(() => {
                dispatch(user_changeSelectedSite(siteToSelect));
                dispatch({
                    type: EUserTypes.GET_DISABLED_SITES_SUCCESS,
                    payload: sites.data.sites.map((site) => ({ name: site.name, disabled: site?.disabled === true })),
                });
            });
            return true;
        } catch (error) {
            dispatch({ type: EUserTypes.GET_DISABLED_SITES_FAILED });
            return false;
        }
    };
};

export const user_changeSelectedSite = (site_name: string | undefined) => {
    return async (dispatch) => {
        dispatch({ type: EUserTypes.CHANGE_SELECTED_SITE, payload: site_name });
        saveLastSelectedSite(site_name);
        if (!site_name) return;
        try {
            dispatch(user_getSelectedSiteAssets(site_name));
            dispatch({ type: EUserTypes.GET_SELECTED_SITE_START });
            const siteData = await axios.SiteApi.siteControllerGetSite(site_name);

            dispatch(user_getAllFlags());
            dispatch({
                type: EUserTypes.GET_SELECTED_SITE_SUCCESS,
                payload: siteData.data,
            });
        } catch (error) {
            dispatch({ type: EUserTypes.GET_SELECTED_SITE_FAILED });
        }
    };
};

export const user_getAuditHistory = (site_name: string | undefined) => {
    return async (dispatch) => {
        if (!site_name) return;
        dispatch({ type: EUserTypes.GET_AUDIT_HISTORY_START });
        try {
            const auditHistory = await axios.SiteApi.siteControllerGetAuditHistory(site_name);
            dispatch({ type: EUserTypes.GET_AUDIT_HISTORY_SUCCESS, payload: auditHistory.data });
        } catch (e) {
            dispatch({ type: EUserTypes.GET_AUDIT_HISTORY_FAILED });
        }
    };
};

export const user_createNewSite = (payload: CreateSiteRequest) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EUserTypes.CREATE_NEW_SITE_START });
            const siteData = await axios.SiteApi.siteControllerCreateSite(payload);
            if (!siteData.data) {
                throw new Error('');
            }
            dispatch({
                type: EUserTypes.CREATE_NEW_SITE_SUCCESS,
            });
            return true;
        } catch (error) {
            dispatch({ type: EUserTypes.CREATE_NEW_SITE_FAILED });
            return false;
        }
    };
};

export const user_updateSite = (payload: UpdateSiteRequest) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EUserTypes.UPDATE_SITE_START });
            const siteData = await axios.SiteApi.siteControllerUpdateSite(payload);
            if (!siteData?.data?.site) {
                throw new Error('');
            }
            dispatch({
                type: EUserTypes.UPDATE_SITE_SUCCESS,
                payload: siteData.data,
            });
            return true;
        } catch (error) {
            dispatch({ type: EUserTypes.UPDATE_SITE_FAILED });
            return false;
        }
    };
};

export const user_updateDomain = (payload: UpdateDomainRequest) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: EUserTypes.UPDATE_DOMAIN_START,
            });
            await axios.DomainApi.domainControllerUpdateDomain(payload);
            dispatch({
                type: EUserTypes.UPDATE_DOMAIN_SUCCESS,
                payload: payload,
            });
            return true;
        } catch (error: any) {
            dispatch({
                type: EUserTypes.UPDATE_DOMAIN_FAILED,
                payload: error?.response?.data?.message ?? 'Error updating domain',
            });
            return false;
        }
    };
};

export const user_restUpdateDomainError = () => {
    return async (dispatch) => {
        dispatch({
            type: EUserTypes.RESET_UPDATE_DOMAIN_ERROR,
        });
    };
};

export const user_deleteDomain = (payload: DeleteDomainRequest) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: EUserTypes.DELETE_DOMAIN_START,
            });
            await axios.DomainApi.domainControllerDeleteDomain(payload);
            dispatch({
                type: EUserTypes.DELETE_DOMAIN_SUCCESS,
                payload: payload,
            });
            return true;
        } catch (error: any) {
            dispatch({
                type: EUserTypes.DELETE_DOMAIN_FAILED,
                payload: error?.response?.data?.message ?? 'Error delete domain',
            });
            return false;
        }
    };
};

export const user_getIsProxyByCloudFlareEnables = (payload) => {
    return async (dispatch) => {
        try {
            const { data: clouterCnameOption } = await axios.DomainApi.domainControllerGetIsProxyByCloudFlareEnable(
                payload
            );
            dispatch({
                type: EUserTypes.GET_IS_PROXY_BY_CLOUDFLARE_ENABLED,
                payload: clouterCnameOption,
            });
            return true;
        } catch (error) {
            return false;
        }
    };
};

export const user_resetIsProxyByCloudFlareEnables = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: EUserTypes.GET_IS_PROXY_BY_CLOUDFLARE_ENABLED,
                payload: undefined,
            });
            return true;
        } catch (error) {
            return false;
        }
    };
};

export const user_copySite = (payload: CopySiteRequest) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EUserTypes.UPDATE_SITE_START });
            const siteData = await axios.SiteApi.siteControllerCopySite(payload);
            if (!siteData?.data?.site) {
                throw new Error('');
            }
            dispatch({
                type: EUserTypes.UPDATE_SITE_SUCCESS,
                payload: siteData.data,
            });
            return true;
        } catch (error) {
            dispatch({ type: EUserTypes.UPDATE_SITE_FAILED });
            return false;
        }
    };
};

export const user_getSiteData = (site_name: string) => {
    return async (dispatch) => {
        try {
            console.log('get site data');

            dispatch({ type: EUserTypes.GET_SITE_DATA_START });
            const siteData = await axios.SiteApi.siteControllerGetSite(site_name);
            dispatch({
                type: EUserTypes.GET_SITE_DATA_SUCCESS,
                payload: { data: siteData.data, site_name },
            });
        } catch (error) {
            dispatch({ type: EUserTypes.GET_SITE_DATA_FAILED });
        }
    };
};

export const user_getSelectedSiteAssets = (site_name: string) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EUserTypes.GET_SELECTED_SITE_ASSETS_START });
            const siteData = await axios.SiteApi.siteControllerGetAssetsStructure(site_name);
            dispatch({
                type: EUserTypes.GET_SELECTED_SITE_ASSETS_SUCCESS,
                payload: siteData.data,
            });
        } catch (error) {
            dispatch({ type: EUserTypes.GET_SELECTED_SITE_ASSETS_FAILED });
        }
    };
};

export const user_getSiteAsset = (site_name: string, key: string, folderPrefix?: string) => {
    return async () => {
        try {
            const siteData = await axios.SiteApi.siteControllerGetAsset(site_name, key, folderPrefix);
            return siteData.data;
        } catch (error) {
            return undefined;
        }
    };
};

export const user_getSiteAssetPublic = (site_name: string, key: string, folderPrefix?: string) => {
    return async () => {
        try {
            const siteData = await axios.SiteApi.siteControllerGetAssetPublic(site_name, key, folderPrefix);
            return siteData.data;
        } catch (error) {
            return undefined;
        }
    };
};

export const user_uploadSiteAsset = (site_name: string, key: string, base64File: string, folderPrefix?: string) => {
    return async (dispatch) => {
        try {
            const siteData = await axios.SiteApi.siteControllerUploadAsset({
                name_space: site_name,
                key,
                base64File,
                folderPrefix,
            });
            dispatch(user_getSelectedSiteAssets(site_name));
            return siteData.data.status;
        } catch (error) {
            return false;
        }
    };
};

export const user_deleteSiteAsset = (site_name: string, key: string, folderPrefix?: string) => {
    return async (dispatch) => {
        try {
            const siteData = await axios.SiteApi.siteControllerDeleteAsset({
                name_space: site_name,
                key,
                folderPrefix,
            });
            dispatch(user_getSelectedSiteAssets(site_name));
            return siteData.data.status;
        } catch (error) {
            return false;
        }
    };
};

export const user_deleteSiteAssetPublic = (site_name: string, key: string, folderPrefix?: string) => {
    return async (dispatch) => {
        try {
            const siteData = await axios.SiteApi.siteControllerDeleteAssetPublic({
                name_space: site_name,
                key,
                folderPrefix,
            });
            dispatch(user_getSelectedSiteAssets(site_name));
            return siteData.data.status;
        } catch (error) {
            return false;
        }
    };
};

export const user_uploadSiteAssetPublic = (
    site_name: string,
    key: string,
    base64File: string,
    folderPrefix: string,
    isPublic: boolean
) => {
    return async (dispatch) => {
        try {
            const siteData = await axios.SiteApi.siteControllerUploadAssetPublic({
                name_space: site_name,
                key,
                base64File,
                folderPrefix,
                isPublic,
            });
            dispatch(user_getSelectedSiteAssets(site_name));
            return siteData.data.status;
        } catch (error) {
            return false;
        }
    };
};

export const user_getAllFlags = () => {
    return async (dispatch) => {
        try {
            const flags = (await axios.SiteApi.siteControllerGetFlags()).data;
            dispatch({
                type: EUserTypes.GET_ALL_FLAGS,
                payload: flags,
            });
        } catch (error) {
            return {};
        }
    };
};

export const user_downloadEmailTemplates = (namespace: string, project_name: string) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EUserTypes.DOWNLOAD_EMAIL_TEMPLATES_START });
            const res = await axios.SiteApi.siteControllerDownloadEmailTemplates(namespace, project_name, {
                responseType: 'blob',
            });
            const data = res.data as any;
            const blob = new Blob([data], { type: res.headers['content-type'] });
            // console.log(blob);
            const url = window.URL.createObjectURL(blob);
            if (url) {
                dispatch({
                    type: EUserTypes.DOWNLOAD_EMAIL_TEMPLATES_SUCCESS,
                });
                return { status: 1, url: url };
            }
            return false;
        } catch (error: any) {
            const errorParsed = JSON.parse(await error.response.data.text());
            dispatch({ type: EUserTypes.DOWNLOAD_EMAIL_TEMPLATES_FAILED });
            return { status: 0, error: errorParsed.message };
        }
    };
};

export const user_getClusterLbs = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: EUserTypes.GET_CLUSTER_LBS_START });
            console.log('user_getClusterLbs start');

            const clusterLbs = (await axios.OptionsApi.optionsApiControllerGetOptionsByName('cluster_lbs')).data;

            dispatch({
                type: EUserTypes.GET_CLUSTER_LBS_SUCCESS,
                payload: clusterLbs,
            });
        } catch (error) {
            dispatch({ type: EUserTypes.GET_CLUSTER_LBS_FAILED });
        }
    };
};

export const user_getChatBaseChatHistory = (site_name: string) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EUserTypes.GET_CHATBASE_CHAT_HISTORY_START });
            const chatHistory = (await axios.SiteApi.siteControllerGetChatBaseChatHistory(site_name)).data;
            dispatch({
                type: EUserTypes.GET_CHATBASE_CHAT_HISTORY_SUCCESS,
                payload: chatHistory,
            });
        } catch (error) {
            dispatch({ type: EUserTypes.GET_CHATBASE_CHAT_HISTORY_FAILED });
        }
    };
};

export const user_getUsers = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: EUserTypes.GET_USERS_START });
            const users = (await axios.UserApi.userControllerFindAllUsers('users')).data;
            dispatch({
                type: EUserTypes.GET_USERS_SUCCESS,
                payload: users,
            });
        } catch (error) {
            dispatch({ type: EUserTypes.GET_USERS_FAILED });
        }
    };
};

export const user_createUsers = (userInfo: {
    email: string;
    firstName: string;
    lastName: string;
    role: UserInfoRequestRoleEnum;
    namespaces?: string[];
    ips: string[];
}) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EUserTypes.CREATE_USERS_START });
            const createUser: UserInfoRequest = {
                email: userInfo.email,
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                role: userInfo.role,
                namespaces: userInfo.namespaces,
                ips: userInfo.ips,
            };
            const newUser = (await axios.UserApi.userControllerCreateUser(createUser)).data;
            dispatch({
                type: EUserTypes.CREATE_USERS_SUCCESS,
                payload: newUser,
            });
        } catch (error) {
            dispatch({ type: EUserTypes.CREATE_USERS_FAILED });
        }
    };
};

export const user_deleteUsers = (_id: string) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EUserTypes.DELETE_USERS_START });
            const deletedUser = (await axios.UserApi.userControllerDeleteUserById(_id)).data;
            dispatch({
                type: EUserTypes.DELETE_USERS_SUCCESS,
                payload: { _id: _id },
            });
        } catch (error) {
            dispatch({ type: EUserTypes.DELETE_USERS_FAILED });
        }
    };
};
export const user_editUsers = (id: string, updatedFields: UserInfoRequest) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EUserTypes.EDIT_USERS_START });
            const updatedUser = (await axios.UserApi.userControllerUpdateUserById(id, updatedFields)).data;
            dispatch({
                type: EUserTypes.EDIT_USERS_SUCCESS,
                payload: updatedUser,
            });
        } catch (error) {
            dispatch({ type: EUserTypes.EDIT_USERS_FAILED });
        }
    };
};
