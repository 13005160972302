import * as React from 'react';
import MainRouter from 'scenes/MainRouter';
import { AppProps, mapDispatchToProps, mapStateToProps } from 'App/app.scheme';
import useStyles from './app.styles';
import SideMenu from 'components/SideMenu';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useTranslation } from 'react-i18next';
import { onChangeLng } from 'i18n';
import { getLanguageDirection } from 'config/i18n';
import LoaderFullScrren from 'components/LoaderFullScreen';
import { connect } from 'react-redux';
import SnackbarWrapper from './components/SnackbarWrapper';
import Header from 'components/Header';
import createMuiTheme from 'muiTheme/theme/createMuiTheme';

const App: React.FunctionComponent<AppProps> = (props) => {
    const { hasJwtToken, loggedIn, systemLoader, systemTheme, tryGetUserData } = props;

    const {
        i18n: { language },
    } = useTranslation();
    // const { t } = useTranslation('site');
    const [appLanguage, setAppLanguage] = React.useState<string>(language);

    const classes = useStyles({
        theme: systemTheme,
        loggedIn,
    });

    //~~~React Effects
    React.useEffect(() => {
        onLanguageChange(language);
    }, [language]);

    React.useEffect(() => {
        tryGetUserData();
    }, [hasJwtToken, tryGetUserData]);

    //~~~React Effect Functions
    const onLanguageChange = (newLanguage: string) => {
        onChangeLng(newLanguage);
        setAppLanguage(newLanguage); // force app to rebuild on language change
    };

    if (systemLoader) {
        return <LoaderFullScrren />;
    }

    return (
        <ThemeProvider key={appLanguage} theme={createMuiTheme(systemTheme, getLanguageDirection(language))}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <CssBaseline />
                <div className={classes.app_container}>
                    {loggedIn && (
                        <div className={classes.side_menu}>
                            <SideMenu />
                        </div>
                    )}
                    <div className={classes.body}>
                        <Header />
                        <main className={classes.main}>
                            <MainRouter />
                        </main>
                    </div>
                </div>
                <SnackbarWrapper />
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(App));
