import { ESystemTypes } from 'store/system/actionsTypes';
import { ETranslationsTypes } from './actionsTypes';
import { TranslationsReducerState } from './interfaces';

const initialState: TranslationsReducerState = {
    langAndTypesLoading: false,
    languages: [],
    fileTypes: [],

    selectedLanguage: undefined,
    selectedFileType: undefined,

    fileLoading: false,
    fileContent: undefined,
    enFileContent: undefined,

    fullFileLoading: false,
    fullFileContent: undefined,

    updateFileLoading: false,

    updateFullFilesLoading: false,

    createNewLanguageLoading: false,
};

const translationsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ETranslationsTypes.GET_LANGUAGES_AND_TYPES_START: {
            return {
                ...state,
                langAndTypesLoading: true,
            };
        }
        case ETranslationsTypes.GET_LANGUAGES_AND_TYPES_SUCCESS: {
            const { langs, fileTypes } = payload;
            return {
                ...state,
                langAndTypesLoading: false,
                languages: langs,
                fileTypes: fileTypes,
            };
        }
        case ETranslationsTypes.GET_LANGUAGES_AND_TYPES_FAILED: {
            return {
                ...state,
                langAndTypesLoading: false,
            };
        }
        case ETranslationsTypes.SET_SELECTED_LANGUAGE: {
            return {
                ...state,
                selectedLanguage: payload,
            };
        }
        case ETranslationsTypes.SET_SELECTED_FILE: {
            return {
                ...state,
                selectedFileType: payload,
            };
        }
        case ETranslationsTypes.GET_SELECTED_FILE_START: {
            return {
                ...state,
                fileLoading: true,
            };
        }
        case ETranslationsTypes.GET_SELECTED_FILE_SUCCESS: {
            const { content, enContent } = payload;
            return {
                ...state,
                fileLoading: false,
                fileContent: content,
                enFileContent: enContent,
            };
        }
        case ETranslationsTypes.GET_SELECTED_FILE_FAILED: {
            return {
                ...state,
                fileLoading: false,
            };
        }
        case ETranslationsTypes.GET_FULL_FILES_START: {
            return {
                ...state,
                fullFileLoading: true,
            };
        }
        case ETranslationsTypes.GET_FULL_FILES_SUCCESS: {
            return {
                ...state,
                fullFileLoading: false,
                fullFileContent: payload,
            };
        }
        case ETranslationsTypes.GET_FULL_FILES_FAILED: {
            return {
                ...state,
                fullFileLoading: false,
            };
        }
        case ETranslationsTypes.UPDATE_SELECTED_FILE_START: {
            return {
                ...state,
                updateFileLoading: true,
            };
        }
        case ETranslationsTypes.UPDATE_SELECTED_FILE_SUCCESS: {
            return {
                ...state,
                updateFileLoading: false,
                fileContent: payload,
            };
        }
        case ETranslationsTypes.UPDATE_SELECTED_FILE_FAILED: {
            return {
                ...state,
                updateFileLoading: false,
            };
        }
        case ETranslationsTypes.UPDATE_FULL_FILES_START: {
            return {
                ...state,
                updateFullFilesLoading: true,
            };
        }
        case ETranslationsTypes.UPDATE_FULL_FILES_SUCCESS: {
            return {
                ...state,
                updateFullFilesLoading: false,
            };
        }
        case ETranslationsTypes.UPDATE_FULL_FILES_FAILED: {
            return {
                ...state,
                updateFullFilesLoading: false,
            };
        }
        case ETranslationsTypes.CREATE_NEW_LANGUAGE_START: {
            return {
                ...state,
                createNewLanguageLoading: true,
            };
        }
        case ETranslationsTypes.CREATE_NEW_LANGUAGE_SUCCESS:
        case ETranslationsTypes.CREATE_NEW_LANGUAGE_FAILED: {
            return {
                ...state,
                createNewLanguageLoading: false,
            };
        }
        case ESystemTypes.RESET_APP: {
            return {
                ...state,
                langAndTypesLoading: false,
                languages: [],
                fileTypes: [],

                selectedLanguage: undefined,
                selectedFileType: undefined,

                fileLoading: false,
                fileContent: undefined,
                enFileContent: undefined,

                fullFileLoading: false,
                fullFileContent: undefined,

                updateFileLoading: false,
                createNewLanguageLoading: false,
            };
        }
        default:
            return state;
    }
};

export default translationsReducer;
