import { UserInfoRoleEnum } from 'services/generatedClientFromSwagger';
import { system_toggleSideMenu } from 'store/system/actions';
import { user_rolePermitted } from 'store/user/selectors';

export interface PrivateHeaderProps {
    user_rolePermitted: (roles: Array<UserInfoRoleEnum>) => boolean;
    system_toggleSideMenu: () => void;
}

export const mapStateToProps = (state) => ({
    user_rolePermitted: (roles: Array<UserInfoRoleEnum>) => user_rolePermitted(state, roles),
});

export const mapDispatchToProps = {
    system_toggleSideMenu,
};
