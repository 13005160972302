import { makeStyles } from '@material-ui/core';
import { isDesktop, isTablet } from 'helpers/device_helper';
import palette from 'muiTheme/config/palette';

interface Props {
    loggedIn: boolean;
    theme: 'light' | 'dark';
}

export default makeStyles(() => ({
    app_container: {
        minHeight: '100vh',
        maxHeight: '100vh',
        display: 'flex',
    },
    header: {
        display: 'block',
        height: '70px',
        position: 'relative',
    },
    body: (props: Props) => ({
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        position: 'relative',
        width: '100vw',
        height: '100%',
        minHeight: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxWidth: '100vw',
    }),
    side_menu: (props: Props) => ({
        backgroundColor: palette[props.theme].side_menu.background,
        ...isTablet({
            minWidth: '64px',
        }),
        ...isDesktop({
            minWidth: 'auto',
        }),
    }),
    main: {},
    footer: {
        display: 'block',
        position: 'relative',
    },
    page_link: {
        '&:link,&:visited': {
            textDecoration: 'underline',
        },
    },
}));
