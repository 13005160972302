import axios from 'axios';
import config from '../config/common';
import {
    AuthApi,
    UserApi,
    SiteApi,
    TranslationsApi,
    OptionsApi,
    DomainApi,
    PropApi,
    BrandManagementApi,
    CryptoApi,
} from './generatedClientFromSwagger/index';
import store from 'store/index';
import { getTokenFromLocalStorage, saveTokenToLocalStorage } from 'helpers/token';
import { load } from 'recaptcha-v3';
import { ESystemTypes } from 'store/system/actionsTypes';

const URL = config.api.URL;

const getGoogleToken = async () => {
    const recaptcha = await load(config.recaptchaKey);
    return await recaptcha.execute('reload');
};

const axiosInstance = axios.create({
    baseURL: URL,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Cache-Control': 'no-cache',
    },
});

axiosInstance.interceptors.request.use(function (config) {
    const token = getTokenFromLocalStorage();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

axiosInstance.interceptors.response.use(
    function (response) {
        const refreshToken = response.headers['refresh-token'];
        if (refreshToken) {
            saveTokenToLocalStorage(refreshToken);
        }
        return response;
    },
    function (error) {
        if (401 === error?.response?.status) {
            store.dispatch({ type: ESystemTypes.RESET_APP, payload: null });
        } else {
            return Promise.reject(error);
        }
    }
);

const axiosInstanceWithRecaptcha = axios.create({
    baseURL: URL,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Cache-Control': 'no-cache',
    },
});

axiosInstanceWithRecaptcha.interceptors.request.use(async function (config) {
    const token = await getGoogleToken();
    config.headers.Authorization = token;
    return config;
});

axiosInstanceWithRecaptcha.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (401 === error?.response?.status) {
            store.dispatch({ type: ESystemTypes.RESET_APP, payload: null });
            // localStorage.clear();
            // sessionStorage.clear();
        } else {
            return Promise.reject(error);
        }
    }
);

const apiInstances = {
    AuthApi: new AuthApi(undefined, URL, axiosInstanceWithRecaptcha),
    UserApi: new UserApi(undefined, URL, axiosInstance),
    SiteApi: new SiteApi(undefined, URL, axiosInstance),
    TranslationsApi: new TranslationsApi(undefined, URL, axiosInstance),
    OptionsApi: new OptionsApi(undefined, URL, axiosInstance),
    BrandManagementApi: new BrandManagementApi(undefined, URL, axiosInstance),
    DomainApi: new DomainApi(undefined, URL, axiosInstance),
    CryptoApi: new CryptoApi(undefined, URL, axiosInstance),
    PropApi: new PropApi(undefined, URL, axiosInstance),
};

export default apiInstances;
